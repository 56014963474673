import moment from 'moment';
import { times, constant, find, some, each, trim } from 'lodash';

import {
	SelectFilter,
	NumberFilter,
	StringFilter,
	LastDigitsFilter,
	CardTypeFilter,
	CustomFieldsFilter,
} from 'common/components/filters';
import { SelectSelection, SingleSelection, CustomFieldsSelection } from 'common/components/selections';
import { getDate, localToApiMoment, createFilters, getFilterValues, maxApiDaysRange } from 'common/utilities';

const checkTodayEnd = endDate => endDate.isSame(moment(), 'd');

const checkToday = (startDate, endDate) => endDate.isSame(moment(), 'd') && startDate.isSame(moment(), 'd');

const checkYesterday = (startDate, endDate) => {
	const yesterday = moment()
		.subtract(1, 'days')
		.startOf('day');
	return endDate.isSame(yesterday, 'd') && startDate.isSame(yesterday, 'd');
};
const checkLastMonth = endDate =>
	endDate.isSame(
		moment()
			.subtract(1, 'months')
			.endOf('month'),
		'd'
	);

const isValidKey = (key, startDate, endDate) => {
	let isInvalid = false;
	switch (key) {
		case 'today':
			isInvalid = checkToday(startDate, endDate);
			break;
		case '7':
		case '30':
		case '60':
		case '90':
		case 'month':
			isInvalid = checkTodayEnd(endDate);
			break;
		case 'yesterday':
			isInvalid = checkYesterday(startDate, endDate);
			break;
		case 'lastmonth':
			isInvalid = checkLastMonth(endDate);
			break;
	}
	return isInvalid;
};
export const transactionsFilter = createFilters([
	{
		key: 'secondaryKeys',
		name: 'Secondary Keys',
		values: {
			secondaryKeys: null,
		},
	},
	{
		key: 'date',
		customSettingsKey: 'date',
		hasSelection: true,
		defaultHasSelection: true,
		getSelectionText: ({ key, startDate, endDate }, predefinedDates) => {
			if (!isValidKey(key, startDate, endDate)) {
				key = 'custom';
			}
			const predefinedDate = find(predefinedDates, { key });
			if (predefinedDate) {
				return predefinedDate.displayValue;
			}
			const diff = endDate.diff(startDate, 'days');
			return diff ? `Custom (${diff + 1} days)` : diff === 0 ? 'Custom (1 day)' : 'Custom';
		},
		values: {
			key: '7',
			startDate: moment()
				.startOf('day')
				.add(-7, 'day'),
			endDate: moment().endOf('day'),
			disabled: false,
		},
		props: {
			displayTime: true,
			maxDaysRange: Infinity,
			maxApiDaysRange: maxApiDaysRange,
			showTour: true,
			milliseconds: {
				startDate: '000',
				endDate: '999',
			},
		},
	},
	{
		key: 'amount',
		name: 'Amount',
		customSettingsKey: 'amount',
		values: {
			amount: null,
		},
		props: {
			prefix: '$',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'cardNumber',
		name: 'Card Number',
		customSettingsKey: 'accountNumber',
		values: {
			cardNumber: null,
		},
		component: LastDigitsFilter,
		selectionComponent: SingleSelection,
		props: {
			numberOfDigits: 4,
		},
		onTop: true,
		allowsDateDisable: true,
	},
	{
		key: 'cardholderName',
		name: 'Cardholder Name',
		customSettingsKey: 'cardholderName',
		values: {
			cardholderName: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		onTop: true,
		allowsDateDisable: true,
	},
	{
		key: 'referenceNumber',
		name: 'Reference Number',
		values: {
			referenceNumber: '',
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
		onTop: true,
		allowsDateDisable: true,
	},
	{
		key: 'invoice',
		name: 'Invoice',
		customSettingsKey: 'invoice',
		values: {
			invoice: '',
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		onTop: true,
		allowsDateDisable: true,
	},
	{
		key: 'status',
		name: 'Status',
		customSettingsKey: 'result',
		resetToFalseOnRemove: true,
		values: {
			approved: false,
			declined: false,
		},
		component: SelectFilter,
		selectionComponent: SelectSelection,
		options: {
			approved: 'Approved',
			declined: 'Declined',
		},
		disabled: {
			approved: ({ transactionType }) => transactionType.auth,
			declined: ({ transactionType }) => transactionType.auth,
		},
		injectFilters: ['transactionType'],
		permissions: {
			approved: 'allowReportApproved',
			declined: 'allowReportDeclined',
		},
	},
	{
		key: 'cvv',
		name: 'CVV Result',
		customSettingsKey: 'cvvResult',
		values: {
			match: false,
			noMatch: false,
		},

		options: {
			match: 'Match',
			noMatch: 'No Match',
		},
		component: SelectFilter,
		selectionComponent: SelectSelection,
		advancedFilter: true,
		isRadio: true,
	},
	{
		key: 'avs',
		name: 'AVS',
		customSettingsKey: 'avs',
		values: {
			YYY: false,
			NYZ: false,
			YNA: false,
			NNN: false,
			XXU: false,
			YYX: false,
			NYW: false,
			XXR: false,
			XXS: false,
		},
		options: {
			YYY: 'Address: Match & 5 Digit ZIP: Match',
			NYZ: 'Address: No Match & 5 Digit ZIP: Match',
			YNA: 'Address: Match & 5 Digit ZIP: No Match',
			NNN: 'Address: No Match & 5 Digit ZIP: No Match',
			XXU: 'Address Information not verified for domestic transaction',
			YYX: 'Address: Match & 9 Digit ZIP: Match',
			NYW: 'Address: No Match & 9 Digit ZIP: Match',
			XXR: 'Retry / System Unavailable',
			XXS: 'Service Not Supported',
		},
		selectOptions: [
			{ value: 'YYY', label: 'Address: Match & 5 Digit ZIP: Match' },
			{ value: 'NYZ', label: 'Address: No Match & 5 Digit ZIP: Match' },
			{ value: 'YNA', label: 'Address: Match & 5 Digit ZIP: No Match' },
			{ value: 'NNN', label: 'Address: No Match & 5 Digit ZIP: No Match' },
			{ value: 'XXU', label: 'Address Information not verified for domestic transaction' },
			{ value: 'YYX', label: 'Address: Match & 9 Digit ZIP: Match' },
			{ value: 'NYW', label: 'Address: No Match & 9 Digit ZIP: Match' },
			{ value: 'XXR', label: 'Retry / System Unavailable' },
			{ value: 'XXS', label: 'Service Not Supported' },
		],
		component: SelectFilter,
		selectionComponent: SelectSelection,
		advancedFilter: true,
	},
	{
		key: 'transactionType',
		name: 'Transaction Type',
		values: {
			sale: false,
			auth: false,
			capture: false,
			refund: false,
			credit: false,
		},
		options: {
			sale: 'Sale',
			auth: 'Auth',
			capture: 'Capture',
			refund: 'Refund',
			credit: 'Credit',
			crypto: 'Crypto',
		},
		disabled: {
			auth: ({ status }) => status.approved || status.declined,
		},
		injectFilters: ['status'],
		props: {
			isMulti: true,
		},
		component: SelectFilter,
		selectionComponent: SelectSelection,
		advancedFilter: true,
	},
	{
		key: 'cardType',
		name: 'Card Type',
		values: {
			visa: false,
			mastercard: false,
			amex: false,
			discover: false,
			jcb: false,
			diners: false,
			other: false,
		},
		options: {
			visa: 'Visa',
			mastercard: 'Mastercard',
			amex: 'American Express',
			discover: 'Discover',
			jcb: 'JCB',
			diners: 'Diners',
			other: 'Other',
		},
		props: {
			isMulti: true,
		},
		component: CardTypeFilter,
		selectionComponent: SelectSelection,
		advancedFilter: true,
	},
	{
		key: 'batch',
		name: 'Batch',
		customSettingsKey: 'batch',
		values: {
			batch: null,
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'company',
		name: 'Company',
		customSettingsKey: 'company',
		values: {
			company: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'description',
		name: 'Description',
		customSettingsKey: 'description',
		values: {
			description: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'email',
		name: 'Email',
		customSettingsKey: 'email',
		values: {
			email: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'authCode',
		name: 'Auth Code',
		customSettingsKey: 'authCode',
		values: {
			authCode: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'terminal',
		name: 'Terminal Number',
		customSettingsKey: 'terminalNumber',
		values: {
			terminal: null,
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'poNumber',
		name: 'PO Number',
		customSettingsKey: 'poNumber',
		values: {
			poNumber: null,
		},
		component: NumberFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'orderId',
		name: 'Order ID',
		customSettingsKey: 'orderId',
		values: {
			orderId: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
		advancedFilter: true,
	},
	{
		key: 'custom',
		name: 'Custom Fields',
		customSettingsKey: ['custom1', 'custom2', 'custom3'],
		values: {
			custom01: null,
			custom02: null,
			custom03: null,
		},
		component: CustomFieldsFilter,
		selectionComponent: CustomFieldsSelection,
		props: {
			numberOfCustomFields: 3,
			labels: times(3, constant(null)),
			hiddenFields: times(3, constant(false)),
		},
		advancedFilter: true,
	},
	{
		hide: true,
		key: 'sourceKey',
		name: 'Source Key',
		customSettingsKey: 'sourceKey',
		values: {
			sourceKey: null,
		},
		component: StringFilter,
		selectionComponent: SingleSelection,
	},
	{
		key: 'includeSplitPay',
		name: 'Include Split Pay Transactions',
		values: {
			includeSplitPay: false,
		},
		clearable: false,
		disallowsDateDisable: true,
	},
	{
		key: 'scheduleId',
		name: 'Schedule ID',
		values: {
			scheduleId: null,
			loadAll: false,
		},
		selectionComponent: SingleSelection,
		allowsDateDisable: true,
		removeOnDateEnable: true,
	},
]);

export const compileFilter = async (filterList, filterDateFormat) => {
	const {
		date,
		status,
		amount,
		batch,
		transactionType,
		cardNumber,
		cardType,
		cardholderName,
		cvv,
		company,
		description,
		invoice,
		referenceNumber,
		avs,
		authCode,
		terminal,
		poNumber,
		orderId,
		custom,
		email,
		includeSplitPay,
		secondaryKeys,
	} = getFilterValues(filterList);

	const filters = {
		xCommand: date.disabled ? 'Report:Transactions' : 'Report:All',
	};

	if (!date.disabled) {
		const beginDate = await localToApiMoment(date.startDate);
		const endDate = await localToApiMoment(date.endDate);
		filters.xBeginDate = getDate(beginDate, filterDateFormat);
		filters.xEndDate = getDate(endDate, filterDateFormat);

		if (trim(amount.amount)) {
			filters.xAmount = 'ai' + amount.amount.toString();
		}

		if (trim(batch.batch)) {
			filters.xResponseBatch = 'ai' + batch.batch.toString();
		}

		if (status.approved) {
			filters.xCommand = 'Report:Approved';
		} else if (status.declined) {
			filters.xCommand = 'Report:Declined';
		} else if (includeSplitPay.includeSplitPay) {
			filters.xCommand = 'Report:Net';
		}

		if (some(transactionType, p => p)) {
			const ttFilterArr = [];

			each(transactionType, (value, prop) => {
				if (!value) {
					return;
				}
				switch (prop) {
					case 'sale':
						ttFilterArr.push('cc_sale,check_sale,ebtfs_sale,ebtcb_sale,ebtw_sale');
						break;
					case 'auth':
						ttFilterArr.push('cc_authonly');
						break;
					case 'capture':
						ttFilterArr.push('cc_capture');
						break;
					case 'refund':
						ttFilterArr.push('cc_refund,check_refund');
						break;
					case 'credit':
						ttFilterArr.push('cc_credit,check_credit');
						break;
					case 'crypto':
						ttFilterArr.push('crypto_initiate,crypto_sale');
				}
			});

			filters.xFilterCommand = ttFilterArr.join(',');
		}

		if (some(cardType, p => p)) {
			const cardTypeFilterArr = [];

			each(cardType, (value, card) => {
				if (value) {
					cardTypeFilterArr.push(card);
				}
			});
			filters.xCardType = `a=${cardTypeFilterArr.join(',')}`;
		}

		if (cvv.match) {
			filters.xResponseCVVCode = 'aiM';
		} else if (cvv.noMatch) {
			filters.xResponseCVVCode = 'aiN';
		}

		if (trim(company.company)) {
			filters.xBillCompany = 'a=' + company.company;
		}

		if (trim(description.description)) {
			filters.xDescription = 'a=' + description.description;
		}

		if (trim(email.email)) {
			filters.xEmail = 'a=' + email.email;
		}

		if (avs.YYY) {
			filters.xResponseAvsCode = 'a=YYY';
		} else if (avs.NYZ) {
			filters.xResponseAvsCode = 'a=NYZ';
		} else if (avs.YNA) {
			filters.xResponseAvsCode = 'a=YNA';
		} else if (avs.NNN) {
			filters.xResponseAvsCode = 'a=NNN';
		} else if (avs.XXU) {
			filters.xResponseAvsCode = 'a=XXU';
		} else if (avs.YYX) {
			filters.xResponseAvsCode = 'a=YYX';
		} else if (avs.NYW) {
			filters.xResponseAvsCode = 'a=NYW';
		} else if (avs.XXR) {
			filters.xResponseAvsCode = 'a=XXR';
		} else if (avs.XXS) {
			filters.xResponseAvsCode = 'a=XXS';
		}

		if (trim(authCode.authCode)) {
			filters.xResponseAuthCode = 'ai' + authCode.authCode.toString();
		}

		if (trim(terminal.terminal)) {
			filters.xTerminalNum = 'ai' + terminal.terminal.toString();
		}

		if (trim(poNumber.poNumber)) {
			filters.xPONum = 'ai' + poNumber.poNumber.toString();
		}

		if (trim(orderId.orderId)) {
			filters.xOrderID = 'a=' + orderId.orderId.toString();
		}

		if (some(custom, p => p)) {
			for (let prop in custom) {
				if (custom.hasOwnProperty(prop)) {
					if (trim(custom[prop])) {
						filters['x' + prop] = 'a=' + custom[prop];
					}
				}
			}
		}
	}

	if (secondaryKeys.secondaryKeys) {
		filters.xMultiKeyReporting = true;
	}

	if (trim(cardholderName.cardholderName)) {
		filters.xName = (date.disabled ? '' : 'a=') + cardholderName.cardholderName;
	}

	if (trim(cardNumber.cardNumber) && cardNumber.cardNumber.indexOf('_') === -1) {
		filters.xCardLastFour = (date.disabled ? '' : 'ai') + cardNumber.cardNumber.toString();
	}

	if (trim(referenceNumber.referenceNumber)) {
		filters.xRefNum = (date.disabled ? '' : 'a=') + referenceNumber.referenceNumber.toString();
	}

	if (trim(invoice.invoice)) {
		filters.xInvoice = (date.disabled ? '' : 'a=') + invoice.invoice.toString();
	}

	return filters;
};
