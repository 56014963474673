import React, { Component, createRef } from 'react';
import { cloneDeep, each, first, get, includes, isEmpty, map, noop, split } from 'lodash';
import { bool, func } from 'prop-types';
import classNames from 'classnames';

import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { kvaasService, logoManagementService } from 'common/services';
import { invokeIfFunction, kvaasResources } from 'common/utilities';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import { SidebarContext } from 'common/contexts';
import FooterComponent from '../components/FooterComponent';
import Dropzone from 'react-dropzone';

const requestKeys = {
	DATA: 'data',
	KVAAS: 'kvaas',
	SAVE: 'save',
	REMOVE: 'remove',
	FETCH: 'fetch',
};

class LogoManagementComponent extends Component {
	constructor() {
		super();

		this.top = createRef();
		this.notificationRef = createRef();

		this.state = {
			errorMessages: [],
			logoUrl: null,
			imageFileBase64: null,
			imageFileName: null,
			expanded: false,
			isSaving: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			oldData: {
				logoManagement: null,
			},
			logoManagement: {
				coBrandPortal: false,
				includeCoBrandLogoOnReceipts: false,
				includeCoBrandLogoOnPymntReqEmails: false,
			},
		};
	}
	static contextType = SidebarContext;
	get showUpload() {
		const { imageFileBase64, logoUrl } = this.state;
		return !imageFileBase64 && !logoUrl;
	}
	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		const {
			props: { showLoader, handleError },
			fetchLogo,
			fetchKvaas,
		} = this;
		try {
			showLoader(true);
			const [logoUrl, logoManagement] = await Promise.all([fetchLogo(), fetchKvaas()]);
			const newState = this.mapResponseToState(logoManagement);

			if (logoUrl) {
				newState.logoUrl = logoUrl;
			}

			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
		} catch (e) {
			handleError(e);
		}
		showLoader(false);
	};

	fetchLogo = async () => {
		const { LogoUrl: logoUrl } = await this.props.makePendingRequest(
			logoManagementService.getLogoUrl(),
			requestKeys.DATA
		);
		return logoUrl && `${logoUrl}?${Date.now()}`;
	};

	fetchKvaas = async () => {
		const [logoManagement] = await this.props.makePendingRequest(
			kvaasService.get(kvaasResources.logoManagement),
			requestKeys.KVAAS
		);
		return logoManagement;
	};

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	mapResponseToState = (oldLogoManagement = this.state.oldData.logoManagement) => {
		const { logoManagement, oldData } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			logoManagement,
		};
		kvaasService.mapFieldsToState(
			newState,
			oldLogoManagement,
			'logoManagement',
			(value, key) => (newState['logoManagement'][key] = value)
		);

		return newState;
	};

	mapStateToRequiredFields = async setToDefaults => [
		await this.mapStateToFields('logoManagement', kvaasResources.logoManagement, setToDefaults),
	];

	mapStateToFields = async (key, { primaryKey, userSetting, defaultData }, resetToDefault) => {
		try {
			const newState = cloneDeep(this.state);
			const data = {};
			const sections = {
				logoManagement: this.state.logoManagement,
			};
			each(sections, section => {
				each(section, (field, fieldKey) => {
					if (resetToDefault) {
						data[fieldKey] = defaultData[fieldKey] ? defaultData[fieldKey] : false;
					} else {
						data[fieldKey] = field;
					}
				});
			});
			await this.setStateAsync(newState);
			return {
				newData: {
					revision: 0,
					data,
				},
				oldData: this.state.oldData[key],
				primaryKey,
				userSetting,
			};
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key, primaryKey, resetToDefault } });
		}
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	save = async setToDefaults => {
		const { showLoader, handleBlockChange, makePendingRequest, handleError } = this.props;
		let refreshData = false;
		let refNum;
		let error;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);

		try {
			const { imageFileBase64 } = this.state;
			const promises = [kvaasService.save(...mappedState)];

			if (imageFileBase64) {
				promises.push(new Promise(resolve => resolve(logoManagementService.uploadLogo(imageFileBase64))));
			}

			const [logoManagement, { LogoUrl: logoUrl } = {}] = await makePendingRequest(
				Promise.all(promises),
				requestKeys.SAVE
			);

			const newState = this.mapResponseToState(logoManagement[0]);
			newState.imageFileBase64 = null;
			newState.imageFileName = null;

			if (logoUrl) {
				newState.logoUrl = `${logoUrl}?${Date.now()}`;
				invokeIfFunction(this.context.updateSidebarLogo, {
					logoUrl: newState.logoUrl,
					coBrandPortal: newState.logoManagement.coBrandPortal,
				});
			}
			refNum = logoManagement[0].refNum;

			if (setToDefaults) {
				newState.logoManagement = {
					coBrandPortal: true,
					includeCoBrandLogoOnReceipts: false,
					includeCoBrandLogoOnPymntReqEmails: false,
				};
			}
			showLoader(false);
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
			handleBlockChange(false);
		} catch (e) {
			error = handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const logoManagement = await makePendingRequest(
					kvaasService.get(kvaasResources.logoManagement),
					requestKeys.REFRESH
				);
				const newState = this.mapResponseToState(logoManagement[0]);
				this.setState(newState, () => {
					if (!isEmpty(newState.errorMessages)) {
						this.scrollToTop();
					}
				});
				showLoader(false);
				handleBlockChange(false);
			} catch (e) {
				error = handleError(e, { delayMessage: true });
				if (error) {
					refreshData = true;
				} else {
					return;
				}
			}
			showLoader(false);
			if (!isEmpty(this.state.errorMessages)) {
				this.scrollToTop();
			} else {
				error.show();
			}
		}
		if (!error) {
			addNotification({
				message: setToDefaults ? (
					'Logo Management settings reset to default'
				) : (
					<div>
						<p className="spc--bottom--sml">Logo Management settings updated</p>
					</div>
				),
				ref: refNum,
				success: true,
			});
		}
	};

	removeLogo = async () => {
		const { showLoader, makePendingRequest, handleError } = this.props;
		const {
			logoUrl,
			imageFileBase64,
			logoManagement: { coBrandPortal },
		} = this.state;
		try {
			showLoader(true);
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			invokeIfFunction(this.context.updateSidebarLogo, { logoUrl: null, coBrandPortal });

			const newState = { imageFileBase64: null, modal: null, imageFileName: null };
			if (!logoUrl && imageFileBase64) {
				this.setState(newState, () => {
					addNotification({
						message: 'Logo Removed Successfully',
						success: true,
					});
				});
			} else {
				const { refNum: ref } = await makePendingRequest(logoManagementService.removeLogo(), requestKeys.REMOVE);
				newState.logoUrl = null;
				this.setState(newState, () => {
					addNotification({
						message: 'Logo Removed Successfully',
						ref,
						success: true,
					});
				});
			}
		} catch (e) {
			handleError(e);
		}
		showLoader();
	};

	handleCheckboxChange = ({ target: { name, checked, value, type } }) => {
		const newState = cloneDeep(this.state);
		const newValue = type === 'checkbox' ? checked : value;
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			newState[section][key] = newValue;
		} else {
			newState[name] = newValue;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	openCloseActionsModal = modal => this.setState({ modal });

	checkImageWidth = file =>
		new Promise(resolve => {
			const img = new Image();
			img.onload = function() {
				URL.revokeObjectURL(this.src);
				resolve(this.width);
			};
			img.src = URL.createObjectURL(file);
		});

	handleUploadLogo = async (acceptedFiles, rejectedFiles) => {
		if (rejectedFiles.length) {
			this.setState({
				errorMessages: ['Invalid file type'],
			});
			return false;
		}
		const file = first(acceptedFiles);
		if (file) {
			const imageWidth = await this.checkImageWidth(file);
			if (imageWidth > 842) {
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				addNotification({
					message: 'Please use a logo up to 842px.',
					success: false,
				});
				if (this.state.imageFileBase64) {
					this.setState({ imageFileBase64: null, imageFileName: null });
				}
				return;
			}
			const imageFileBase64 = await logoManagementService.convertImageToBase64(file);
			this.setState({ imageFileBase64, imageFileName: file.name });
			this.props.handleBlockChange(true);
		} else {
			this.setState({ imageFileBase64: null, imageFileName: null });
		}
	};

	render() {
		const {
			logoUrl,
			modal,
			errorMessages,
			logoManagement: { coBrandPortal, includeCoBrandLogoOnReceipts, includeCoBrandLogoOnPymntReqEmails },
			imageFileBase64,
			imageFileName,
		} = this.state;
		return (
			<div className="settings--main">
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} isLoading={this.props.isLoading} />
				<Notification ref={this.notificationRef} />

				<div className="settings__header">
					<h3 className="settings__title">Portal Settings</h3>
					<h5>Logo Management</h5>
				</div>

				<div ref={this.top}></div>

				<div className="notes notes--primary spc--bottom--lrg" id="logoManagementCloseMessage">
					<i className="icon" />
					<div>
						<p className="spc--bottom--med">
							In this section, you can upload your company logo, which will be displayed on emailed receipts. You can
							also choose to display your logo on receipts printed from the Portal, Send Payment Request emails, and on
							your Portal account.
						</p>
						<p>
							Please note, uploading a logo here will not be added to your PaymentSITE. To add a logo to your
							PaymentSITE, please go to PaymentSITE Management under Portal Account Settings.
						</p>
					</div>
				</div>

				{map(errorMessages, (error, index) => (
					<p key={index} className="type--validation">
						{error}
					</p>
				))}

				<div className="spc--bottom--lrg">
					{this.showUpload && (
						<Dropzone onDrop={this.handleUploadLogo} accept="image/png,image/jpg,image/jpeg">
							{({ getRootProps, getInputProps, isDragActive }) => {
								return (
									<React.Fragment>
										<p className="type--p2 type--p2--medium spc--bottom--sml--alt">Upload Logo</p>
										<div
											{...getRootProps()}
											className={classNames('upload', {
												draggOver: isDragActive,
												'is-loading': this.props.isLoading,
											})}
										>
											<input {...getInputProps()} />
											<div className="upload__icon"></div>
											<div className="upload__text">
												<p className="type--p3 type--p3--medium spc--bottom--tny">Upload Files or Drag and Drop</p>
												<p className="type--p4 type--color--text--light">Recommended image width is 842px.</p>
												<p className="type--p4 type--color--text--light">Allowed formats are .jpg, .jpeg.</p>
											</div>
										</div>
									</React.Fragment>
								);
							}}
						</Dropzone>
					)}

					{(imageFileBase64 || logoUrl) && (
						<div className="upload__list">
							<div className="upload__list__item">
								<div
									className="upload__list__item__thumbnail datatooltip--auto datatooltip--no-wrap"
									data-tooltip="Click to preview logo"
									onClick={() =>
										this.openCloseActionsModal({
											name: modalNames.previewLogo,
											data: { logoUrl: imageFileBase64 ? `data:image/jpeg;base64,${imageFileBase64}` : logoUrl },
										})
									}
								>
									<img src={imageFileBase64 ? `data:image/jpeg;base64,${imageFileBase64}` : logoUrl} alt="Logo" />
								</div>
								<p className="upload__list__item__name">{imageFileName}</p>
								<button
									type="button"
									tabIndex="-1"
									className="btn upload__list__item__remove datatooltip--auto datatooltip--no-wrap"
									data-tooltip="Remove logo"
									disabled={this.props.isLoading}
									onClick={() =>
										this.openCloseActionsModal({
											name: modalNames.confirmAction,
											data: {
												question: 'Are you sure you want to remove your logo?',
												onConfirm: this.removeLogo,
											},
										})
									}
								>
									<i className="icon icon--sml icon--close"></i>
								</button>
							</div>
						</div>
					)}
				</div>

				<div>
					<h4 className="type--p2 type--p2--medium spc--bottom--lrg">Settings</h4>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="logoManagement.coBrandPortal"
							name="logoManagement.coBrandPortal"
							checked={coBrandPortal}
							value={coBrandPortal}
							onChange={this.handleCheckboxChange}
							className="input--check"
						/>
						<label htmlFor="logoManagement.coBrandPortal">Include my logo on my merchant portal account</label>
					</div>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="logoManagement.includeCoBrandLogoOnReceipts"
							name="logoManagement.includeCoBrandLogoOnReceipts"
							checked={includeCoBrandLogoOnReceipts}
							value={includeCoBrandLogoOnReceipts}
							onChange={this.handleCheckboxChange}
							className="input--check"
						/>
						<label htmlFor="logoManagement.includeCoBrandLogoOnReceipts">
							Include my logo on portal print receipts
						</label>
					</div>
					<div className="spc--bottom--sml">
						<input
							type="checkbox"
							id="logoManagement.includeCoBrandLogoOnPymntReqEmails"
							name="logoManagement.includeCoBrandLogoOnPymntReqEmails"
							checked={includeCoBrandLogoOnPymntReqEmails}
							value={includeCoBrandLogoOnPymntReqEmails}
							onChange={this.handleCheckboxChange}
							className="input--check"
						/>
						<label htmlFor="logoManagement.includeCoBrandLogoOnPymntReqEmails">
							Include my logo on Send Payment Request emails
						</label>
					</div>
				</div>

				<FooterComponent save={this.save} isLoading={this.props.isLoading} disabled={this.props.isLoading} />
			</div>
		);
	}
}

LogoManagementComponent.propTypes = {
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool.isRequired,
	handleBlockChange: func.isRequired,
};

export default withError(withLoader(withCancelable(withBlock(LogoManagementComponent))));
