import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { get } from 'lodash';

import sectionKeys from '../../routing/sections';
import { kvaasService, principalService } from '../../Common/services';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import UserSettingsGeneral from './pages/UserSettingsGeneral';
import AccountDropdownOrder from './pages/AccountDropdownOrder';
import UserSettingsDashboard from './pages/UserSettingsDashboard';
import UserSettingsTransactionHistory from './pages/UserSettingsTransactionHistory';
import UserSettingsCustomers from './pages/UserSettingsCustomers';
import UserSettingsFraudwatch from './pages/UserSettingsFraudwatch';
import UserSettingsBatch from './pages/UserSettingsBatch';

import SettingsTemplate from 'components/settings/pages/SettingsTemplate';
import { Security } from 'components/login';
import { RecurringSettingsComponent } from 'components/settings/pages';
import UserSettingsNewTransaction from './pages/UserSettingsNewTransaction';
import UserSettingsDisputes from './pages/UserSettingsDisputes';
import { kvaasResources } from 'common/utilities';

const PlaceholderComponent = ({ location }) => (
	<div className="settings--main settings--main--alt">
		<h3 className="spc--top--sml">
			Placeholder For <code>{location.pathname}</code>
		</h3>
	</div>
);
PlaceholderComponent.propTypes = { location: PropTypes.object.isRequired };

let principal = principalService.get();
let userRole = get(principal, 'idInfo.permissions.role');
principalService.subscribe(newPrincipal => {
	principal = newPrincipal;
	userRole = get(newPrincipal, 'idInfo.permissions.role');
});

const isFraud = () => {
	return principal && principal.hasAccess && principal.hasAccess[sectionKeys.fraud];
};

const securityOnly = () => !get(principal, 'hasAccess.settings');

const isViewOnly = () => {
	return get(principal, 'isViewOnly', false);
};

const routes = showDisputes => [
	{
		path: '/user-settings',
		component: () => {
			return <Redirect to="/settings/user-settings" />;
		},
		label: 'User Settings',
		isMain: true,
		disabled: true,
		tooltip: 'Individual user preferences and options',
	},
	{
		path: '/general',
		component: UserSettingsGeneral,
		label: 'General',
		hidden: () => isFraud() || securityOnly(),
		isDefault: true,
		section: sectionKeys.settings,
	},
	{
		path: '/account-dropdown-order',
		component: AccountDropdownOrder,
		label: 'Account Dropdown Order',
		hidden: () => isFraud() || securityOnly(),
		section: sectionKeys.settings,
	},
	{
		path: '/dashboard',
		component: UserSettingsDashboard,
		label: 'Dashboard',
		hidden: () => isFraud() || securityOnly(),
		section: sectionKeys.settings,
	},
	{
		path: '/new-transaction',
		component: UserSettingsNewTransaction,
		label: 'New Transaction',
		hidden: () => isFraud() || isViewOnly() || securityOnly(),
		section: sectionKeys.transactions,
	},
	{
		path: '/transaction-history',
		component: UserSettingsTransactionHistory,
		label: 'Transaction History',
		hidden: () => isFraud() || securityOnly(),
		isDefault: () => userRole === 'saveonly',
		section: sectionKeys.settings,
	},
	{
		path: '/batch',
		component: UserSettingsBatch,
		label: 'Batch',
		hidden: () => isFraud() || securityOnly(),
		section: sectionKeys.batches,
	},
	{
		path: '/customers',
		component: UserSettingsCustomers,
		label: 'Customers',
		hidden: () => isFraud() || securityOnly(),
		isDefault: () =>
			principal && principal.idInfo && principal.idInfo.permissions && !principal.idInfo.permissions.allowReportAll,
		section: sectionKeys.settings,
	},
	{
		path: '/recurring-settings',
		component: RecurringSettingsComponent,
		label: 'Recurring',
		section: sectionKeys.dashboard,
		hidden: () => isFraud() || securityOnly(),
	},
	{
		path: '/security',
		component: Security,
		label: 'Security',
	},
	{
		path: '/disputes',
		component: UserSettingsDisputes,
		label: 'Disputes',
		section: sectionKeys.disputes,
		hidden: () => (showDisputes !== undefined && showDisputes === false) || securityOnly(),
	},
	{
		path: '/fraudwatch',
		component: UserSettingsFraudwatch,
		label: 'FraudWatch',
		section: sectionKeys.fraud,
	},
	{
		path: '/portal-settings',
		component: () => {
			return <Redirect to="/settings/portal-settings" />;
		},
		label: 'Portal Settings',
		hidden: isFraud,
		isMain: true,
		tooltip: 'Settings that apply across the portal and impact all users',
	},
	{
		path: '/gateway-settings',
		component: () => {
			return <Redirect to="/settings/gateway-settings" />;
		},
		label: 'Gateway Settings',
		hidden: isFraud,
		isMain: true,
		tooltip: 'Global gateway account settings that function independently of the portal',
	},
];

class UserSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	async componentDidMount() {
		const [portalFlags] = await kvaasService.get(kvaasResources.portalFlags);
		this.setState({ showDisputes: get(portalFlags, 'data.showDisputesTab', false) });
	}

	render = () => {
		const { showDisputes } = this.state;
		return (
			<SettingsTemplate
				title={'User Settings'}
				routes={routes(showDisputes)}
				redirectToRoute={'settings/user-settings/'}
				defaultRoute={{ label: 'General' }}
				defaultRouteUrl={'general'}
				isFraud={isFraud()}
				userRole={userRole}
				securityOnly={securityOnly()}
				{...this.props}
			/>
		);
	};
}

UserSettings.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
};

export default withError(withCancelable(UserSettings));
