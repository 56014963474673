import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { map, toLower, includes, split, cloneDeep, isEmpty, each, get, trim, find, some } from 'lodash';
import NumberFormat from 'react-number-format';

import { withBlock } from 'common/components/block';
import { withCancelable } from 'common/components/cancelable';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { Notification } from 'common/components/notifications';
import { customerService, kvaasService, principalService } from 'common/services';

import {
	compileFilter,
	recurringSchedulesFilter as Filter,
} from 'common/components/recurring-schedules/filter/recurringSchedulesFilter';
import {
	afterMaxRetriesActionOptions,
	kvaasResources,
	maxRetriesReachedNotificationTooltip,
	newAccountAfterMaxRetriesTooltip,
	retrySettingKeysList,
	warningMessage,
} from 'common/utilities';
import { validators } from 'common/fields';
import { Select } from 'common/components/select';
import { Tour } from 'common/components/tour';
import { isOlderThanSevenMonths } from 'common/utilities/isOlderThanSevenMonths';
import BaseSettings from 'components/settings/pages/BaseSettings';
import FooterComponent from 'components/settings/components/FooterComponent';
const handleInvalidRevision = error => {
	if (
		error &&
		(toLower(error.message) === 'invalid: revision' || toLower(error.message) === 'item exists. revision cannot be 0')
	) {
		return {
			...error,
			message: 'The data you were trying to update is not up to date. Fetching latest data...',
		};
	}
	return error;
};

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
	KVAAS: 'kvaas',
	REFRESH: 'refresh',
};

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'maxRetriesReachedNotificationEnabled',
	steps: [
		{
			selector: '#maxRetriesReachedNotificationEnabled',
			content: 'Get an email alert when a failed transaction reaches its maximum number of retries.',
		},
	],
};

class AccountManagementRecurringSettings extends BaseSettings {
	constructor(props) {
		super(props);

		this.refTop = createRef();
		this.notification = createRef();

		this.state = {
			sectionsExpanded: {
				general: false,
				display: false,
				processing: false,
				emails: false,
			},
			activeFilters: cloneDeep(Filter),
			isSaving: false,
			expanded: false,
			defaultEmail: '',
			emails: '',
			maxRetriesReachedNotificationEmails: '',
			emailsValid: true,
			errorMessages: [],
			merchant: {
				revision: 0,
				scheduleReportSettings: {
					enabled: false,
					emailAddresses: [],
				},
				maxRetriesReachedNotification: {
					enabled: false,
					emailAddresses: [],
				},
			},

			oldData: {
				recurringSchedules: null,
			},

			recurringSchedules: {
				allowInitialTransactionToDecline: false,
				setNewCardAsDefault: false,
				afterMaxRetriesAction: isOlderThanSevenMonths ? 'ContinueNextInterval' : 'Disable',
				failedTransactionRetryTimes: isOlderThanSevenMonths ? '5' : '3',
				daysBetweenRetries: isOlderThanSevenMonths ? '1' : '3',
				sendReceiptByDefault: false,
			},
		};
	}

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
		};
	};

	componentDidMount = async () => {
		await this.fetchData();
	};

	setNewState = newState => {
		this.setState(newState, () => {
			if (!isEmpty(newState.errorMessages)) {
				this.scrollToTop();
			}
		});
	};

	fetchData = async refreshData => {
		const {
			props: { showLoader, handleError },
			fetchKvaas,
			fetchMerchant,
		} = this;
		try {
			showLoader(true);
			const principal = principalService.get();
			const email = get(principal, 'email');
			const [merchant, { recurringSchedules }] = await Promise.all([fetchMerchant(email), fetchKvaas(refreshData)]);
			const newState = this.mapResponseToState(recurringSchedules, merchant);
			newState.defaultEmail = email;
			this.setNewState(newState);
			showLoader(false);
		} catch (e) {
			showLoader(false);
			handleError(e);
		}
	};

	fetchKvaas = async refreshData => {
		try {
			const [recurringSchedules] = await this.props.makePendingRequest(
				kvaasService.get({ ...kvaasResources.recurringSchedules, throwError: true }),
				refreshData ? requestKeys.REFRESH : requestKeys.KVAAS
			);
			return {
				recurringSchedules,
			};
		} catch (e) {
			if (!e || e.isCanceled) return;
			this.props.handleKvaasLoadError();
		}
	};

	fetchMerchant = async (defaultEmail = this.state.defaultEmail) => {
		const {
			result,
			scheduleReportSettings,
			maxRetriesReachedNotification,
			revision,
		} = await this.props.makePendingRequest(customerService.describeMerchant(), requestKeys.FETCH);
		if (toLower(result) === 's') {
			const emailAddresses = get(scheduleReportSettings, 'emailAddresses', []);
			const maxRetriesReachedNotificationEmails = get(maxRetriesReachedNotification, 'emailAddresses', []);

			if (isEmpty(emailAddresses)) {
				scheduleReportSettings.emailAddresses = [defaultEmail];
			}

			if (isEmpty(maxRetriesReachedNotificationEmails)) {
				maxRetriesReachedNotification.emailAddresses = [defaultEmail];
			}

			return { scheduleReportSettings, maxRetriesReachedNotification, revision };
		}
	};

	constructEmailsArray = emails => map(split(emails, ','), value => trim(value));

	updateMerchant = async resetToDefaults => {
		const { defaultEmail, emails, emailsValid, maxRetriesReachedNotificationEmails } = this.state;
		const merchantInfo = cloneDeep(this.state.merchant);

		if (emailsValid) {
			if (!isEmpty(emails)) {
				const emailsArr = this.constructEmailsArray(emails);
				merchantInfo.scheduleReportSettings.emailAddresses = emailsArr;
			}
			if (!isEmpty(maxRetriesReachedNotificationEmails)) {
				const emailsArr = this.constructEmailsArray(maxRetriesReachedNotificationEmails);
				merchantInfo.maxRetriesReachedNotification.emailAddresses = emailsArr;
			}
		}

		if (isEmpty(emails) || resetToDefaults) {
			merchantInfo.scheduleReportSettings.emailAddresses = [defaultEmail];
		}

		if (isEmpty(maxRetriesReachedNotificationEmails) || resetToDefaults) {
			merchantInfo.maxRetriesReachedNotification.emailAddresses = [defaultEmail];
		}

		if (resetToDefaults) {
			merchantInfo.scheduleReportSettings.enabled = false;
			merchantInfo.maxRetriesReachedNotification.enabled = false;
		}

		merchantInfo.scheduleReportSettings.showDetails = true;

		await customerService.updateMerchant(merchantInfo);

		return this.fetchMerchant();
	};

	expandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};
	updateExistingSchedulesRetrySettings = async () => {
		let error = '';
		let successRef = '';
		const xfilter = await this.props.makePendingRequest(compileFilter(this.state.activeFilters), requestKeys.FETCH);
		xfilter.Filters = { Active: false };
		const listSchedulesData = await this.props.makePendingRequest(
			customerService.filterRecurringSchedulesAll(null, xfilter),
			requestKeys.FETCH
		);
		const scheduleSettings = this.state.recurringSchedules;
		var scheduleData = {
			AfterMaxRetriesAction: scheduleSettings.afterMaxRetriesAction,
			DaysBetweenRetries: scheduleSettings.daysBetweenRetries,
			FailedTransactionRetryTimes: scheduleSettings.failedTransactionRetryTimes,
		};
		try {
			await this.props.makePendingRequest(
				Promise.all(
					map(listSchedulesData.xReportData, async result => {
						scheduleData.ScheduleId = result.scheduleId;
						scheduleData.Revision = result.revision;
						scheduleData.StartDate = result.startDate;
						scheduleData.CalendarCulture = result.calendarCulture;
						try {
							const resp = await customerService.updateScheduleRetryAmount(scheduleData);
							successRef = resp.ref;
						} catch (e) {
							if (!e.isCanceled) error += `\n${result.scheduleId} -${e.message} (${e.ref})`;
						}
					})
				)
			);
			return { error: error, ref: successRef, success: true };
		} catch (e) {
			if (!e.isCanceled) this.props.handleError(e);
		}
	};
	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	scrollToTop = () => {
		if (this.refTop.current) {
			this.refTop.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	mapErrorMessages = errorMessages => {
		return map(errorMessages, (error, index) => (
			<div key={index} className="badge badge--dot badge--error">
				{error}
			</div>
		));
	};

	handleRefreshData = async error => {
		let handledErr = error;
		let shouldRefresh = false;
		try {
			await this.fetchData(true);
		} catch (e) {
			handledErr = this.props.handleError(e, { delayMessage: true });
			if (handledErr) {
				shouldRefresh = true;
			}
		}
		this.props.showLoader(false);
		if (!isEmpty(this.state.errorMessages)) {
			this.scrollToTop();
		} else {
			handledErr.show();
		}
		return shouldRefresh;
	};
	handleSetToDefaults = (newState, merchant) => {
		newState.merchant = merchant;
		newState.recurringSchedules = {
			allowInitialTransactionToDecline: false,
			setNewCardAsDefault: false,
			afterMaxRetriesAction: isOlderThanSevenMonths ? 'ContinueNextInterval' : 'Disable',
			failedTransactionRetryTimes: isOlderThanSevenMonths ? '5' : '3',
			daysBetweenRetries: isOlderThanSevenMonths ? '1' : '3',
			sendReceiptByDefault: false,
		};
	};

	save = async setToDefaults => {
		let error;
		let refNum;
		let refreshData = false;
		this.props.showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);
		try {
			const [[recurringSchedules], merchant] = await this.props.makePendingRequest(
				Promise.all([kvaasService.save(...mappedState), this.updateMerchant(setToDefaults)]),
				requestKeys.SAVE
			);
			const newState = this.mapResponseToState(recurringSchedules, merchant);
			newState.emailsValid = true;
			refNum = recurringSchedules.refNum;
			if (setToDefaults) {
				this.handleSetToDefaults(newState, merchant);
			}

			this.props.showLoader(false);
			this.setNewState(newState);
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			refreshData = await this.handleRefreshData(error);
		}
		if (!error) {
			this.notification.current.addNotification({
				message: setToDefaults ? (
					'Recurring settings reset to default'
				) : (
					<p className="spc--bottom--sml">Recurring Settings Updated</p>
				),
				ref: refNum,
				success: true,
			});
		}
	};

	mapStateToRequiredFields = async setToDefaults => [
		await this.mapRecurringSettingsToState('recurringSchedules', kvaasResources.recurringSchedules, setToDefaults),
	];
	mapSection = (section, resetToDefault, defaultData, data) => {
		each(section, (field, fieldKey) => {
			const isRecurringKey = some(['daysBetweenRetries', 'failedTransactionRetryTimes'], item => item === fieldKey);
			const defaultValue = isRecurringKey ? '4' : false;

			if (resetToDefault) {
				this.handleSectionReset(data, defaultData, defaultValue, fieldKey);
			} else {
				if (fieldKey) data[fieldKey] = field;
				if (fieldKey === 'failedTransactionRetryTimes' || fieldKey === 'daysBetweenRetries') {
					if (data[fieldKey]) {
						data[fieldKey]++;
					} else {
						data[fieldKey] = defaultValue;
					}
				}
			}
		});
	};
	mapRecurringSettingsToState = async (key, { primaryKey, defaultData }, resetToDefault) => {
		try {
			const newState = cloneDeep(this.state);
			const data = {};
			const section = this.state[key];

			this.mapSection(section, resetToDefault, defaultData, data);
			await this.setStateAsync(newState);
			return {
				newData: {
					revision: 0,
					data,
				},
				oldData: this.state.oldData[key],
				primaryKey,
			};
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key, primaryKey, resetToDefault } });
		}
	};

	mapResponseToState = (oldSchedules = this.state.oldData.recurringSchedules, merchant = this.state.merchant) => {
		const { recurringSchedules, oldData } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			merchant,
			oldData,
			recurringSchedules,
		};
		const emailAddresses = get(merchant, 'scheduleReportSettings.emailAddresses');
		const maxRetriesReachedNotificationEmails = get(merchant, 'maxRetriesReachedNotification.emailAddresses');

		if (!isEmpty(emailAddresses)) {
			newState.emails = emailAddresses;
		}

		if (!isEmpty(maxRetriesReachedNotificationEmails)) {
			newState.maxRetriesReachedNotificationEmails = maxRetriesReachedNotificationEmails;
		}

		this.mapSettingsToState(newState, oldSchedules, 'recurringSchedules');
		return newState;
	};
	mapRetries = (data, newState, type) => {
		each(data, (value, key) => {
			if (key === 'failedTransactionRetryTimes' || key === 'daysBetweenRetries') {
				value = (value - 1).toString();
			}
			if (key === 'afterMaxRetriesAction') {
				value = isOlderThanSevenMonths ? value : 'Disable';
			}
			newState[type][key] = value;
		});
	};
	mapSettingsToState = (newState, oldData, type) => {
		const { error, refNum, data, result } = oldData;
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				newState.oldData[type] = {
					...oldData,
				};
			}
			this.mapRetries(data, newState, type);
		} else if (toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0') {
			this.props.handleError({
				isApiError: true,
				ref: refNum,
				message: error,
				success: false,
			});
		} else {
			const refString = ` (Ref# ${refNum})`;
			newState.errorMessages.push(`${error}${refNum ? refString : ''}`);
		}
	};

	handleSectionReset = (data, defaultData, defaultValue, fieldKey) => {
		if (!includes(retrySettingKeysList, fieldKey)) {
			data[fieldKey] = defaultData[fieldKey] || false;
		} else {
			data[fieldKey] = (isOlderThanSevenMonths && defaultData[fieldKey]) || defaultValue;
		}
	};

	handleCheckboxChange = ({ target: { name, checked } }) => {
		const newState = cloneDeep(this.state);
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			if (includes(['scheduleReportSettings', 'maxRetriesReachedNotification'], section)) {
				newState.merchant[section][key] = checked;
			} else {
				newState[section][key] = checked;
			}
		} else {
			newState[name] = checked;
		}

		this.setState(newState, this.validateEmails);
		this.props.handleBlockChange(true);
	};

	handleNumberAndSelectChange = (name, value) => {
		const newState = {};
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			if (includes(['scheduleReportSettings', 'maxRetriesReachedNotification'], section)) {
				newState.merchant = {
					...this.state.merchant,
					[section]: {
						...this.state.merchant[section],
						[key]: value,
					},
				};
			} else {
				newState[section] = {
					...this.state[section],
					[key]: value,
				};
			}
		} else {
			newState[name] = value;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	handleSelectChange = ({ name: value }, { name }) => {
		this.handleNumberAndSelectChange(name, value);
	};

	handleNumberChange = ({ value }, { target: { name } }) => {
		this.handleNumberAndSelectChange(name, value);
	};

	checkIfAllowedValue = ({ value }) => {
		return value > -1 && value < (isOlderThanSevenMonths ? 11 : 4);
	};

	checkIfAllowedDaysBetweenRetries = ({ value }) => {
		return (value ? value > 0 : true) && value < (isOlderThanSevenMonths ? 100 : 4);
	};

	handleEmailChange = ({ target: { value, name } }) => {
		const newState = { [name]: value };
		this.setState(newState, this.validateEmails);
	};

	validateEmails = () => {
		const {
			emails,
			merchant: {
				scheduleReportSettings: { enabled },
				maxRetriesReachedNotification: { enabled: maxRetriesEnabled },
			},
			maxRetriesReachedNotificationEmails,
		} = this.state;

		let emailsValid = true;

		if (maxRetriesEnabled && !validators.email(maxRetriesReachedNotificationEmails)) {
			emailsValid = false;
		}

		if (enabled && !validators.email(emails)) {
			emailsValid = false;
		}

		this.setState({ emailsValid });
	};

	renderEmailSection = (
		sendReceiptByDefault,
		enabled,
		emails,
		maxRetriesReachedNotificationEnabled,
		maxRetriesReachedNotificationEmails
	) => {
		return (
			<div>
				<h4 className="type--p2 type--p2--medium spc--bottom--lrg">Emails</h4>

				<div className="spc--bottom--med">
					<input
						type="checkbox"
						id="recurringSchedules.sendReceiptByDefault"
						name="recurringSchedules.sendReceiptByDefault"
						checked={sendReceiptByDefault}
						value={sendReceiptByDefault}
						onChange={this.handleCheckboxChange}
						className="input--check"
					/>
					<label htmlFor="recurringSchedules.sendReceiptByDefault">
						Send The Customer A Recurring Schedule Receipt By Default
					</label>
				</div>
				<div>
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="scheduleReportSettings.enabled"
							name="scheduleReportSettings.enabled"
							checked={enabled}
							value={enabled}
							onChange={this.handleCheckboxChange}
							className="input--check"
						/>
						<label htmlFor="scheduleReportSettings.enabled">Enable Recurring Schedule Email Summary Report</label>
					</div>
					{enabled && (
						<div className="spc--bottom--med">
							<div className="form__group__header">
								<label htmlFor="email" className="form__group__label">
									To
								</label>
								{enabled && (
									<i
										data-tooltip="Separate multiple emails with commas."
										className="icon icon--tny icon--regular--info"
									></i>
								)}
							</div>
							<input
								type="text"
								name="emails"
								className="input input--med"
								placeholder="Emails"
								value={emails}
								onChange={this.handleEmailChange}
							/>
						</div>
					)}
				</div>
				<div>
					<div className="flex--primary flex--nowrap spc--bottom--med">
						<div>
							<input
								type="checkbox"
								id="maxRetriesReachedNotification.enabled"
								name="maxRetriesReachedNotification.enabled"
								checked={maxRetriesReachedNotificationEnabled}
								value={maxRetriesReachedNotificationEnabled}
								onChange={this.handleCheckboxChange}
								className="input--check"
							/>
							<label id="maxRetriesReachedNotificationEnabled" htmlFor="maxRetriesReachedNotification.enabled">
								Send Email When Max. Number of Retries is Reached
							</label>
						</div>
						<i
							className="icon icon--tny icon--regular--info spc--left--tny flex--no-shrink datatooltip--top-left"
							data-tooltip={maxRetriesReachedNotificationTooltip}
						></i>
					</div>
					{maxRetriesReachedNotificationEnabled && (
						<div>
							<div className="form__group__header">
								<label htmlFor="maxRetriesReachedNotificationEmails" className="form__group__label">
									To
								</label>
								<i
									data-tooltip="Separate multiple emails with commas."
									className="icon icon--tny icon--regular--info"
								></i>
							</div>
							<input
								type="text"
								name="maxRetriesReachedNotificationEmails"
								className="input input--med"
								placeholder="Emails"
								value={maxRetriesReachedNotificationEmails}
								onChange={this.handleEmailChange}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};
	shouldDisableSaveButton = () => {
		const {
			emailsValid,
			recurringSchedules: { daysBetweenRetries },
		} = this.state;
		return (
			this.props.isLoading || !emailsValid || !this.checkIfAllowedDaysBetweenRetries({ value: daysBetweenRetries })
		);
	};

	render = () => {
		const {
			errorMessages,
			emails,
			emailsValid,
			merchant: {
				scheduleReportSettings: { enabled },
				maxRetriesReachedNotification: { enabled: maxRetriesReachedNotificationEnabled },
			},
			recurringSchedules: {
				afterMaxRetriesAction,
				allowInitialTransactionToDecline,
				setNewCardAsDefault,
				failedTransactionRetryTimes,
				daysBetweenRetries,
				sendReceiptByDefault,
			},
			maxRetriesReachedNotificationEmails,
		} = this.state;
		const { isLoading } = this.props;
		const afterMaxRetriesActionValue = find(afterMaxRetriesActionOptions, { name: afterMaxRetriesAction });
		return (
			<div className="settings--main">
				<div className="settings__header">
					<h3 className="settings__title">Portal Settings</h3>
					<h5>Recurring Settings</h5>
				</div>

				<div ref={this.refTop}></div>
				{this.state.errorMessages.length > 0 ? this.mapErrorMessages(errorMessages) : null}
				{!emailsValid ? (
					<div className="type--validation spc--bottom--lrg">Please enter a valid email address</div>
				) : null}

				<div className="spc--bottom--med">
					<input
						type="checkbox"
						id="recurringSchedules.setNewCardAsDefault"
						name="recurringSchedules.setNewCardAsDefault"
						checked={setNewCardAsDefault}
						value={setNewCardAsDefault}
						onChange={this.handleCheckboxChange}
						className="input--check"
					/>
					<label htmlFor="recurringSchedules.setNewCardAsDefault">
						Upon adding a new card, automatically set it as the default card
					</label>
				</div>
				<div className="spc--bottom--lrg">
					<input
						type="checkbox"
						id="recurringSchedules.allowInitialTransactionToDecline"
						name="recurringSchedules.allowInitialTransactionToDecline"
						checked={allowInitialTransactionToDecline}
						value={allowInitialTransactionToDecline}
						onChange={this.handleCheckboxChange}
						className="input--check"
					/>
					<label htmlFor="recurringSchedules.allowInitialTransactionToDecline">
						Create the schedule even if the initial payment fails
					</label>
				</div>
				<div className="notes notes--primary spc--bottom--lrg">
					<i className="icon" />
					<div>
						<p className="spc--bottom--sml">
							Note: If you set up a schedule that recurs sooner than the retry settings, its transactions won't be
							retried.
						</p>
						<p className="type--p4--medium">Total tries includes the original transaction.</p>
					</div>
				</div>

				{failedTransactionRetryTimes > 5 && <div className="type--validation spc--bottom--med">{warningMessage}</div>}

				<div className="f-row f-row--bottom">
					<div className="f-col f-col-sml-12 f-col-med-6 form__group">
						<div className="form__group__header">
							<span className="form__group__label">After</span>
							{!isOlderThanSevenMonths && (
								<i
									className="icon icon--tny icon--regular--info spc--left--tny"
									data-tooltip="Due to concerns of risk, retries are limited to a maximum of 3 retries, on new Merchant accounts."
								/>
							)}
						</div>
						<div className="input--tries">
							<NumberFormat
								id="recurringSchedules.failedTransactionRetryTimes"
								name="recurringSchedules.failedTransactionRetryTimes"
								className="input input--med"
								onValueChange={this.handleNumberChange}
								value={failedTransactionRetryTimes <= 0 ? 1 : failedTransactionRetryTimes}
								format="##"
								allowNegative={false}
								isAllowed={this.checkIfAllowedValue}
								placeholder={isOlderThanSevenMonths ? '1-10' : '1-3'}
								inputMode="numeric"
								disabled={isLoading}
							/>
						</div>
					</div>
					<div className="f-col f-col-sml-12 f-col-med-6 form__group">
						<NumberFormat
							id="recurringSchedules.daysBetweenRetries"
							name="recurringSchedules.daysBetweenRetries"
							className="input input--med"
							onValueChange={this.handleNumberChange}
							value={daysBetweenRetries}
							format="##"
							allowNegative={false}
							placeholder={isOlderThanSevenMonths ? '1' : '3'}
							isAllowed={this.checkIfAllowedDaysBetweenRetries}
							inputMode="numeric"
							disabled={isLoading}
						/>
					</div>
				</div>

				<div className="form__group">
					<div className="form__group__header">
						<span className="form__group__label">Day(s) between Retries</span>
						<i
							className="icon icon--tny icon--regular--info spc--left--tny"
							data-tooltip={afterMaxRetriesActionValue.tooltip}
						></i>
					</div>
					<div data-tooltip={!isOlderThanSevenMonths ? newAccountAfterMaxRetriesTooltip : null}>
						<Select
							name="recurringSchedules.afterMaxRetriesAction"
							id="recurringSchedules.afterMaxRetriesAction"
							value={afterMaxRetriesActionValue}
							options={afterMaxRetriesActionOptions}
							onChange={this.handleSelectChange}
							menuPlacement="auto"
							isDisabled={!isOlderThanSevenMonths}
						/>
					</div>
				</div>

				{this.renderEmailSection(
					sendReceiptByDefault,
					enabled,
					emails,
					maxRetriesReachedNotificationEnabled,
					maxRetriesReachedNotificationEmails
				)}
				<Notification ref={this.notification} />
				{!this.props.isLoading && <Tour tourConfig={tourConfig} />}
				<FooterComponent disabled={this.shouldDisableSaveButton()} isLoading={this.props.isLoading} save={this.save} />
			</div>
		);
	};
}

AccountManagementRecurringSettings.propTypes = {
	isLoading: PropTypes.bool,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	showLoader: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};

export default withError(
	withLoader(withCancelable(withBlock(AccountManagementRecurringSettings))),
	handleInvalidRevision
);
