import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';

import { principalService, authenticationService } from 'common/services';
import { withLoader } from 'common/components/loader';
import { withCancelable } from 'common/components/cancelable';
import { Notification } from 'common/components/notifications';
import { getMail, logger, parseError } from 'common/utilities';

class SamlLoginComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMessage: null,
		};
	}

	async componentWillMount() {
		try {
			const { history } = this.props;
			let currentAuthUser = await Auth.currentAuthenticatedUser();
			const { username } = currentAuthUser;

			if (principalService.get() && currentAuthUser) {
				principalService.clear();
			}

			if (!principalService.get() && currentAuthUser) {
				try {
					const {
						signInUserSession: {
							idToken: { jwtToken },
						},
					} = currentAuthUser;

					try {
						const auth = await authenticationService.login(jwtToken, username);
						this.props.showLoader(false);
						if (auth.success) {
							history.push('/login');
						} else {
							this.notification.addNotification({
								message: auth.message,
								ref: auth.ref,
								success: auth.success,
							});
						}
					} catch (err) {
						if (err && err.isCanceled) {
							return;
						}
						logger.logError({
							message: 'Authentication error occurred.',
							errorDetails: err,
							username,
						});
						const { stack } = parseError(err);
						this.props.showLoader(false);
						if (
							err &&
							err.ex &&
							err.ex.response &&
							(err.ex.response.status === 401 || err.ex.response.status === 403)
						) {
							this.setState({
								errorMessage:
									'You are not authorized to access the page. Contact customer support: gatewaysupport@solapayments.com',
							});
						} else {
							this.setState({ errorMessage: getMail(stack, {}) });
						}
					}
				} catch (err) {
					Auth.signOut();
					await Auth.federatedSignIn();
				}
			}
		} catch (ex) {
			Auth.signOut();
			await Auth.federatedSignIn();
		}
	}

	render() {
		const { errorMessage } = this.state;
		return (
			<div>
				Logging in...
				<Notification ref={el => (this.notification = el)} />
				{errorMessage ? <div className="spc--top--sml type--color--error">{errorMessage}</div> : null}
			</div>
		);
	}
}

SamlLoginComponent.propTypes = {
	showLoader: PropTypes.func,
};

export default withCancelable(withLoader(SamlLoginComponent));
