import React from 'react';
import { map } from 'lodash';

import { CurrencyComponent } from '../../columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';

const columns = [
	{
		key: 'date',
		name: 'Date',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		fixWidth: true,
		initWidth: 120,
	},
	{
		key: 'paymentsRemaining',
		name: 'Payments remaining',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
	},
	{
		key: 'amount',
		name: 'Amount',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: props => (
			<div className="flex--primary flex--right">
				<CurrencyComponent {...props} />
			</div>
		),
		getRowMetaData: ({ currency }) => ({ currency, alignLeftOnGrid: false }),
		alignHeaderRight: true,
		alignLeftOnPrint: true,
		fixWidth: true,
		initWidth: 140,
	},
];

export const SchedulePreviewColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
