import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bool, func, object, string } from 'prop-types';
import { Auth } from 'aws-amplify';
import {
	cloneDeep,
	find,
	get,
	inRange,
	includes,
	join,
	last,
	map,
	replace,
	size,
	split,
	toLower,
	uniqueId,
} from 'lodash';

import { authenticationService, kvaasService, principalService } from 'common/services';
import { parseError, getMail, logger, kvaasResources, initialPageOptions } from 'common/utilities';
import { withLoader } from 'common/components/loader';
import { Notification } from 'common/components/notifications';
import { withCancelable } from 'common/components/cancelable';
import cognitoErrorMap from 'common/utilities/cognitoErrorMapping';
import { withError } from 'common/components/error';
import PrivacyPolicyFooter from 'common/components/footer/PrivacyPolicyFooter';

const messages = {
	SMS_MFA: destination => (
		<Fragment>A security code has been sent to you by SMS ({destination}). Enter the code to continue.</Fragment>
	),
	SOFTWARE_TOKEN_MFA: () => (
		<Fragment>Enter the confirmation code from your Multi Factor Authentication (MFA) app</Fragment>
	),
	DEFAULT: () => <Fragment>Enter the confirmation code to continue</Fragment>,
};

class ConfirmMFAComponent extends Component {
	constructor(props) {
		super(props);

		const user = (props.location.state && props.location.state.user) || null;
		const username = (props.location.state && props.location.state.username) || null;
		const password = (props.location.state && props.location.state.password) || null;
		const usernamePersistenceKey = (props.location.state && props.location.state.usernamePersistenceKey) || null;
		const rememberUser = (props.location.state && props.location.state.rememberUser) || null;
		const challengeName =
			(props.location.state && props.location.state.challengeName) || props.challengeName || 'DEFAULT';
		const challengeDestination = (props.location.state && props.location.state.challengeDestination) || null;
		const isStepup = !!props.isStepup;
		const key = `mfa-${props.componentKey || replace(props.location.pathname, /\//g, '-')}`;

		this.state = {
			user,
			username,
			password,
			usernamePersistenceKey,
			rememberUser,
			rememberDevice: false,
			challengeName,
			challengeDestination,
			errorMessage: null,
			successMessage: null,
			isStepup,
			isStepupInitialized: false,
			key,
			inputs: [
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
				{ key: uniqueId('input'), value: '', ref: React.createRef() },
			],
		};
		this.elements = Array(6)
			.fill()
			.map(() => React.createRef());
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
		window.addEventListener('paste', this.handlePaste);

		if (this.state.isStepup) {
			try {
				let { key } = this.state;
				const principal = localStorage.getItem('PRINCIPAL');
				const isCognitoAdmin = principal && JSON.parse(principal).isAdmin;
				const lastVerified = localStorage.getItem(key);
				let sentCode = localStorage.getItem(`${key}-sent`);
				if (!isNaN(Number(lastVerified))) {
					const date = new Date();
					const minutesAgo = isCognitoAdmin ? 60 : 20;
					date.setMinutes(date.getMinutes() - minutesAgo);

					if (Number(lastVerified) > date.getTime()) {
						this.props.markAsVerified();
						return;
					} else if (Number(lastVerified) > 0) {
						// it was sent more than 20 minutes ago -- resend
						sentCode = 0;
					}
				}
				this.props.showLoader(true);
				let user = await Auth.currentAuthenticatedUser();
				const data = await this.getCognitoUserDataAsync(user);

				const { PreferredMfaSetting, UserAttributes } = data;
				const isStepupSmsMfa = PreferredMfaSetting === 'SMS_MFA';
				const isStepupSoftwareMfa = PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA';

				if (!isStepupSmsMfa && !isStepupSoftwareMfa) {
					// no MFA configured - for example, automated tests
					this.props.markAsVerified();
					return;
				}

				let destination = 'Software device';
				if (isStepupSmsMfa) {
					destination = 'XXXX';
					const phone = UserAttributes.find(att => att.Name === 'phone_number');
					if (phone) destination = `+*******${phone.Value.slice(-4)}`;

					if (!sentCode) {
						destination = await this.sendVerificationCode(user);
						localStorage.setItem(`${key}-sent`, 1);
					}
				}
				localStorage.setItem(this.state.key, '0');

				this.setState({
					user,
					isStepupSmsMfa,
					isStepupSoftwareMfa,
					challengeName: PreferredMfaSetting,
					challengeDestination: destination,
					isStepupInitialized: true,
				});

				this.props.showLoader();
			} catch (error) {
				const notification = this.props.handleError(error);
				const errorMessages = [
					'network error',
					'failed to fetch',
					'failed to load resource',
					'the network connection was lost',
				];
				const errorMessage = toLower(get(error, 'message', ''));

				if (includes(errorMessages, errorMessage)) {
					notification.message =
						'A network error occurred while attempting to authenticate. Please check your network connection and try refreshing the page.';
				} else {
					notification.message = this.getMessage(error) || errorMessage;
				}
			}
		} else if (!get(this.props, 'location.state.user')) {
			this.props.history.push('/');
		}
	}
	componentWillUnmount() {
		window.removeEventListener('paste', this.handlePaste);
	}
	get challengeAnswer() {
		return join(map(this.state.inputs, 'value'), '');
	}
	getMessage = err => {
		let message;
		switch (err.code) {
			case 'PasswordResetRequiredException': {
				message =
					'Please change your password. Our system indicates your current password is too generic, was flagged as compromised in the past, or has expired.';
				break;
			}

			case 'MFAMethodNotFoundException': {
				message = 'Multi factor authentication setup is incomplete, please contact customer service for assistance';
				break;
			}
			default: {
				message = null;
			}
		}
		return message;
	};
	getCognitoUserDataAsync = user => {
		return new Promise((resolve, reject) => {
			user.getUserData(
				(err, data) => {
					if (err) {
						reject(err);
						return;
					}
					resolve(data);
				},
				{ bypassCache: true }
			);
		});
	};

	sendVerificationCode = async user => {
		const { showLoader, makePendingRequest } = this.props;
		const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', '');

		try {
			showLoader(true);
			const {
				CodeDeliveryDetails: { Destination: destination },
			} = await makePendingRequest(
				authenticationService.getUserAttributeVerificationCode(accessToken, 'phone_number'),
				'send'
			);
			showLoader();

			return destination;
		} catch (e) {
			this.handleStepupError(e);
		}
	};

	handleKeyDown = (e, i) => {
		if (inRange(e.key, 0, 10)) {
			this.setState(
				prevState => {
					const inputs = cloneDeep(prevState.inputs);
					inputs[i].value = e.key;
					return { inputs };
				},
				() => {
					if (this.elements[i + 1]) {
						this.elements[i + 1].current.focus();
					}
				}
			);
		} else if (e.key === 'Backspace') {
			const currentValue = get(this.state.inputs[i], 'value', '');
			this.setState(
				prevState => {
					const inputs = cloneDeep(prevState.inputs);
					inputs[i].value = '';
					return { inputs };
				},
				() => {
					if (this.elements[i - 1] && !currentValue) {
						this.elements[i - 1].current.focus();
					}
				}
			);
		}
	};

	handleAutoSubmit = () => {
		if (this.state.isStepup && size(this.challengeAnswer) === 6) {
			this.handleSubmit({ preventDefault: () => {} });
		}
	};

	handlePaste = e => {
		e.preventDefault();
		const paste = e.clipboardData.getData('text');
		// Check if paste contains only digits
		if (!/^\d+$/.test(paste)) {
			return;
		}
		const pasteArray = split(paste, '');
		if (size(pasteArray) === size(this.elements)) {
			const inputs = map(pasteArray, value => ({ value }));
			this.setState({ inputs }, () => {
				last(this.elements).current.focus();
				this.handleAutoSubmit();
			});
		}
	};

	toggleRemember = event => {
		this.setState({ rememberDevice: event.target.checked });
	};

	async handleSubmit(event) {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		const isStepup = this.state.isStepup;
		if (isStepup) {
			await this.completeStepup();
		} else {
			await this.completeSignin();
		}
	}

	completeStepup = async () => {
		const { isStepupSmsMfa, isStepupSoftwareMfa, user, key } = this.state;
		const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', '');
		try {
			if (isStepupSoftwareMfa) {
				await Auth.verifyTotpToken(user, this.challengeAnswer);
			} else if (isStepupSmsMfa) {
				await authenticationService.verifyUserAttribute(accessToken, 'phone_number', this.challengeAnswer);
			}

			localStorage.setItem(key, Date.now().toString());
			this.props.markAsVerified();
		} catch (err) {
			this.handleStepupError(err);
		}
	};
	routeTologin = err => {
		if (includes(toLower(err.message), 'invalid session')) {
			const { history } = this.props;
			history.push({
				pathname: '/login',
				state: {
					errorMessage: 'Your session expired. Please sign in again.',
				},
			});
		}
	};
	handleStepupError = async err => {
		// Token is not verified
		let defaultMessage = (
			<p className="type--color--error spc--bottom--sml">
				Something went wrong. Please{' '}
				<a className="btn btn--link" href="/logout">
					try logging in again.
				</a>
			</p>
		);

		let respBody = get(err, 'response');
		if (!err.message && !!respBody) {
			// const unit8ChunksList = await this.yieldUint8Chunks(respBody);
			err = respBody;
		}

		let message = cognitoErrorMap[err.__type] || cognitoErrorMap[err.code] || err.message || defaultMessage;
		this.routeTologin(err);

		if (message.message) {
			message = message.message;
		}

		this.setState(
			{
				errorMessage: message,
				successMessage: null,
			},
			this.props.showLoader
		);
	};

	completeSignin = async () => {
		const { user, username, usernamePersistenceKey, challengeName, rememberUser, rememberDevice } = this.state;

		const { showLoader, makePendingRequest } = this.props;

		showLoader(true);

		Auth.confirmSignIn(user, this.challengeAnswer, challengeName)
			.then(async authUser => {
				const token =
					(authUser &&
						authUser.signInUserSession &&
						authUser.signInUserSession.idToken &&
						authUser.signInUserSession.idToken.jwtToken) ||
					false;

				if (rememberDevice) {
					await new Promise((resolve, reject) => {
						authUser.setDeviceStatusRemembered({
							onSuccess: function() {
								resolve();
							},
							onFailure: function(err) {
								reject(err);
							},
						});
					});
				} else {
					await new Promise((resolve, reject) => {
						authUser.setDeviceStatusNotRemembered({
							onSuccess: function() {
								resolve();
							},
							onFailure: function(err) {
								reject(err);
							},
						});
					});
				}

				if (token) {
					try {
						const auth = await makePendingRequest(authenticationService.login(token, username));
						showLoader(false);

						if (auth.success) {
							rememberUser
								? localStorage.setItem(usernamePersistenceKey, username)
								: localStorage.removeItem(usernamePersistenceKey);
							await this.redirect(auth.redirectToTerms, auth.isDefaultKey, auth.allKeys, token, username);
						} else {
							Auth.signOut();
							this.notification.addNotification({
								message: auth.message,
								ref: auth.ref,
								success: auth.success,
							});
						}
					} catch (err) {
						if (err && err.isCanceled) {
							return;
						}

						logger.logError({
							message: 'Authentication error occurred.',
							errorDetails: err,
							username,
						});
						const { stack } = parseError(err);
						showLoader(false);
						if (
							err &&
							err.ex &&
							err.ex.response &&
							(err.ex.response.status === 401 || err.ex.response.status === 403)
						) {
							this.setState({
								errorMessage:
									'You are not authorized to access the page. Contact customer support: gatewaysupport@solapayments.com',
							});
						} else {
							this.setState({ errorMessage: getMail(stack, {}) });
						}
					}
				} else {
					this.setState({
						errorMessage: 'You are not authorized to access the page. Contact customer support: gatewaysupport@solapayments.com',
					});
					showLoader(false);
				}

				showLoader();
			})
			.catch(err => {
				// Token is not verified
				let message = (
					<p className="type--color--error spc--top--sml">
						Something went wrong. Please{' '}
						<a className="btn btn--link btn--link--eror btn--link--underline" href="/logout">
							try logging in again.
						</a>
					</p>
				);

				if (err && err.code) {
					this.routeTologin(err);
					message = cognitoErrorMap[err.code] || { message };
					message = replace(message.message, '[ERR]', err.message);
				}
				this.setState(
					{
						errorMessage: message,
						successMessage: null,
					},
					showLoader
				);
			});
	};

	formValidation = () => {
		if (this.challengeAnswer.length <= 0) {
			this.setState({
				errorMessage: 'Please enter the code to verify',
				successMessage: null,
			});
			return false;
		}

		return true;
	};

	resendSms = async () => {
		this.props.showLoader(true);
		const { username, password, isStepupSmsMfa } = this.state;
		try {
			if (isStepupSmsMfa) {
				const destination = await this.sendVerificationCode(this.state.user);
				this.setState({
					successMessage: 'Resent SMS successfully',
					challengeDestination: destination || this.state.challengeDestination,
				});
			} else {
				const user = await Auth.signIn(username, password);
				this.setState({
					successMessage: 'Resent SMS successfully',
					user,
					challengeName: user.challengeName,
					challengeDestination:
						user.challengeParam.CODE_DELIVERY_DESTINATION || user.challengeParam.FRIENDLY_DEVICE_NAME,
				});
			}
			this.props.showLoader();
		} catch (err) {
			logger.logError({
				message: 'Resend SMS error occurred.',
				errorDetails: err,
				username,
			});
			this.setState({
				errorMessage: (
					<span>
						Failed to resend SMS. Please try going through the{' '}
						<Link to={{ pathname: '/login', state: { username } }} className="btn btn--link">
							login
						</Link>{' '}
						process again.
					</span>
				),
			});
			this.props.showLoader(false);
			return;
		}
	};

	redirect = async () => {
		const { history, location } = this.props;
		let redirectUrl = '/';
		let additionalState;

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}
		if (redirectUrl === '/') {
			try {
				const [userSettings] = await kvaasService.get(kvaasResources.userSettings);
				const initialPage = get(userSettings, 'data.initialPage', false);
				const isViewOnly = get(principalService.get(), 'isViewOnly', false);
				if (initialPage) {
					const route = find(initialPageOptions, { key: initialPage });
					if (route && !(isViewOnly && includes(['newTransactions', 'newCustomers'], route.key))) {
						redirectUrl = route.path;
						additionalState = route.state;
					}
				}
			} catch (e) {
				logger.logError({
					message: 'Initial page redirect error.',
					errorDetails: e,
				});
			}
		}
		history.push({
			pathname: redirectUrl,
			...(additionalState || {}),
		});
	};

	render() {
		const {
			errorMessage,
			successMessage,
			challengeName,
			challengeDestination,
			rememberDevice,
			isStepup,
			isStepupInitialized,
		} = this.state;
		return (
			<React.Fragment>
				<form className="auth__form" onSubmit={this.handleSubmit}>
					<h2 className="auth__form__title">{isStepup ? 'Confirm Access' : 'Confirm Code'}</h2>
					{isStepup && (
						<div className="type--validation spc--bottom--lrg">This action requires further authentication</div>
					)}
					{(!isStepup || isStepupInitialized) && (
						<Fragment>
							{challengeName && (
								<p className="auth__form__description">{messages[challengeName](challengeDestination)}</p>
							)}
							<div className="form__group">
								<div className="form__group__header spc--bottom--sml">
									<span className="form__group__label">Confirmation code</span>
								</div>
								<div className="input--security">
									{this.state.inputs.map((input, i) => (
										<input
											autoFocus={i === 0}
											type="number"
											className="input"
											placeholder="0"
											value={input.value}
											key={input.key}
											ref={this.elements[i]}
											onKeyDown={e => this.handleKeyDown(e, i)}
											onPaste={this.handlePaste}
										/>
									))}
								</div>
							</div>
							{!isStepup && (
								<div>
									<input
										type="checkbox"
										id="rememberDevice"
										checked={rememberDevice}
										onChange={this.toggleRemember}
										className="input--check"
										disabled={this.props.isLoading}
									></input>
									<label htmlFor="rememberDevice">Remember this device for 90 days</label>
								</div>
							)}
							{errorMessage ? <div className="type--color--error spc--bottom--sml">{errorMessage}</div> : null}
							{successMessage ? <div className="type--color--success spc--bottom--sml">{successMessage}</div> : null}
							<button
								disabled={this.props.isLoading || this.challengeAnswer.length !== 6}
								type="submit"
								className="btn btn--primary btn--lrg no-gap spc--top--xlrg"
							>
								<span>Confirm</span>
								{!isStepup && <span className="hide--to--sml--inline spc--left--tny">Sign-In</span>}
							</button>
							{challengeName === 'SMS_MFA' && (
								<button
									disabled={this.props.isLoading}
									type="button"
									onClick={this.resendSms}
									className="btn btn--link btn--lrg spc--top--sml"
								>
									Resend Code
								</button>
							)}
						</Fragment>
					)}

					<Notification ref={el => (this.notification = el)} />
				</form>

				<PrivacyPolicyFooter />
			</React.Fragment>
		);
	}
}

ConfirmMFAComponent.propTypes = {
	location: object.isRequired,
	history: object.isRequired,
	showLoader: func.isRequired,
	challengeName: string,
	isLoading: bool,
	isStepup: bool,
	markAsVerified: func,
	handleError: func,
	componentKey: string,
	makePendingRequest: func.isRequired,
};

export default withCancelable(withLoader(withError(withRouter(ConfirmMFAComponent))));
