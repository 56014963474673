import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { Modal } from './../../modal';
import { Notification } from './../../../components/notifications';
import { default as AddEditCustomerForm } from './add-edit-customer-form';
import { withError } from '../../error';
import { withCancelable } from '../../cancelable';
import { kvaasService, principalService } from '../../../services';
import { kvaasResources, invokeIfFunction } from '../../../utilities';
import { get } from 'lodash';
import { threeDS2ProcessTooltip } from './constants';

class AddEditCustomer extends Component {
	constructor(props) {
		super(props);
		this.formRef = createRef();
		this.notificationRef = createRef();
		this.customerFooterRef = createRef();
		this.principal = principalService.get();
		const has3DS2 = this.principal.idInfo.x3DSEnabled && this.principal.idInfo.x3DSVersion === '2';

		this.state = {
			has3DS2,
			isModalOpen: props.isModalOpen || false,
			advancedView: this.props.advancedView,
			advancedViewByDefault: props.advancedViewByDefault || false,
			hideCustomerModal: false,
			isProcessing: false,
		};
	}

	get hasIfields() {
		if (this.formRef.current && this.formRef.current.isPopup) {
			return false;
		}
		return !!document.querySelector('.newtransaction__iframe');
	}
	get permissions() {
		return get(this.principal, 'idInfo.permissions', {});
	}

	get allowCcAdjust() {
		const { existingTransaction } = this.props;
		return !get(existingTransaction, 'xCustom01') && get(this.permissions, 'allowCcAdjust', false);
	}
	componentDidMount = async () => {
		try {
			const [[userSettings]] = await this.props.makePendingRequest(
				Promise.all([kvaasService.get(kvaasResources.userSettings)]),
				'kvaas'
			);

			const newState = {
				isModalOpen: this.props.isModalOpen || this.state.isModalOpen,
				advancedViewByDefault:
					this.props.advancedViewByDefault || get(userSettings, 'data.expandNewCustomerPopup', false),
			};
			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	setIsProcessing = isProcessing => {
		this.setState({ isProcessing });
	};

	addNotification = (notification, closeModal = true) => {
		const { shouldRefreshGrid, closeLinkTransactionPopup, refreshGrid } = this.props;
		const notificationRef = this.props.notificationRef || this.notificationRef;
		if (notificationRef.current) {
			invokeIfFunction(closeLinkTransactionPopup);
			notificationRef.current.addNotification({
				...notification,
				onClose: async () => {
					if (shouldRefreshGrid) {
						refreshGrid();
					}
				},
			});
		}
		if (closeModal) {
			this.handleCloseModal();
		}
	};

	handleOpenModal = async (advancedView = false) => {
		invokeIfFunction(this.props.hideLinkTransactionPopup);
		if (this.formRef.current) {
			await this.formRef.current.reset();
		}
		this.setState({
			isModalOpen: true,
			advancedView: advancedView || this.state.advancedViewByDefault || this.props.advancedView,
		});
	};

	handleCloseModal = () => {
		invokeIfFunction(this.props.closeModal);
		this.setState({
			isModalOpen: false,
			advancedView: false,
		});
	};

	handleSave = () => {
		if (this.formRef.current) {
			this.formRef.current.handleSave();
		}
	};

	toggleAdvancedView = () => {
		if (this.state.isProcessing) return;
		this.setState({ advancedView: !this.state.advancedView }, () => {
			if (this.formRef.current) {
				this.formRef.current.scrollToTop();
			}
		});
	};

	render() {
		const {
			type,
			trigger,
			triggerProps,
			existingTransaction,
			template,
			isOpen,
			closeModal,
			isDuplicate,
			closeLinkTransactionPopup,
			hideLinkTransactionPopup,
		} = this.props;
		const { isModalOpen, advancedView, advancedViewByDefault, isProcessing, has3DS2 } = this.state;
		const title = 'Add a new customer';

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				{trigger ? trigger({ triggerProps, onClick: () => this.handleOpenModal() }) : null}
				<Modal
					isOpen={trigger ? isModalOpen : isOpen}
					onClose={
						trigger || closeLinkTransactionPopup
							? () => {
									this.handleCloseModal();
									invokeIfFunction(this.props.closeLinkTransactionPopup);
							  }
							: closeModal
					}
					shouldCloseOnOverlayClick={false}
					overlayClassName="modal__overlay"
					className={`modal__content ${advancedView ? 'modal--lrg' : ''}`}
				>
					<div className="modal__header">
						<div className="modal__header__title">{title}</div>
						{!this.allowCcAdjust && hideLinkTransactionPopup && (
							<p className="type--p3 type--color--text--light align--h--right spc--right--lrg">
								The transaction will not be linked to the new Customer
							</p>
						)}
						<button
							className="btn btn--action btn--action--secondary datatooltip--auto datatooltip--down-left"
							onClick={this.toggleAdvancedView}
							data-tooltip={advancedView ? 'Minimize View' : 'Maximize View'}
						>
							<i className={`icon icon--sml icon--${advancedView ? 'min' : 'max'}-view`}></i>
						</button>
					</div>
					<div className="modal__body" tabIndex="-1">
						<AddEditCustomerForm
							setIsProcessing={this.setIsProcessing}
							existingTransaction={existingTransaction}
							addNotification={this.addNotification}
							type={type}
							ref={this.formRef}
							template={template}
							advancedView={advancedView}
							advancedViewByDefault={advancedViewByDefault}
							toggleAdvancedView={this.toggleAdvancedView}
							customerFooterRef={this.customerFooterRef}
							isDuplicate={isDuplicate}
						/>
					</div>
					<div className="modal__footer">
						<div className="datatooltip--w--300">
							<span ref={this.customerFooterRef} />
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--med btn--primary datatooltip--top-left datatooltip--w--300"
								data-tooltip={has3DS2 ? threeDS2ProcessTooltip : null}
								onClick={this.handleSave}
								disabled={isProcessing}
							>
								Save
							</button>
						</div>
					</div>
				</Modal>
			</Fragment>
		);
	}
}

AddEditCustomer.defaultProps = {
	type: 'customers',
	shouldRefreshGrid: true,
};

AddEditCustomer.propTypes = {
	refreshGrid: PropTypes.func.isRequired,
	existingTransaction: PropTypes.object,
	type: PropTypes.oneOf(['customers', 'schedules']).isRequired,
	trigger: PropTypes.func,
	advancedView: PropTypes.bool.isRequired,
	closeModal: PropTypes.func,
	template: PropTypes.object,
	isOpen: PropTypes.bool,
	notificationRef: PropTypes.any,
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	advancedViewByDefault: PropTypes.bool,
	closeLinkTransactionPopup: PropTypes.func,
	hideLinkTransactionPopup: PropTypes.func,
	shouldRefreshGrid: PropTypes.bool,
	isDuplicate: PropTypes.func,
	isModalOpen: PropTypes.bool,
	triggerProps: PropTypes.object,
};

export default withError(withCancelable(AddEditCustomer));
