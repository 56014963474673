import React from 'react';
import { map } from 'lodash';

import { PaymentSiteActionsComponent, LinkComponent } from '../../columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';

const { paymentSiteUrl } = ApplicationSettings;

const columns = [
	{
		key: 'name',
		name: 'Name',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: false,
		fixWidth: true,
		initWidth: 150,
	},
	{
		key: 'path',
		name: 'PaymentSITE URL',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: false,
		getRowMetaData: ({ path }) => ({ value: `${paymentSiteUrl}${path}` }),
		formatter: LinkComponent,
	},
	{
		key: 'actions',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		hideOnExport: true,
		fixWidth: true,
		initWidth: 70,
		formatter: props => <PaymentSiteActionsComponent {...props} />,
		getRowMetaData: row => row,
	},
];

export const PaymentSiteColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
