import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from '../modal/modal';

const { name } = SoftwareSettings;

export default function LeaveBetaFeedbackModal({ isOpen, mid, dba, email, onClose }) {
	return (
		<ModalComponent isOpen={isOpen} onClose={onClose} className="modal__content modal--beta-feedback">
			{mid && email && dba && (
				<Fragment>
					<div className="modal__body modal__body--beta-feedback">
						<iframe
							height="1590"
							title="Embedded Wufoo Form"
							frameBorder="0"
							scrolling="no"
							style={{ width: '100%', border: 'none' }}
							src={`https://sola.wufoo.com/forms/z1kj5p61k4x3a9/?Field8=${encodeURIComponent(
								`${dba} - ${mid}`
							)}&Field9=${encodeURIComponent(email)}&Field7=${encodeURIComponent(name)}`}
						/>
					</div>
					<div className="modal__footer"></div>
				</Fragment>
			)}
		</ModalComponent>
	);
}
LeaveBetaFeedbackModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	mid: PropTypes.string,
	dba: PropTypes.string,
	email: PropTypes.string,
	onClose: PropTypes.func.isRequired,
};
