import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import {
	map,
	toLower,
	endsWith,
	each,
	findKey,
	clone,
	isEmpty,
	find,
	get,
	toNumber,
	times,
	padStart,
	includes,
} from 'lodash';
import NumberFormat from 'react-number-format';
import Select, { components } from 'react-select';
import { FullScheduleInfo } from '../../../full-schedule-info';
import { customerService } from 'common/services';

import {
	CurrencyMap,
	displayToApi,
	isAfter,
	toHebrew,
	kvaasResources,
	apiTimezone,
	afterMaxRetriesActionOptions,
	newAccountAfterMaxRetriesTooltip,
	warningMessage,
	CardTypeImagePath,
	OutsideClick,
} from './../../../../utilities';
import { calendarTypes, until, today } from './../constants';
import { HebrewDatePicker, SingleDatePicker } from 'common/components/date-picker';
import kvaasService from '../../../../services/kvaasService';
import { withCancelable } from './../../../cancelable';
import { withError } from './../../../error';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { Tour } from 'common/components/tour';
import { isOlderThanSevenMonths } from 'common/utilities/isOlderThanSevenMonths';
import CustomerSchedulePreview from './customer-schedule-preview';
import FormGroupPreview from 'common/components/form-group-preview/FormGroupPreview';
import FormGroup from './components/FormGroup';

const { displayDateFormat, apiDateFormat } = ApplicationSettings;

const intervalOptions = [
	{ name: 'day', label: 'Day(s)' },
	{ name: 'week', label: 'Week(s)' },
	{ name: 'month', label: 'Month(s)' },
	{ name: 'year', label: 'Year(s)' },
];

const mapToOption = option => ({ label: option, name: option });
const calendarTypeOptions = map(calendarTypes, mapToOption);
const untilTypeOptions = map(until, mapToOption);

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'retriesSettings',
	steps: [
		{
			selector: '#retriesSettings',
			content: 'You can now set and apply the Retries setting at the schedule level for recurring schedules.',
		},
	],
};

const disabledDaysTooltip =
	'The end date you selected is earlier than the next run date. Please select a date that is later than the next scheduled run date or disable the schedule.';

class AddEditCustomerSchedulesAddEdit extends Component {
	constructor(props) {
		super(props);
		this.hebrewDateCloserRef = createRef();
		this.endDateRef = createRef();
		this.startDateRef = createRef();
		this.selectRef = createRef();

		const {
			schedule: { startDate, endDate },
		} = props;
		this.state = {
			isPopoverOpen: false,
			templateOptions: [],
			temporaryValue: '',
			startDate: moment(clone(startDate), apiDateFormat).format(displayDateFormat),
			endDate: moment(clone(endDate), apiDateFormat).format(displayDateFormat),
			isFullScheduleInfoOpen: false,
			fullScheduleData: null,
			includeConvenience: false,
			customKeys: [],
			customerRequiredFields: {},
			customerHiddenFields: {},
			customDisplayLabels: {},
			convenienceFees: {},
			recurringCustomFields: {},
		};
	}

	get today() {
		return today[this.props.schedule.calendarCulture];
	}

	get fieldClassName() {
		return `f-col f-col-sml-12 f-col-med-12${this.props.advancedView ? ' f-col-xxlrg-6' : ''} form__group`;
	}

	componentDidMount = async () => {
		const { convenienceFees } = this.props;
		try {
			if (this.props.gridHolder) {
				this.props.gridHolder.addEventListener('scroll', this.closeDatePickers);
			}
			const [
				recurringTemplates,
				requiredFields,
				customDisplayLabels,
				customerHiddenFields,
			] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.recurringTemplates,
					kvaasResources.customerRequiredFields,
					kvaasResources.transactionDisplayLabels,
					kvaasResources.customerHiddenFields
				)
			);
			const newState = {
				customerRequiredFields: get(requiredFields, 'data', {}),
				customDisplayLabels: get(customDisplayLabels, 'data', {}),
				customerHiddenFields: get(customerHiddenFields, 'data', {}),
				customKeys: [],
				templateOptions: [],
				templates: {},
				convenienceFees,
			};
			times(19, i => {
				const oneBasedIndex = i + 1;
				const key = `recurringCustom${oneBasedIndex}`;
				const padStartKey = `custom${padStart(i + 1, 2, 0)}`;
				if (!newState.customerHiddenFields[key]) {
					newState.customKeys.push(padStartKey);
				}
			});
			newState.selectedCustomerPaymentMethod = this.getSelectedCustomerPaymentMethod();
			this.mapResponseToState(recurringTemplates, newState);
			this.setState(newState);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const {
			template,
			id,
			schedule: {
				_meta: { modified },
			},
			schedule,
		} = this.props;
		this.shouldAutoFocusAmount(prevProps);
		if (prevProps.schedule !== schedule && !!template && !modified) {
			const { convenienceFees } = this.state;
			this.props.applyTemplate(id, template, convenienceFees);
		}
		const selectedCustomerPaymentMethod = this.getSelectedCustomerPaymentMethod();

		if (
			prevProps.paymentMethods !== this.props.paymentMethods &&
			selectedCustomerPaymentMethod !== prevState.selectedCustomerPaymentMethod
		) {
			this.setState({
				selectedCustomerPaymentMethod,
			});
		}
	};

	componentWillUnmount = () => {
		if (this.props.gridHolder) {
			this.props.gridHolder.removeEventListener('scroll', this.closeDatePickers);
		}
	};

	shouldAutoFocusAmount = prevProps => {
		const { isExpanded, advancedView } = this.props;
		if (!prevProps.isExpanded && isExpanded && this.amountRef && advancedView) {
			this.amountRef.focus();
		}
	};
	getSelectedCustomerPaymentMethod = () => {
		const paymentMethodId =
			get(this.props, 'schedule.paymentMethodId') || get(this.props, 'general.defaultPaymentMethodId');
		return find(this.props.paymentMethods, paymentMethod => paymentMethod.xPaymentMethodId === paymentMethodId);
	};

	getInputClassName = (className, field) => {
		const isValid = !includes(this.props.schedule.fieldErrors, field);
		if (isValid) return className;
		return `${className} is-invalid`;
	};

	mapResponseToState = ({ data }, newState) => {
		each(data, (template, key) => {
			const parsed = JSON.parse(template);
			const label = parsed.xScheduleName;

			newState.templateOptions.push({ name: key, label });
			newState.templates[key] = parsed;
		});
		this.setState({ ...newState });
	};

	mapStateToCustomFields = () => {
		const { schedule, isViewOnly, convenienceFees, isPreview } = this.props;
		const { customerRequiredFields, customDisplayLabels, customerHiddenFields } = this.state;
		const isActive = schedule._meta.originalData.isActive === true;
		return map(this.state.customKeys, key => {
			const number = key.slice(-2);
			if (number === '01') return;
			const parsedNumber = parseFloat(number);
			let value = schedule[key] || '';
			if (toLower(convenienceFees.originalCustomKey) === `custom${number}`) return;
			if (toLower(convenienceFees.convenienceCustomKey) === `custom${number}`) return;
			if (customerHiddenFields[`recurringCustom${parsedNumber}`]) return null;

			const customFieldLabel = customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`;
			return (
				<Fragment key={key}>
					{isPreview ? (
						<FormGroupPreview key={key} label={customFieldLabel} value={value} previewField={false} />
					) : (
						<div className="form__group" key={key}>
							<div className="form__group__header">
								<label className="form__group__label">{customFieldLabel}</label>
								{this.renderRequired(`recurringCustom${parsedNumber}`, customerRequiredFields)}
							</div>
							<input
								type="text"
								name={key}
								className={this.getInputClassName('input input--med', key)}
								placeholder={customDisplayLabels[`custom${parsedNumber}`] || `Custom${number}`}
								value={value}
								onChange={this.onChange}
								disabled={!isActive || isViewOnly}
							/>
						</div>
					)}
				</Fragment>
			);
		});
	};

	closeDatePickers = () => {
		if (this.hebrewDateCloserRef.current) {
			this.hebrewDateCloserRef.current.click();
		}
		const singleDatePickerRef = document.querySelector(
			'.filter__date.filter__date--fullwidth.input--dateselect__holder .rc-menu-submenu-open .rc-menu-submenu-title'
		);
		if (singleDatePickerRef) {
			singleDatePickerRef.click();
		}
	};

	getIntervalType = name => {
		return find(intervalOptions, { name });
	};

	get currencyCode() {
		const {
			schedule: { currency },
		} = this.props;
		return CurrencyMap.resolveCurrency(currency);
	}

	toggleExpandCollapseSchedule = () => {
		const {
			schedule: {
				_meta: { id, scheduleExpanded },
			},
		} = this.props;
		this.props.onChange(id, '_meta.scheduleExpanded', !scheduleExpanded);
	};

	onChange = e => {
		const { id } = this.props;
		let { name, value, checked, type } = e.target;

		let allowChange = true;

		if (name === 'email' && this.props.onGeneralChange) {
			this.props.onGeneralChange({
				key: name,
				value: type === 'checkbox' ? checked : value,
			});
			return;
		}

		if (name === 'intervalCount' || name === 'totalPayments') {
			if (value === '0') return;
			try {
				const newVal = parseInt(value);
				if (newVal <= 0 || (newVal && isNaN(newVal))) {
					allowChange = false;
				}
			} catch (error) {
				allowChange = false;
			}
		}

		if (allowChange) {
			this.props.onChange(id, name, type === 'checkbox' ? checked : value);
		}
	};

	handleScheduleNameChange = schedule => {
		const { name, label } = schedule;
		const { id } = this.props;
		const { templates } = this.state;
		const key = findKey(templates, (_, key) => key == name);
		this.props.onChange(id, 'scheduleName', label);
		if (key) {
			const { convenienceFees } = this.state;

			this.props.applyTemplate(id, templates[key], convenienceFees);
			this.setState({ temporaryValue: label }, () => {
				const selectRef = get(this.selectRef, 'current');
				if (!selectRef) {
					return;
				}
				selectRef.blur();
				selectRef.focus();
				selectRef.blur();
			});
		}
	};

	handleSelectChange = ({ name: value }, { name }) => {
		const { id, onChange } = this.props;
		onChange(id, name, value);
	};

	handleCalendarTypeChange = ({ name: value }, { name }) => {
		const { id, onChange } = this.props;
		const today = moment().format(displayDateFormat);
		this.setState({ startDate: today });
		onChange(id, name, value);
	};

	handleNumberFormatChange = ({ value }, { target: { name } }) => {
		const { id } = this.props;
		if (value !== '') {
			value = toNumber(value);
		}

		this.props.onChange(id, name, value);
	};

	handleDateChange = (stateName, fieldName, datePickerRef, { formattedValue, value }) => {
		const { id } = this.props;
		this.setState({ [stateName]: formattedValue });
		if (value.length === 8 && moment(formattedValue, displayDateFormat, true).isValid()) {
			this.props.onChange(id, fieldName, moment(formattedValue, displayDateFormat, true).format(apiDateFormat));
			if (datePickerRef.current && datePickerRef.current.dateRef.current) {
				datePickerRef.current.dateRef.current.showMonth(moment.utc(formattedValue, displayDateFormat, true).toDate());
			}
		} else {
			this.props.onChange(id, fieldName, '');
		}
	};

	onSkipSaturdayAndHolidaysChange = () => {
		const { id, schedule } = this.props;
		this.props.onChange(id, 'skipSaturdayAndHolidays', !schedule.skipSaturdayAndHolidays);
	};
	handleUntilChange = event => {
		const { name, value } = event.target;
		this.props.onChange(this.props.id, name, value);
	};
	onCustReceiptChange = async () => {
		const { id, schedule } = this.props;
		if (schedule.custReceipt && this.props.onGeneralChange) {
			await this.props.onGeneralChange({
				key: 'email',
				value: '',
			});
		}
		this.props.onChange(id, 'custReceipt', !schedule.custReceipt);
	};

	onuseDefaultPaymentMethodOnlyChange = () => {
		const { id, schedule } = this.props;
		this.props.onChange(id, 'useDefaultPaymentMethodOnly', !schedule.useDefaultPaymentMethodOnly);
	};

	checkIfAmountAllowed = ({ value }) => {
		return value.length < 16;
	};

	handleAmountChange = ({ floatValue = 0, value }) => {
		const { id } = this.props;
		const { convenienceFees, schedule } = this.props;
		const includeConvenience = schedule.includeConvenience === undefined ? true : schedule.includeConvenience;
		const { applyToRecurringSchedule, enableConvenienceFee } = convenienceFees;
		let amountWithConvenienceFee;
		let convenienceFee;
		if (
			applyToRecurringSchedule &&
			enableConvenienceFee &&
			includeConvenience &&
			(convenienceFees.ccPercentageAmount || convenienceFees.ccFlatAmount)
		) {
			amountWithConvenienceFee = floatValue + this.props.onBeforeCalculateConvenience(floatValue, convenienceFees);
			convenienceFee = this.props.onBeforeCalculateConvenience(floatValue, convenienceFees).toFixed(4);
		}

		this.props.onChange(id, 'amount', value, amountWithConvenienceFee, convenienceFee);
		if (this.amountRef && !endsWith(value, '.')) {
			this.amountRef.blur();
			this.amountRef.focus();
		}
	};

	handleOpenPreview = () => {
		const { id } = this.props;
		this.props.handleOpenPreview(id);
	};

	handleOpenViewPayments = () => {
		const {
			schedule: { scheduleId, scheduleName },
		} = this.props;
		this.props.handleOpenViewPayments(scheduleId, scheduleName);
	};
	handleOpenFullScheduleInfo = () => {
		if (!this.state.fullScheduleData) {
			this.getFullScheduleData(true);
		} else {
			this.setState({ isFullScheduleInfoOpen: true });
		}
	};
	handleCloseFullScheduleInfo = () => {
		this.setState({ isFullScheduleInfoOpen: false });
	};

	getFullScheduleData = async showPopup => {
		const {
			schedule: { scheduleId },
		} = this.props;
		let schedule = {};
		if (scheduleId) {
			try {
				schedule = await this.props.makePendingRequest(customerService.getFullRecurringSchedule(scheduleId));
				this.setState({
					fullScheduleData: schedule,
					isFullScheduleInfoOpen: showPopup,
				});
			} catch (e) {
				if (this.props.handleError(e)) {
					this.handleCloseFullScheduleInfo();
				}
			}
		}
		return schedule;
	};

	hasCustomValues = data => {
		const { convenienceCustomKey, originalCustomKey } = this.props.convenienceFees;
		const hasValue = data[toLower(convenienceCustomKey)] || data[toLower(originalCustomKey)];
		return hasValue !== undefined;
	};
	renderOption = props => <components.Option {...props}>{this.renderPaymentMethod(props)}</components.Option>;

	renderSelected = props => (
		<components.SingleValue {...props}>{this.renderPaymentMethod(props)}</components.SingleValue>
	);
	renderPaymentMethod = ({ data }) => {
		return (
			<Fragment>
				<img alt={data.xCardType} src={CardTypeImagePath.getPath(data.xCardType)} className="spc--right--sml" />
				<span>
					{data.xTokenAlias || data.xName} {data.xMaskedNumber.slice(-4)}
					{data.xIsDefaultPaymentMethod ? ' (default)' : null}
				</span>
			</Fragment>
		);
	};
	handleCustomerPaymentMethodChange = selectedCustomerPaymentMethod => {
		const newState = { selectedCustomerPaymentMethod };
		this.setState(newState, () => {
			this.props.onChange(this.props.id, 'paymentMethodId', selectedCustomerPaymentMethod.xPaymentMethodId);
		});
	};

	renderPaymentMethodSelect = (
		customerId,
		selectedCustomerPaymentMethod,
		isActive,
		isViewOnly,
		paymentMethodOptions = this.props.paymentMethods
	) => {
		return (
			customerId && (
				<FormGroup
					type="select"
					value={selectedCustomerPaymentMethod}
					options={paymentMethodOptions}
					components={{
						Option: this.renderOption,
						SingleValue: this.renderSelected,
					}}
					onChange={this.handleCustomerPaymentMethodChange}
					isClearable={false}
					isMulti={false}
					getOptionValue={option => option.xPaymentMethodId}
					label="Payment Method"
					disabled={!isActive || isViewOnly}
				/>
			)
		);
	};

	renderRequired = (key, customerRequiredFields) => {
		if (customerRequiredFields[key]) {
			return (
				<span data-tooltip="Required" className="form__group__required">
					*
				</span>
			);
		}
		return null;
	};

	getTotalToDisplay(schedule, totalAmount, amount, originalCustomKey) {
		const scheduleCustomValue = schedule[toLower(originalCustomKey)];
		if (scheduleCustomValue && !totalAmount) {
			return scheduleCustomValue;
		} else if (totalAmount && amount > totalAmount) {
			return amount;
		} else if (totalAmount) {
			return totalAmount;
		} else {
			return 0;
		}
	}

	renderAmountField(id, schedule, required, isActive) {
		const { isViewOnly, customDisplayLabels } = this.props;
		return (
			<div className={this.fieldClassName} id="amountField">
				<div className="form__group__header">
					<label htmlFor={`${id}-amount`} className="form__group__label">
						{customDisplayLabels['amount'] || 'Amount'} {required}
					</label>
				</div>
				<NumberFormat
					className="input input--med"
					prefix={this.currencyCode}
					value={schedule.amount || ''}
					inputMode="decimal"
					onValueChange={this.handleAmountChange}
					isAllowed={this.checkIfAmountAllowed}
					name="amount"
					id={`${id}-amount`}
					placeholder={`${this.currencyCode}0`}
					thousandSeparator=","
					decimalSeparator="."
					allowNegative={false}
					decimalScale={2}
					getInputRef={el => (this.amountRef = el)}
					disabled={!isActive || isViewOnly}
					min="1"
					step="1"
				/>
			</div>
		);
	}

	renderTotalAmountField(id, amount, totalToDisplay, totalAmount, currencyCode, schedule) {
		return (
			<div className={this.fieldClassName} id="totalAmountField">
				<div className="form__group__header">
					<label htmlFor={`${id}-totalAmount`} className="form__group__label">
						Total Amount
					</label>
				</div>
				<NumberFormat
					className="input input--med"
					prefix={currencyCode}
					value={
						(schedule.includeConvenience === undefined && !totalAmount) || schedule.includeConvenience === false
							? amount
							: totalToDisplay
					}
					inputMode="decimal"
					name="Total Amount"
					id={`${id}-totalAmount`}
					placeholder={`${currencyCode}0`}
					thousandSeparator=","
					decimalSeparator="."
					allowNegative={false}
					decimalScale={2}
					getInputRef={el => (this.amountRef = el)}
					disabled={true}
				/>
			</div>
		);
	}

	renderConvenienceFeeField(id, convenienceFee, scheduleConvenienceValue, currencyCode) {
		return (
			<div className={this.fieldClassName} id="convenienceFeeField">
				<div className="form__group__header">
					<label htmlFor={`${id}-convenienceFee`} className="form__group__label">
						Electronic Transfer Fee
					</label>
					<i
						className="icon icon--nano icon--info"
						data-tooltip="This field was previously referred to as 'Convenience Fee'"
					></i>
				</div>
				<NumberFormat
					className="input input--med"
					prefix={currencyCode}
					value={convenienceFee || scheduleConvenienceValue || ''}
					inputMode="decimal"
					name="Electronic Transfer Fee"
					id={`${id}-convenienceFee`}
					placeholder={`${currencyCode}0`}
					thousandSeparator=","
					decimalSeparator="."
					allowNegative={false}
					decimalScale={2}
					getInputRef={el => (this.amountRef = el)}
					disabled={true}
				/>
			</div>
		);
	}

	renderIncludeConvenienceCheckbox(id, includeConvenience, isActive, isViewOnly, includeExcludeConvenience, schedule) {
		return (
			<div className="f-col f-col-sml-12 form__group">
				<div className="flex--primary flex--gap--sml">
					<div className="display--f">
						<input
							tabIndex="-1"
							type="checkbox"
							name="includeConvenience"
							id={`${id}includeConvenience`}
							className="input--check input--check--no-label datatooltip--convenience-fee"
							value={includeConvenience}
							checked={includeConvenience}
							onChange={() => includeExcludeConvenience(id, !includeConvenience)}
							disabled={!isActive || isViewOnly}
						/>
						<label htmlFor={`${id}includeConvenience`} className="datatooltip--no-wrap">
							Include Electronic Transfer Fee
						</label>
					</div>
					<p data-tooltip={`${schedule.includeConvenience ? 'Exclude' : 'Include'} Electronic Transfer Fee`}>
						Include Electronic Transfer Fee
					</p>
				</div>
			</div>
		);
	}
	get displayTotalAndConvenience() {
		const { convenienceFees, schedule } = this.props;
		if (!convenienceFees) return [];
		const { enableConvenienceFee, applyToRecurringSchedule, originalCustomKey, allowExclude } = convenienceFees;
		const convenienceFeeEnabled = enableConvenienceFee && applyToRecurringSchedule;
		const includeConvenience =
			(schedule.includeConvenience && convenienceFeeEnabled) ||
			(schedule[toLower(originalCustomKey)] && convenienceFeeEnabled);
		const displayTotalAndConvenienceFee = (!allowExclude && convenienceFeeEnabled) || includeConvenience;
		return displayTotalAndConvenienceFee;
	}

	renderCostFields(id, schedule, required, isActive) {
		const { amount, convenienceFee, totalAmount } = schedule;
		const { convenienceFees, isViewOnly } = this.props;
		if (!convenienceFees) return [];
		const { enableConvenienceFee, applyToRecurringSchedule, convenienceCustomKey, originalCustomKey } = convenienceFees;
		const convenienceFeeEnabled = enableConvenienceFee && applyToRecurringSchedule;
		const includeConvenience =
			(schedule.includeConvenience && convenienceFeeEnabled) ||
			(schedule[toLower(originalCustomKey)] && convenienceFeeEnabled);
		const scheduleConvenienceValue = schedule[toLower(convenienceCustomKey)];
		const displayTotalAndConvenienceFee = this.displayTotalAndConvenience;
		const totalToDisplay = this.getTotalToDisplay(schedule, totalAmount, amount, originalCustomKey);

		return (
			<Fragment>
				{this.renderAmountField(id, schedule, required, isActive)}
				{displayTotalAndConvenienceFee &&
					this.renderTotalAmountField(id, amount, totalToDisplay, totalAmount, this.currencyCode, schedule)}
				{displayTotalAndConvenienceFee &&
					this.renderConvenienceFeeField(id, convenienceFee, scheduleConvenienceValue, this.currencyCode)}
				{convenienceFeeEnabled &&
					applyToRecurringSchedule &&
					convenienceFees.allowExclude &&
					(convenienceFees.ccPercentageAmount || convenienceFees.ccFlatAmount) &&
					this.renderIncludeConvenienceCheckbox(
						id,
						includeConvenience,
						isActive,
						isViewOnly,
						this.props.includeExcludeConvenience,
						schedule
					)}
			</Fragment>
		);
	}

	renderFullScheduleInfo = () => {
		const { fullScheduleData, isFullScheduleInfoOpen } = this.state;
		if (!fullScheduleData) return null;
		return (
			<FullScheduleInfo
				isOpen={isFullScheduleInfoOpen}
				onClose={this.handleCloseFullScheduleInfo}
				className="modal__content modal--sml"
				schedule={fullScheduleData}
			/>
		);
	};

	setCustomDaySelection = (date, key) => {
		const { id } = this.props;
		const value = moment(date.date).format(apiDateFormat);
		this.props.onChange(id, key, value);
	};

	setCustomDaySelectionHebrew = (date, key) => {
		const { id } = this.props;
		this.props.onChange(id, key, date);
	};

	closePopover = () => {
		if (!this.state.isPopoverOpen) return;
		this.setState({ isPopoverOpen: false });
	};

	toggleOpenClosePopover = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
	};

	runPopoverAction = async (e, id, field) => {
		const { schedule } = this.props;
		if (field !== '_meta.saveAsTemplate' || (field === '_meta.saveAsTemplate' && schedule.scheduleName)) {
			await this.props.runPopoverAction(id, field);
		} else {
			e.preventDefault();
			e.stopPropagation();
		}
	};

	handleOpenActivateScheduleModal = async (e, id, isActive) => {
		const { handleOpenCloseModal } = this.props;
		let fullScheduleData = {};
		if (!isActive) {
			fullScheduleData = await this.getFullScheduleData();
		}
		if (isActive || (!isActive && !fullScheduleData.paymentsMissed)) {
			return this.runPopoverAction(e, id, 'isActive');
		}
		handleOpenCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Activate Recurring Schedule',
				question: (
					<p>
						Note that when reactivating this schedule, if a scheduled payment was missed,
						<br /> we will process a payment immediately. The schedule will then resume as normal.
						<br /> <span className="display--ib spc--top--sml">Are you sure you want to proceed? </span>
					</p>
				),
				onConfirm: () => this.runPopoverAction(e, id, 'isActive'),
			},
		});
	};

	handleOpenDeleteModal = e => {
		const {
			schedule,
			handleOpenCloseModal,
			permissions: { allowCcSale, allowCheckSale },
		} = this.props;
		const hasPermissionToRemoveSchedule = allowCcSale || allowCheckSale;
		if (!hasPermissionToRemoveSchedule) {
			e.preventDefault();
			e.stopPropagation();
			return;
		}
		handleOpenCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Deleting Recurring Schedule',
				question: `Deleting a recurring schedule cannot be undone. Are you sure you want to delete the schedule?`,
				onConfirm: () => this.runPopoverAction(e, schedule._meta.id, '_meta.deleted'),
			},
		});
	};

	handleInputChange = (...event) => {
		const { id } = this.props;
		const { temporaryValue } = this.state;
		const [value, { action }] = event;

		if (action === 'input-change') {
			this.props.onChange(id, 'scheduleName', value);
			this.setState({ temporaryValue: value });
		}
		if (action === 'menu-close' && !!temporaryValue) {
			this.props.onChange(id, 'scheduleName', temporaryValue);
		}
	};

	disableAutoComplete = e => {
		if (!e.target.autocomplete) {
			//this disables the chrome autofill feature
			e.target.autocomplete = 'autocomplete';
		}
	};

	syncInputWithDate = (name, value) => {
		this.setState({ [name]: value });
	};

	checkIfAllowedValue = ({ value }) => value > 0 && value < (isOlderThanSevenMonths ? 11 : 4);

	checkIfAllowedDaysBetweenRetries = ({ value }) => {
		return (value ? value > 0 : true) && value < (isOlderThanSevenMonths ? 100 : 4);
	};
	checkIfZeroValue = ({ value }) => {
		return value ? value > 0 : true;
	};

	renderScheduleProcessingOptions = () => {
		const {
			schedule,
			schedule: { failedTransactionRetryTimes, daysBetweenRetries, afterMaxRetriesAction },
			isExpanded,
			index,
			scheduleToFocus,
			advancedView,
			isViewOnly,
		} = this.props;
		const id = schedule._meta.id;
		const hasScheduleId = !!schedule.scheduleId;
		const isActive = schedule._meta.originalData.isActive === true;
		const afterMaxRetriesActionValue = find(afterMaxRetriesActionOptions, { name: afterMaxRetriesAction });
		const createScheduleFailChecked = get(schedule, 'allowInitialTransactionToDecline', false);
		return (
			<Fragment>
				{!hasScheduleId && (
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id={`${id}-allowInitialTransactionToDecline`}
							name="allowInitialTransactionToDecline"
							className="input input--check"
							value={createScheduleFailChecked}
							checked={createScheduleFailChecked}
							onChange={this.onChange}
							disabled={!isActive || isViewOnly}
						/>
						<label htmlFor={`${id}-allowInitialTransactionToDecline`}>
							Create the schedule even if the initial payment fails
						</label>
					</div>
				)}

				<div className="notes notes--primary spc--bottom--lrg">
					<div className="icon"></div>
					<div>
						<p>Note:</p>
						<p>
							If you set up a schedule that recurs sooner than the retry settings, its transactions won't be retried.
						</p>
						<p>Total tries includes the original transaction.</p>
					</div>
				</div>

				{failedTransactionRetryTimes > 5 && <p className="type--validation spc--bottom--med">{warningMessage}</p>}
				<div id="retriesSettings" className="f-row f-row--bottom spc--bottom--lrg">
					<div className="f-col f-col-sml-6">
						<div className="form__group__header">
							<span className="form__group__label">After</span>
							{isOlderThanSevenMonths && (
								<i
									className="icon icon--tny icon--regular--info datatooltip--top-right"
									data-tooltip="Determine the number of times recurring will retry after a failed attempt, set the number of days between retries and whether the schedule should resume or deactivate if all retries fail."
								></i>
							)}
						</div>
						<div className="input--prefix">
							<NumberFormat
								className="input--prefix__main"
								id={`${id}-failedTransactionRetryTimes`}
								name="failedTransactionRetryTimes"
								value={failedTransactionRetryTimes}
								format="##"
								allowNegative={false}
								isAllowed={this.checkIfAllowedValue}
								placeholder={isOlderThanSevenMonths ? '1-10' : '1-3'}
								inputMode="numeric"
								disabled={!isActive || isViewOnly}
								onValueChange={this.handleNumberFormatChange}
							/>
							<span className="type--color--text--light">tries</span>
						</div>
					</div>
					<div className="f-col f-col-sml-6">
						<div className="input--sufix">
							<span className="type--color--text--light">with</span>
							<NumberFormat
								id={`${id}-daysBetweenRetries`}
								name="daysBetweenRetries"
								className="input--sufix__main"
								onValueChange={this.handleNumberFormatChange}
								value={daysBetweenRetries}
								format="##"
								allowNegative={false}
								isAllowed={this.checkIfAllowedDaysBetweenRetries}
								placeholder={isOlderThanSevenMonths ? '1' : '3'}
								inputMode="numeric"
								disabled={!isActive || isViewOnly}
							/>
						</div>
					</div>
				</div>

				<div className="spc--bottom--xlrg">
					<div className="form__group__header">
						<span className="form__group__label">Day(s) between Retries:</span>
						<i
							className="icon icon--tny icon--regular--info"
							data-tooltip={get(afterMaxRetriesActionValue, 'tooltip')}
						></i>
					</div>
					<div data-tooltip={!isOlderThanSevenMonths ? newAccountAfterMaxRetriesTooltip : null}>
						<Select
							className="react-select-container"
							classNamePrefix="react-select"
							menuPlacement="auto"
							name="afterMaxRetriesAction"
							id={`${id}-afterMaxRetriesAction`}
							value={afterMaxRetriesActionValue}
							options={afterMaxRetriesActionOptions}
							onChange={this.handleSelectChange}
							onFocus={this.disableAutoComplete}
							isDisabled={!isOlderThanSevenMonths || !isActive || isViewOnly}
							getOptionValue={option => option.name}
						/>
					</div>
				</div>
				{(scheduleToFocus ? scheduleToFocus === schedule.scheduleId : index === 0) &&
					(advancedView ? isExpanded : true) &&
					schedule._meta.scheduleExpanded && <Tour tourConfig={tourConfig} />}
			</Fragment>
		);
	};

	handleButtonClick = (e, callBack, ...args) => {
		callBack(e, ...args);
		this.closePopover();
	};

	renderAdvancedViewOutsideClick = ({
		scheduleToFocus,
		schedule,
		index,
		isPopoverOpen,
		hasScheduleId,
		isViewOnly,
		id,
		isActive,
		hasPermissionToRemoveSchedule,
	}) => {
		return (
			<OutsideClick className="pos--rel display--f" action={this.closePopover}>
				<button className="btn btn--link pos--rel" onClick={this.toggleOpenClosePopover}>
					<i
						className={`icon icon--sml icon--${isPopoverOpen ? 'more--primary' : 'more'} ${
							scheduleToFocus
								? scheduleToFocus === schedule.scheduleId
									? 'viewPaymentsWidget'
									: ''
								: index === 0
								? 'viewPaymentsWidget'
								: ''
						}`}
					></i>
				</button>
				{isPopoverOpen && (
					<div className={`popover popover--down popover--down-right`}>
						<ul className="popover__list">
							{hasScheduleId && !isViewOnly && (
								<li className="item">
									<button
										className="btn btn--link btn--link--tertiary"
										onClick={e => this.handleButtonClick(e, this.handleOpenActivateScheduleModal, id, isActive)}
									>
										<i className={`icon icon--lrg icon--${isActive ? 'close--error' : 'request--success'}`}></i>
										{`${isActive ? 'Deactivate' : 'Activate'} Schedule`}
									</button>
								</li>
							)}
							{!isViewOnly && (
								<li className="item">
									<button
										data-tooltip={
											schedule.scheduleName ? null : 'Schedule Name field is required in order to save as a template'
										}
										className="btn btn--link btn--link--tertiary datatooltip--w--230 datatooltip--down"
										onClick={e => this.handleButtonClick(e, this.runPopoverAction, id, '_meta.saveAsTemplate')}
									>
										<i className="icon icon--lrg icon--save--light"></i>
										<span>Save As Template</span>
									</button>
								</li>
							)}
							{hasScheduleId && (
								<Fragment>
									<li className="item">
										<button
											className="btn btn--link btn--link--tertiary"
											onClick={e => this.handleButtonClick(e, this.handleOpenPreview)}
										>
											<i className="icon icon--lrg icon--view--light"></i>
											<span>Preview Schedule</span>
										</button>
									</li>
									<li className="item">
										<button
											className="btn btn--link btn--link--tertiary"
											onClick={e => this.handleButtonClick(e, this.handleOpenViewPayments)}
										>
											<i className="icon icon--lrg icon--refund--light"></i>
											<span>View Payments</span>
										</button>
									</li>
									<li className="item">
										<button
											className="btn btn--link btn--link--tertiary"
											onClick={e => this.handleButtonClick(e, this.handleOpenFullScheduleInfo)}
										>
											<i className="icon icon--lrg icon--layout"></i>
											<span>Full Schedule Information</span>
										</button>
									</li>
								</Fragment>
							)}
							{!isViewOnly && (
								<li className="item">
									<button
										className="btn btn--link btn--link--error"
										onClick={e => this.handleButtonClick(e, this.handleOpenDeleteModal, id, '_meta.deleted')}
										data-tooltip={!hasPermissionToRemoveSchedule ? 'Permission required' : null}
									>
										<i className="icon icon--lrg icon--delete--error"></i>
										<span>Remove Schedule</span>
									</button>
								</li>
							)}
						</ul>
					</div>
				)}
			</OutsideClick>
		);
	};
	renderAdvancedView = ({ advancedView, schedule, hasScheduleId, scheduleTitle }) => {
		{
			return (
				(advancedView && (
					<Fragment>
						<button className="info-panel__heading__wrapper is-expandable" onClick={this.toggleExpandCollapseSchedule}>
							<div className="flex--primary flex--gap--med">
								{this.renderPopover()}
								<h6 className="info-panel__heading">{scheduleTitle}</h6>
								<div className={`badge badge--${schedule.isActive ? 'success' : 'default'} flex--no-shrink`}>
									{schedule.isActive ? 'Active' : 'Inactive'}
								</div>
								{hasScheduleId && schedule.scheduleName !== schedule.scheduleId && (
									<button onClick={e => e.stopPropagation()} className="badge badge--default">
										{schedule.scheduleId}
									</button>
								)}
							</div>
							<div className="flex--primary flex--gap--sml">
								<i
									className={`icon icon--sml icon--chevron--${
										schedule._meta.scheduleExpanded ? 'down' : 'top'
									}--primary`}
								></i>
							</div>
						</button>
					</Fragment>
				)) ||
				null
			);
		}
	};

	renderScheduleEndDate = ({
		id,
		required,
		schedule,
		advancedView,
		isViewOnly,
		isEndDateDisabled,
		endDate,
		endMoment,
		earliestExpiryDate,
	}) => {
		return (
			<div className="form__group">
				<div className="form__group__header">
					<label htmlFor={`${id}-endDate`} className="form__group__label">
						Date
					</label>
					<div className="form__group__required">{required}</div>
				</div>
				<div className="filter__date filter__date--input filter__date--input--med">
					{schedule.calendarCulture === calendarTypes.GREGORIAN || !advancedView ? (
						<Menu mode={'horizontal'} openAnimation={'slide-up'} triggerSubMenuAction={'click'} disabled={isViewOnly}>
							<SubMenu
								popupClassName="rc-menu-datepicker-tooltip"
								className="customer__datepicker"
								disabled={isEndDateDisabled}
								title={
									<NumberFormat
										value={endDate || '-'}
										format="##/##/####"
										className="input"
										placeholder={displayDateFormat}
										mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
										onValueChange={e => this.handleDateChange('endDate', 'endDate', this.endDateRef, e)}
										disabled={isEndDateDisabled}
									/>
								}
								mode={'vertical-right'}
								key={'custom'}
							>
								<MenuItem disabled>
									<SingleDatePicker
										isFilter={false}
										date={endMoment}
										onChange={date => this.setCustomDaySelection(date, 'endDate')}
										disabledDays={[earliestExpiryDate, { before: earliestExpiryDate }]}
										type="endDate"
										syncInputWithDate={this.syncInputWithDate}
										ref={this.endDateRef}
										customerError={
											endDate !== 'Invalid date' && !moment(endDate, displayDateFormat).isValid() ? 'Invalid date' : ''
										}
									/>
								</MenuItem>
							</SubMenu>
						</Menu>
					) : (
						<HebrewDatePicker
							disabledDaysTooltip={disabledDaysTooltip}
							disabled={isEndDateDisabled || isViewOnly}
							date={schedule.endDate}
							dependentValues={[schedule.startDate]}
							onChange={date => this.setCustomDaySelectionHebrew(date, 'endDate')}
							filter={date =>
								isAfter(
									displayToApi(toHebrew(date)),
									schedule.nextScheduledRunTime ? displayToApi(toHebrew(earliestExpiryDate)) : schedule.startDate
								) && moment(date).isAfter(today[calendarTypes.GREGORIAN], 'day')
							}
						/>
					)}
				</div>
			</div>
		);
	};

	renderPopover = () => {
		const {
			schedule,
			scheduleToFocus,
			index,
			isViewOnly,
			general,
			permissions: { allowCcSale, allowCheckSale },
		} = this.props;

		const id = schedule._meta.id;
		const hasScheduleId = !!schedule.scheduleId;
		const isPopoverOpen = this.state.isPopoverOpen;

		const isActive = schedule._meta.originalData.isActive === true;
		const hasPermissionToRemoveSchedule = allowCcSale || allowCheckSale;

		return this.renderAdvancedViewOutsideClick({
			scheduleToFocus,
			schedule,
			index,
			isPopoverOpen,
			hasScheduleId,
			isViewOnly,
			id,
			isActive,
			hasPermissionToRemoveSchedule,
			general,
		});
	};

	renderAdvancedViewSection = () => {
		const {
			schedule,
			advancedView,
			scheduleToFocus,
			index,
			isViewOnly,
			permissions: { allowCcSale, allowCheckSale },
		} = this.props;

		const id = schedule._meta.id;
		const hasScheduleId = !!schedule.scheduleId;
		const scheduleTitle = schedule.scheduleName || schedule.scheduleId || 'New schedule';
		const displayDailyScheduleWarningMessage =
			schedule.intervalType === 'day' && !schedule.scheduleId && !isOlderThanSevenMonths;
		const isActive = schedule._meta.originalData.isActive === true;
		const hasPermissionToRemoveSchedule = allowCcSale || allowCheckSale;
		const isPopoverOpen = this.state.isPopoverOpen;
		return (
			<div>
				{!advancedView && (
					<div className="notes notes--primary">
						<i className="icon"></i>
						<div>
							<p>NOTE:</p>
							<p>By entering an amount you are creating a new recurring schedule.</p>
						</div>
					</div>
				)}
				{this.renderAdvancedView({
					id,
					advancedView,
					schedule,
					hasScheduleId,
					isActive,
					scheduleTitle,
					scheduleToFocus,
					index,
					isPopoverOpen,
					isViewOnly,
					hasPermissionToRemoveSchedule,
				})}
				{map(schedule._meta.errorMessages, (errorMessage, index) => (
					<p key={index} className="type--validation spc--top--med">
						{errorMessage}
					</p>
				))}
				{displayDailyScheduleWarningMessage && (
					<p className="type--validation spc--bottom--med">
						Daily recurring scheduling is not recommended for new accounts as it may result in risk issues. By selecting
						the daily recurring option, you are acknowledging and consenting to higher risk for your account.
					</p>
				)}
			</div>
		);
	};
	render = () => {
		const {
			schedule,
			advancedView,
			permissions: { allowCcSale, allowCheckSale },
			hideSkipSabbath,
			general,
			isLoading,
			customDisplayLabels,
			isViewOnly,
			isPreview,
			scheduleToFocus,
			index,
		} = this.props;
		const {
			templateOptions,
			startDate,
			endDate,
			temporaryValue,
			customerRequiredFields,
			customerHiddenFields,
			selectedCustomerPaymentMethod,
			paymentMethodOptions,
			isPopoverOpen,
		} = this.state;
		const hasPermissionToRemoveSchedule = allowCcSale || allowCheckSale;
		const id = schedule._meta.id;

		if (schedule._meta.isLoading || isLoading) {
			return (
				<div className="loader__holder">
					<div className="loader__spinner"></div>
				</div>
			);
		} else {
			const startMoment = schedule.startDate
				? moment(schedule.startDate, apiDateFormat)
				: moment(
						moment()
							.tz(apiTimezone)
							.startOf('day')
							.format(apiDateFormat),
						apiDateFormat
				  );
			const endMoment = schedule.endDate ? moment(schedule.endDate, apiDateFormat) : startMoment.clone();
			const nextScheduledRunTimeMoment = schedule.nextScheduledRunTime
				? moment(schedule.nextScheduledRunTime)
				: startMoment;
			const earliestExpiryDate = moment
				.max(
					nextScheduledRunTimeMoment,
					moment(
						moment()
							.tz(apiTimezone)
							.startOf('day')
							.format(apiDateFormat),
						apiDateFormat
					)
				)
				.toDate();
			const isActive = schedule._meta.originalData.isActive === true;
			const hasScheduleId = !!schedule.scheduleId;
			const isStartDateDisabled =
				schedule.startDate === schedule._meta.originalData.startDate &&
				hasScheduleId &&
				!isAfter(schedule.startDate, this.today);
			const isEndDateDisabled =
				!hasPermissionToRemoveSchedule ||
				schedule._meta.originalData.isActive === false ||
				(!!schedule.endDate &&
					schedule.endDate === schedule._meta.originalData.endDate &&
					hasScheduleId &&
					!isAfter(schedule.endDate, this.today));
			const required = advancedView ? (
				<span data-tooltip="Required" className="form__group__required">
					*
				</span>
			) : null;
			const showSpecificDayOfWeekCheckbox = toLower(schedule.intervalType) === 'month' && !hasScheduleId;

			const scheduleNameLabel = customDisplayLabels['scheduleName'] || 'Schedule Name';
			const descriptionLabel = customDisplayLabels['description'] || 'Description';
			const invoiceLabel = customDisplayLabels['invoice'] || 'Invoice';
			const fullScheduleInformation = this.renderFullScheduleInfo();

			if (isPreview)
				return (
					<Fragment>
						{fullScheduleInformation}
						<CustomerSchedulePreview
							customFields={this.mapStateToCustomFields()}
							schedule={schedule}
							customDisplayLabels={customDisplayLabels}
							currency={this.currencyCode}
							convenienceFees={this.props.convenienceFees}
							convenienceFeeEnabled={this.displayTotalAndConvenience}
							paymentMethod={get(selectedCustomerPaymentMethod, 'xMaskedNumber', 'N/A')}
						>
							{this.renderAdvancedViewOutsideClick({
								scheduleToFocus,
								schedule,
								index,
								isPopoverOpen,
								hasScheduleId,
								isViewOnly,
								id,
								isActive,
								hasPermissionToRemoveSchedule,
							})}
						</CustomerSchedulePreview>
					</Fragment>
				);
			return (
				<div ref={this.hebrewDateCloserRef}>
					<Fragment>
						{fullScheduleInformation}
						{this.renderAdvancedViewSection()}
						{schedule._meta.scheduleExpanded && (
							<div className="info-panel__section">
								<div className="flex--tertiary flex--gap--sml spc--bottom--lrg">
									<h5>General information</h5>
								</div>
								{advancedView && !customerHiddenFields['scheduleName'] && (
									<div className="form__group">
										<div className="form__group__header">
											<label htmlFor={`${id}-scheduleName`} className="form__group__label">
												{scheduleNameLabel}
											</label>
											{this.renderRequired('scheduleName', customerRequiredFields)}
										</div>
										{hasScheduleId || isEmpty(templateOptions) ? (
											<input
												type="text"
												id={`${id}-scheduleName`}
												name="scheduleName"
												className={this.getInputClassName('input input--med', 'scheduleName')}
												placeholder={scheduleNameLabel}
												value={schedule.scheduleName || ''}
												onChange={this.onChange}
												disabled={(!isActive && hasScheduleId) || isViewOnly}
											/>
										) : (
											<Select
												isDisabled={isViewOnly}
												ref={this.selectRef}
												className={this.getInputClassName('react-select-container', 'scheduleName')}
												classNamePrefix="react-select"
												placeholder={scheduleNameLabel}
												name="scheduleName"
												id={`${id}-scheduleName`}
												options={templateOptions}
												value={mapToOption(schedule.scheduleName)}
												inputValue={temporaryValue}
												onChange={this.handleScheduleNameChange}
												onInputChange={this.handleInputChange}
												onFocus={this.disableAutoComplete}
												getOptionValue={option => option.name}
											/>
										)}
									</div>
								)}
								{advancedView && !customerHiddenFields['description'] && (
									<FormGroup
										type="text"
										id={`${id}-description`}
										name="description"
										className="input input--med"
										placeholder={descriptionLabel}
										value={schedule.description || ''}
										onChange={this.onChange}
										disabled={(!isActive && hasScheduleId) || isViewOnly}
										label={descriptionLabel}
										requiredFields={customerRequiredFields}
										formKey="description"
									/>
								)}
								<div className="f-row">{this.renderCostFields(id, schedule, required, isActive)}</div>
								{this.renderPaymentMethodSelect(
									general.customerId,
									selectedCustomerPaymentMethod,
									isActive,
									isViewOnly,
									paymentMethodOptions
								)}
								<h5 className="spc--bottom--lrg">Invoice information</h5>
								{!customerHiddenFields['invoice'] && (
									<FormGroup
										formKey="invoice"
										htmlForKey="amount"
										label={invoiceLabel}
										requiredFields={customerRequiredFields}
										type="text"
										id={`${id}-invoice`}
										name="invoice"
										placeholder={invoiceLabel}
										value={schedule.invoice || ''}
										onChange={this.onChange}
										disabled={(!isActive && hasScheduleId) || isViewOnly}
										getInputClassName={this.getInputClassName}
									/>
								)}
								<div className="form__group">
									<div className="form__group__header">
										<label htmlFor={`${id}-intervalCount`} className="form__group__label">
											Frequency
										</label>
										<i data-tooltip="Interval type" className="icon icon--nano icon--info"></i>
									</div>
									<div className="flex--primary flex--gap--sml--alt spc--bottom--med">
										<div className="input--prefix w--100">
											<label htmlFor={`${id}-intervalCount`}>Every</label>
											<input
												disabled={hasScheduleId || isViewOnly}
												type="number"
												min="1"
												step="1"
												id={`${id}-intervalCount`}
												name="intervalCount"
												className="input--prefix__main type--right"
												placeholder="1"
												value={schedule.intervalCount || ''}
												onChange={this.onChange}
												inputMode="numeric"
											/>
										</div>
										<Select
											isDisabled={hasScheduleId || isViewOnly}
											id={`${id}-intervalType`}
											name="intervalType"
											className="react-select-container flex--grow--1"
											classNamePrefix="react-select"
											value={this.getIntervalType(toLower(schedule.intervalType)) || {}}
											options={intervalOptions}
											onChange={this.handleSelectChange}
											onFocus={this.disableAutoComplete}
											getOptionValue={option => option.name}
										/>
									</div>
									{showSpecificDayOfWeekCheckbox && (
										<div className="flex--primary flex--nowrap spc--bottom--med">
											<div>
												<input
													type="checkbox"
													id={`${id}-_meta.specificDayOfWeek`}
													name="_meta.specificDayOfWeek"
													className="input--check"
													value={schedule._meta.specificDayOfWeek || false}
													checked={schedule._meta.specificDayOfWeek || false}
													onChange={this.onChange}
													disabled={isViewOnly}
												/>
												<label htmlFor={`${id}-_meta.specificDayOfWeek`}>
													Run recurring at a specific day of the week
												</label>
											</div>
											<i
												className="icon icon--tny icon--regular--info spc--left--tny datatooltip--top-left"
												data-tooltip="Run future transactions on the same day as the first transaction, e.g. first Sunday of the month."
											></i>
										</div>
									)}
									{!hideSkipSabbath && (
										<div>
											<input
												type="checkbox"
												id={`${id}-skipSaturdayAndHolidays`}
												name="skipSaturdayAndHolidays"
												className="input--check"
												value={schedule.skipSaturdayAndHolidays || false}
												checked={schedule.skipSaturdayAndHolidays || false}
												onChange={this.onSkipSaturdayAndHolidaysChange}
												disabled={(!isActive && hasScheduleId) || isViewOnly}
											/>
											<label htmlFor={`${id}-skipSaturdayAndHolidays`}>Skip Sabbath and Holidays</label>
										</div>
									)}
								</div>
								{advancedView && (
									<FormGroup
										type="select"
										disabled={hasScheduleId || isViewOnly}
										id={`${id}-calendarCulture`}
										name="calendarCulture"
										value={mapToOption(schedule.calendarCulture)}
										options={calendarTypeOptions}
										onChange={this.handleCalendarTypeChange}
										onFocus={this.disableAutoComplete}
										getOptionValue={option => option.label}
										label="Calendar Type"
									/>
								)}
								<div>
									<div className="form__group">
										<div className="form__group__header">
											<label htmlFor={`${id}-startDate`} className="form__group__label">
												Start
											</label>
											{required}
											<i
												data-tooltip="When selecting today’s date, a transaction will be processed immediately."
												className="icon icon--tny icon--regular--info datatooltip--top-right"
											></i>
										</div>
										<div className="filter__date filter__date--input filter__date--input--med">
											{schedule.calendarCulture === calendarTypes.GREGORIAN ? (
												<Menu
													mode={'horizontal'}
													openAnimation={'slide-up'}
													triggerSubMenuAction={'click'}
													disabled={isViewOnly}
												>
													<SubMenu
														className="customer__datepicker"
														disabled={isStartDateDisabled}
														mode={'vertical-right'}
														key={'custom'}
														title={
															<NumberFormat
																value={startDate || '-'}
																format="##/##/####"
																className={this.getInputClassName('input', 'startDate')}
																placeholder={displayDateFormat}
																mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
																onValueChange={e =>
																	this.handleDateChange('startDate', 'startDate', this.startDateRef, e)
																}
																disabled={isStartDateDisabled}
															/>
														}
													>
														<MenuItem disabled>
															<SingleDatePicker
																isFilter={false}
																date={startMoment}
																onChange={date => this.setCustomDaySelection(date, 'startDate')}
																disabledDays={{ before: new Date() }}
																type="startDate"
																syncInputWithDate={this.syncInputWithDate}
																ref={this.startDateRef}
																customerError={
																	startDate !== '' && !moment(startDate, displayDateFormat).isValid()
																		? 'Invalid date'
																		: ''
																}
															/>
														</MenuItem>
													</SubMenu>
												</Menu>
											) : (
												<HebrewDatePicker
													date={schedule.startDate}
													onChange={date => this.setCustomDaySelectionHebrew(date, 'startDate')}
													filter={date => moment(date).isSameOrAfter(today[calendarTypes.GREGORIAN], 'day')}
													disabled={isStartDateDisabled || isViewOnly}
												/>
											)}
										</div>
									</div>
								</div>
								<div className="form__group">
									<div className="form__group__header">
										<div className="form__group__label">END</div>
									</div>
									<div className="flex--primary flex--gap--xlrg">
										{map(untilTypeOptions, (option, index) => {
											const inputId = `${id}-_meta.until-${index}`;
											return (
												<Fragment key={index}>
													<input
														type="radio"
														disabled={isEndDateDisabled || isViewOnly}
														id={inputId}
														name="_meta.until"
														value={option.name}
														checked={schedule._meta.until === option.name}
														onChange={this.handleUntilChange}
														className="input--radio"
													/>
													<label htmlFor={inputId}>{option.label}</label>
												</Fragment>
											);
										})}
									</div>
								</div>
								{schedule._meta.until === until.ENDDATE &&
									this.renderScheduleEndDate({
										id,
										required,
										schedule,
										advancedView,
										isViewOnly,
										isEndDateDisabled,
										endDate,
										endMoment,
										earliestExpiryDate,
									})}
								{schedule._meta.until === until.PAYMENTS && (
									<FormGroup
										type="number"
										id={`${id}-totalPayments`}
										value={schedule.totalPayments}
										onChange={this.handleNumberFormatChange}
										format="###"
										placeholder="Indefinite"
										name="totalPayments"
										inputMode="numeric"
										disabled={isEndDateDisabled || isViewOnly}
										isAllowed={this.checkIfZeroValue}
									/>
								)}
								<div className="spc--bottom--med">
									<div>
										<input
											type="checkbox"
											id={`${id}-custReceipt`}
											name="custReceipt"
											className="input input--check"
											value={schedule.custReceipt || false}
											checked={schedule.custReceipt || false}
											onChange={this.onCustReceiptChange}
											disabled={(!isActive && hasScheduleId) || isViewOnly}
										/>
										<label htmlFor={`${id}-custReceipt`}>Send receipt</label>
									</div>
									{schedule.custReceipt && general && (
										<div className="spc--top--sml--alt">
											<div className="form__group__header">
												<label htmlFor={`${general.customerId}-email`} className="form__group__label">
													Email address
												</label>
											</div>
											<input
												type="text"
												id={`${general.customerId}-email`}
												name="email"
												className="input input--med"
												placeholder="Email address"
												value={general.email || ''}
												onChange={this.onChange}
												inputMode="email"
												disabled={isViewOnly}
											/>
										</div>
									)}
								</div>
								<div className="spc--bottom--med">
									<input
										type="checkbox"
										id={`${id}-useDefaultPaymentMethodOnly`}
										name="useDefaultPaymentMethodOnly"
										className="input input--check"
										value={schedule.useDefaultPaymentMethodOnly || false}
										checked={schedule.useDefaultPaymentMethodOnly || false}
										onChange={this.onuseDefaultPaymentMethodOnlyChange}
										disabled={(!isActive && hasScheduleId) || isViewOnly}
									/>
									<label htmlFor={`${id}-useDefaultPaymentMethodOnly`}>
										Retry declined recurring with default card only
									</label>
								</div>
								{this.renderScheduleProcessingOptions()}
								<h5 className="spc--bottom--lrg">Custom Fields</h5>
								{this.mapStateToCustomFields()}
							</div>
						)}
					</Fragment>
				</div>
			);
		}
	};
}

AddEditCustomerSchedulesAddEdit.propTypes = {
	id: PropTypes.string.isRequired,
	schedule: PropTypes.object.isRequired,
	general: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	paymentMethods: PropTypes.array.isRequired,
	onGeneralChange: PropTypes.func,
	gridHolder: PropTypes.object,
	advancedView: PropTypes.bool.isRequired,
	applyTemplate: PropTypes.func.isRequired,
	template: PropTypes.object,
	handleOpenPreview: PropTypes.func,
	handleOpenViewPayments: PropTypes.func,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	isExpanded: PropTypes.bool,
	isViewOnly: PropTypes.bool,
	permissions: PropTypes.object.isRequired,
	hideSkipSabbath: PropTypes.bool,
	runPopoverAction: PropTypes.func.isRequired,
	handleOpenCloseModal: PropTypes.func.isRequired,
	index: PropTypes.number,
	scheduleToFocus: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	convenienceFees: PropTypes.object,
	onBeforeCalculateConvenience: PropTypes.func.isRequired,
	includeExcludeConvenience: PropTypes.func.isRequired,
	customDisplayLabels: PropTypes.any,
	data: PropTypes.any,
	isPreview: PropTypes.bool,
};

export default withError(withCancelable(AddEditCustomerSchedulesAddEdit));
