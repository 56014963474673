import React from 'react';
import PropTypes from 'prop-types';
const ApplyForAch = ({ advancedView }) => {
	return (
		<div className="info-panel__section">
			<div className="type--center">
				<i className="icon icon--xlrg icon--ach-disabled" />
				<div className="type--p2 type--color--text--light spc--bottom--sml--alt">
					Allow your customers to pay directly to their bank account without credit card
				</div>
				<a
					className={`btn btn--med btn--secondary${advancedView ? '' : ' fullwidth'}`}
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.cardknox.com/go"
				>
					Click here to apply for ACH
				</a>
			</div>
		</div>
	);
};
ApplyForAch.propTypes = {
	advancedView: PropTypes.bool.isRequired,
};

export default ApplyForAch;
