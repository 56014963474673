import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	map,
	split,
	cloneDeep,
	some,
	each,
	toLower,
	find,
	findIndex,
	padStart,
	times,
	isEmpty,
	concat,
	filter,
	slice,
	includes,
	join,
	findKey,
	get,
	groupBy,
	flatten,
	trim,
	startsWith,
	isUndefined,
} from 'lodash';
import NumberFormat from 'react-number-format';

import { kvaasService, principalService } from '../../../Common/services';
import { Notification } from '../../../Common/components/notifications';
import { withLoader } from '../../../Common/components/loader';
import { withCancelable } from '../../../Common/components/cancelable';
import { withBlock } from '../../../Common/components/block';
import { withError } from '../../../Common/components/error';
import {
	kvaasResources,
	mapConvenienceToCustom,
	CurrencyMap,
	checkIfCanadian,
	hasFeaturePackage,
} from '../../../Common/utilities';
import { Select } from '../../../Common/components/select';
import { convenienceFeeTypes } from '../../../components/new-transaction/constants';
import sectionKeys from '../../../routing/sections';
import { Modal } from '../../../Common/components/modal';
import handleInvalidRevision from '../../user-settings/utils/invalidRevision';
import { FieldManagementHeader } from '../components';
import { featurePackages } from 'common/utilities/has-feature-package';
import FooterComponent from 'components/settings/components/FooterComponent';

const requestKeys = {
	KVAAS: 'kvaas',
	SAVE: 'save',
	FETCH: 'fetch',
};

class AccountManagementTransactionSettings extends Component {
	constructor(props) {
		super(props);
		this.refTop = createRef();
		this.notificationRef = createRef();

		const principal = principalService.get();
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};

		if (principal) {
			this.setPermissions(principal);
		}

		this.state = {
			expandedSections: {
				fieldConfig: false,
				convenience: false,
				sales: false,
				receipt: false,
			},
			isSaving: false,
			expanded: false,
			expandAll: false,
			errorMessages: [],
			invalidFields: [],
			oldData: {
				required: null,
				customLabel: null,
				hide: null,
				flags: null,
				convenience: null,
				salesTax: null,
			},
			portalFlags: this.initialFlags,
			convenienceFees: this.initialConvenience,
			salesTax: this.initialSalesTax,
			isCvvModalOpen: false,
			wasCvvHidden: false,
			has3DS: false,
			disableCvvRequiredConfirmButton: true,
			permissions,
			showEbtfsVoucherOption: false,
			showIssuingBankInfo: false,
			expandNewTransactionPopup: true,
			ignoreAvsOption: false,
			...this.initialFields,
		};

		this.customFieldOptions = [
			{ label: '-', value: '' },
			...slice(map(this.initialFields.custom, ({ label }) => ({ label, value: label })), 2),
		];
	}

	get initialFlags() {
		return {
			sendReceipt: false,
			sendCopy: false,
			allowDuplicate: false,
			allowVerifyDisable: false,
			showIssuingBankInfo: false,
			ignoreAvsOption: false,
			displayOlderTransactionsFirst: false,
			expandNewTransactionPopup: true,
		};
	}

	get initialConvenience() {
		return {
			ccType: convenienceFeeTypes.none,
			ccPercentageAmount: 0,
			ccFlatAmount: 0,
			achType: convenienceFeeTypes.none,
			achPercentageAmount: 0,
			achFlatAmount: 0,
			convenienceCustomKey: '',
			originalCustomKey: '',
			allowExclude: false,
			enabledByDefault: false,
			enableConvenienceFee: false,
			convenienceBeforeSales: false,
			applyToRecurringSchedule: false,
		};
	}

	get initialSalesTax() {
		return {
			salesTaxPercentage: 0,
			allowExclude: false,
			allowOverride: false,
			enableSalesTax: false,
		};
	}

	get initialFields() {
		return {
			general: {
				amount: {
					required: true,
					label: 'Amount',
					customLabel: '',
					disabled: true,
				},
				cardNumber: {
					required: true,
					label: 'Card Number',
					customLabel: '',
					disabled: true,
				},
				validUntil: {
					required: true,
					label: 'Exp Date',
					customLabel: '',
					disabled: true,
				},
				cvv: {
					cvvAllTransactionsRequired: false,
					cvvNewTransactionsOnlyRequired: false,
					label: 'CVV',
					customLabel: '',
					hide: false,
				},
				email: {
					required: false,
					label: 'Email',
					customLabel: '',
					hide: false,
				},
			},
			billingShipping: {
				firstName: {
					required: false,
					label: 'First Name',
					customLabel: '',
					hide: false,
				},
				lastName: {
					required: false,
					label: 'Last Name',
					customLabel: '',
					hide: false,
				},
				company: {
					required: false,
					label: 'Company',
					customLabel: '',
					hide: false,
				},
				address: {
					required: false,
					label: 'Address',
					customLabel: '',
					hide: false,
				},
				city: {
					required: false,
					label: 'City',
					customLabel: '',
					hide: false,
				},
				state: {
					required: false,
					label: 'State',
					customLabel: '',
					hide: false,
				},
				zip: {
					required: false,
					label: 'ZIP',
					customLabel: '',
					hide: false,
				},
				country: {
					required: false,
					label: 'Country',
					customLabel: '',
					hide: false,
				},
				phoneNumber: {
					required: false,
					label: 'Phone Number',
					customLabel: '',
					hide: false,
				},
			},
			avsInfo: {
				avsZip: {
					required: false,
					label: 'AVS ZIP',
					customLabel: '',
					hide: false,
				},
				avsStreet: {
					required: false,
					label: 'AVS Street',
					customLabel: '',
					hide: false,
				},
			},
			transactionDetails: {
				invoice: {
					required: false,
					label: 'Invoice',
					customLabel: '',
					hide: false,
				},
				poNumber: {
					required: false,
					label: 'PO Number',
					customLabel: '',
					hide: false,
				},
				description: {
					required: false,
					label: 'Description',
					customLabel: '',
					hide: false,
				},
				orderId: {
					required: false,
					label: 'Order Id',
					customLabel: '',
					hide: false,
				},
			},
			custom: this.createCustomFields(3, 19),
		};
	}
	get terminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}
	getInputClassName = (value, checked, name) => {
		const {
			convenienceFees: { enableConvenienceFee },
			focusedField,
			convenienceErrors,
		} = this.state;

		let className = 'input input--med';
		if (
			enableConvenienceFee &&
			(!value || parseFloat(value) <= 0) &&
			checked &&
			name !== focusedField &&
			!isEmpty(convenienceErrors)
		) {
			return join(concat(className, 'is-invalid'), ' ');
		}
		return className;
	};
	getSalesTaxClassName = () => {
		const {
			salesTaxErrors,
			salesTax: { enableSalesTax },
		} = this.state;
		let className = 'input input--med';
		if (!isEmpty(salesTaxErrors) && enableSalesTax) {
			return join(concat(className, 'is-invalid'), ' ');
		}
		return className;
	};

	getCustomField = value => find(this.customFieldOptions, { value });

	currencyCode = CurrencyMap.resolveCurrency();

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	createCustomFields = (numberOfFields, maxCustomerFields) => {
		const custom = {};
		times(maxCustomerFields, i => {
			const oneBasedIndex = i + 1;
			const key = `custom${oneBasedIndex}`;
			const field = {
				required: false,
				label: `Custom${padStart(i + 1, 2, 0)}`,
				customLabel: '',
				hide: false,
				canDelete: oneBasedIndex > numberOfFields,
			};
			custom[key] = field;
		});
		return custom;
	};

	componentDidMount = async () => {
		this.props.showLoader(true);
		try {
			const getKvaas = kvaasService.get(
				{ ...kvaasResources.transactionRequiredFields, throwError: true },
				{ ...kvaasResources.transactionDisplayLabels, throwError: true },
				{ ...kvaasResources.transactionHiddenFields, throwError: true },
				{ ...kvaasResources.portalFlags, throwError: true },
				{ ...kvaasResources.convenienceFees, throwError: true },
				{ ...kvaasResources.salesTax, throwError: true }
			);

			const [
				requiredFields,
				customDisplayLabels,
				transactionHiddenFields,
				flags,
				convenience,
				salesTax,
			] = await this.props.makePendingRequest(getKvaas, requestKeys.FETCH);
			this.props.showLoader(false);
			const newState = this.mapResponseToState(
				requiredFields,
				customDisplayLabels,
				transactionHiddenFields,
				flags,
				convenience,
				salesTax
			);
			const isCanadian = checkIfCanadian();
			const expandNewTransactionPopup = get(flags, 'data.expandNewTransactionPopup');
			if (isUndefined(expandNewTransactionPopup)) {
				flags.data.expandNewTransactionPopup = false;
			}
			newState.showEbtfsVoucherOption = !!flags.data.showEbtfsVoucherOption;
			newState.showIssuingBankInfo = !!flags.data.showIssuingBankInfo;
			newState.ignoreAvsOption = !!flags.data.ignoreAvsOption;
			newState.expandNewTransactionPopup = !!flags.data.expandNewTransactionPopup;
			newState.portalFlags = flags.data;

			if (isCanadian) {
				newState.billingShipping.zip.label = 'Postal Code';
				newState.billingShipping.state.label = 'Province';
			}
			if (this.terminalOnly) {
				newState.expandedSections = {
					...this.state.expandedSections,
					fieldConfig: true,
				};
			}
			this.handleNewState(newState);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
	};
	handleNewState = newState =>
		this.setState(newState, () => {
			if (!isEmpty(newState.errorMessages)) {
				this.scrollToTop();
			}
		});
	displayErrors = errors => {
		return map(errors, (error, index) => (
			<p className="type--validation spc--bottom--med" key={index}>
				{error}
			</p>
		));
	};

	setErrors = type => {
		if (type === 'tax') {
			return this.validateTax();
		}
		this.setState({ convenienceErrors: this.validateConvenience() });
	};
	handleFieldFocus = ({ target: { name } }, type) => {
		this.setState(
			{
				focusedField: name,
			},
			() => this.setErrors(type)
		);
	};
	handleFieldBlur = type => {
		this.setState(
			{
				focusedField: '',
			},
			() => this.setErrors(type)
		);
	};
	validateConvenience = (convenienceFees = this.state.convenienceFees) => {
		const convenienceErrors = [];
		const {
			ccType,
			achType,
			ccPercentageAmount,
			achPercentageAmount,
			ccFlatAmount,
			achFlatAmount,
			convenienceCustomKey,
			originalCustomKey,
			enableConvenienceFee,
		} = convenienceFees;
		if (!enableConvenienceFee) return;
		const floatCCType = parseFloat(ccType);
		const floatAchType = parseFloat(achType);
		if ((floatCCType === 1 && !parseFloat(ccPercentageAmount)) || (floatCCType === 2 && !parseFloat(ccFlatAmount))) {
			if (!startsWith(toLower(this.state.focusedField), 'cc')) {
				convenienceErrors.push('CC must be greater than 0');
			}
		}
		if (
			(floatAchType === 1 && !parseFloat(achPercentageAmount)) ||
			(floatAchType === 2 && !parseFloat(achFlatAmount))
		) {
			if (!startsWith(toLower(this.state.focusedField), 'ac')) {
				convenienceErrors.push('ACH must be greater than 0');
			}
		}
		if (!convenienceCustomKey) {
			convenienceErrors.push('Convenience custom field is required');
		}

		if (!originalCustomKey) {
			convenienceErrors.push('Original amount custom field is required');
		}
		return convenienceErrors;
	};

	setPermissions = principal => {
		this.isAdmin = !!principal.hasAccess[sectionKeys.users];
		this.hasPermissions = !!principal.hasAccess[sectionKeys.newTransaction];
		this.hasGoPlus = !!principal.hasAccess[sectionKeys.goPlus];
		this.has3DS = !!principal.idInfo.x3DSEnabled && principal.idInfo.x3DSVersion !== '2';
		this.hasAch = !!principal.idInfo.xACHEnabled;
	};

	mapData = (newState, oldData, type, data) => {
		each(data, (value, key) => {
			const section = find(newState, item => item && item[key]);
			const fieldIndex = findIndex(newState.fields, ({ fieldKey }) => fieldKey === key);
			if (fieldIndex > -1) {
				if (type === 'required' && oldData[key] === undefined) {
					newState.fields[fieldIndex][type] = false;
				}
				newState.fields[fieldIndex][type] = value;
			}

			if (type === 'flags') {
				newState.portalFlags[key] = !!value;
			} else if (type === 'convenience') {
				newState.convenienceFees[key] = value;
			} else if (type === 'salesTax') {
				newState.salesTax[key] = value;
			} else if (section) {
				section[key][type] = value;
			} else if (
				type === 'required' &&
				(key === 'cvvAllTransactionsRequired' || key === 'cvvNewTransactionsOnlyRequired')
			) {
				newState.general.cvv[key] = value;
			}
		});
	};

	isInvalidRevision = error =>
		toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0';

	onBeforeHandleError = (refNum, error) => {
		this.props.handleError({
			isApiError: true,
			ref: refNum,
			message: error,
			success: false,
		});
	};
	isValidData = (data, result, error) => data && (toLower(result) === 's' || error === 'Item does not exist');
	mapFieldsToState = (newState, oldData, type) => {
		const { data, result, error, refNum } = oldData;
		if (this.isValidData(data, result, error)) {
			if (!error) {
				newState.oldData[type] = {
					...oldData,
				};
			}

			this.mapData(newState, oldData, type, data);
		} else if (this.isInvalidRevision(error)) {
			this.onBeforeHandleError(refNum, error);
		} else {
			const refString = ` (Ref# ${refNum})`;
			newState.errorMessages.push(`${error}${refNum ? refString : ''}`);
		}
	};

	mapResponseToState = (
		oldFields = this.state.oldData.required,
		oldDisplayLabels = this.state.oldData.customLabel,
		oldHide = this.state.hide,
		oldFlags = this.state.oldData.flags,
		oldConvenience = this.state.oldData.convenience,
		oldSalesTax = this.state.oldData.salesTax
	) => {
		const {
			general,
			billingShipping,
			transactionDetails,
			avsInfo,
			custom,
			oldData,
			portalFlags,
			convenienceFees,
			salesTax,
		} = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			general,
			avsInfo,
			billingShipping,
			transactionDetails,
			custom,
			portalFlags,
			convenienceFees,
			salesTax,
		};
		this.mapFieldsToState(newState, oldFields, 'required');
		this.mapFieldsToState(newState, oldHide, 'hide');
		this.mapFieldsToState(newState, oldDisplayLabels, 'customLabel');
		this.mapFieldsToState(newState, oldFlags, 'flags');
		this.mapFieldsToState(newState, oldConvenience, 'convenience');
		this.mapFieldsToState(newState, oldSalesTax, 'salesTax');
		const { parsedConvenienceKey, parsedOriginalKey } = mapConvenienceToCustom(
			oldConvenience,
			oldHide,
			oldDisplayLabels
		);
		newState.parsedConvenienceKey = parsedConvenienceKey;
		newState.parsedOriginalKey = parsedOriginalKey;

		this.displayConvenienceCustomFields(newState, parsedOriginalKey, parsedConvenienceKey);

		return newState;
	};

	displayConvenienceCustomFields = (newState, parsedOriginalKey, parsedConvenienceKey) => {
		const originalKey = findKey(newState.custom, (_, key) => key === parsedOriginalKey);
		const convenienceKey = findKey(newState.custom, (_, key) => key === parsedConvenienceKey);
		if (newState.custom[originalKey]) {
			newState.custom[originalKey].hide = false;
		}
		if (newState.custom[convenienceKey]) {
			newState.custom[convenienceKey].hide = false;
		}
	};
	setToDefaultHiddenFields = (key, fieldKey, data, defaultData) => {
		if (key === 'hide') {
			data[fieldKey] = defaultData[fieldKey] ? defaultData[fieldKey] : false;
		} else {
			if (key === 'required' && fieldKey === 'cvv') {
				data.cvvAllTransactionsRequired = false;
				data.cvvNewTransactionsOnlyRequired = false;
			} else {
				data[fieldKey] = false;
			}
		}
	};

	setToDefault = (requiredFields, fieldKey, defaultData, data, key, newState, sectionKey) => {
		const notRequired = !some(requiredFields, required => required === fieldKey);
		if (notRequired) {
			this.setToDefaultHiddenFields(key, fieldKey, data, defaultData);
		}
		if (key === 'customLabel') {
			data[fieldKey] = '';
		}
		if (key === 'customLabel' || notRequired) {
			if (key === 'required' && fieldKey === 'cvv') {
				newState[sectionKey][fieldKey].cvvAllTransactionsRequired = false;
				newState[sectionKey][fieldKey].cvvNewTransactionsOnlyRequired = false;
			} else {
				newState[sectionKey][fieldKey][key] = data[fieldKey];
			}
		}
	};

	mapStateToFields = async (key, { primaryKey, defaultData }, resetToDefault) => {
		try {
			const newState = cloneDeep(this.state);
			const requiredFields = ['amount', 'validUntil', 'cardNumber'];
			const data = {};
			const sections = {
				general: this.state.general,
				billingShipping: this.state.billingShipping,
				avsInfo: this.state.avsInfo,
				transactionDetails: this.state.transactionDetails,
				custom: this.state.custom,
			};
			each(sections, (section, sectionKey) => {
				each(section, (field, fieldKey) => {
					if (resetToDefault) {
						this.setToDefault(requiredFields, fieldKey, defaultData, data, key, newState, sectionKey);
					} else {
						if (key === 'required' && fieldKey === 'cvv') {
							data.cvvAllTransactionsRequired = !!field.cvvAllTransactionsRequired;
							data.cvvNewTransactionsOnlyRequired = !!field.cvvNewTransactionsOnlyRequired;
						} else {
							data[fieldKey] = field[key];
						}
					}
				});
			});
			await this.setStateAsync(newState);
			return {
				newData: {
					revision: 0,
					data,
				},
				oldData: this.state.oldData[key],
				primaryKey,
			};
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key, primaryKey, resetToDefault } });
		}
	};

	mapStateToRequiredFields = async setToDefaults => [
		await this.mapStateToFields('required', kvaasResources.transactionRequiredFields, setToDefaults),
		await this.mapStateToFields('customLabel', kvaasResources.transactionDisplayLabels, setToDefaults),
		await this.mapStateToFields('hide', kvaasResources.transactionHiddenFields, setToDefaults),
		{
			newData: {
				revision: 0,
				data: setToDefaults ? this.initialFlags : this.state.portalFlags,
			},
			oldData: this.state.oldData.flags,
			...kvaasResources.portalFlags,
		},
		{
			newData: {
				revision: 0,
				data: setToDefaults && this.hasPermissions ? this.initialConvenience : this.state.convenienceFees,
			},
			oldData: this.state.oldData.convenience,
			...kvaasResources.convenienceFees,
		},
		{
			newData: {
				revision: 0,
				data: setToDefaults && this.hasPermissions ? this.initialSalesTax : this.state.salesTax,
			},
			oldData: this.state.oldData.salesTax,
			...kvaasResources.salesTax,
		},
	];

	scrollToTop = () => {
		if (this.refTop.current) {
			this.refTop.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	checkIfAllowedValue = ({ value }) => {
		return value <= 100;
	};

	checkIfAllowedSalesTaxPercentage = ({ value }) => {
		return value <= 20;
	};

	handleOpenCvvModal = () => {
		const { disableCvvRequiredConfirmButton } = this.state;
		const newState = { isCvvModalOpen: true };
		const disable = this.checkIfShouldDisableConfirmButton(this.state);
		if (disableCvvRequiredConfirmButton !== disable) {
			newState.disableCvvRequiredConfirmButton = disable;
		}
		this.setState(newState);
	};

	handleCloseCvvModal = () => {
		this.setState({ isCvvModalOpen: false });
	};

	checkIfShouldDisableConfirmButton = newState => {
		const oldData = get(this.state.oldData, 'required.data', {
			cvvAllTransactionsRequired: false,
			cvvNewTransactionsOnlyRequired: false,
		});
		return (
			oldData &&
			!!oldData.cvvAllTransactionsRequired === !!newState.general.cvv.cvvAllTransactionsRequired &&
			!!oldData.cvvNewTransactionsOnlyRequired === !!newState.general.cvv.cvvNewTransactionsOnlyRequired
		);
	};

	closeCvvModalAndDefaultValues = () => {
		const { oldData } = this.state;
		const data = get(oldData, 'required.data', {});
		const newState = cloneDeep(this.state);
		if (!newState.general.cvv.hide) {
			newState.general.cvv.cvvAllTransactionsRequired = data && !!data.cvvAllTransactionsRequired;
			newState.general.cvv.cvvNewTransactionsOnlyRequired = data && !!data.cvvNewTransactionsOnlyRequired;
		}
		newState.general.cvv.hide = this.state.wasCvvHidden;
		newState.isCvvModalOpen = false;
		this.setState(newState);
	};

	handleChange = event => {
		const { name, checked, value, type } = event.target;
		const [sectionKey, fieldKey, propKey] = split(name, '.');

		const newState = {};
		newState[sectionKey] = cloneDeep(this.state[sectionKey]);
		const newValue = type === 'checkbox' ? checked : value;
		newState[sectionKey][fieldKey][propKey] = newValue;

		if (includes(['required', 'cvvAllTransactionsRequired', 'cvvNewTransactionsOnlyRequired'], propKey)) {
			newState[sectionKey][fieldKey].hide = false;
		} else if (propKey === 'hide') {
			newState[sectionKey][fieldKey].required = false;
			if (fieldKey === 'cvv') {
				newState[sectionKey][fieldKey].cvvAllTransactionsRequired = false;
				newState[sectionKey][fieldKey].cvvNewTransactionsOnlyRequired = false;
			}
		}

		this.setState(newState, this.validateFields);
		this.props.handleBlockChange(true);
	};

	validateFields = () => {
		const invalidFields = [];
		const duplicates = this.getDuplicates();

		each(duplicates, ({ label }) => {
			invalidFields.push(label);
		});

		const hasErrors = !isEmpty(invalidFields);

		this.setState({ invalidFields });

		return hasErrors;
	};

	getDuplicates = () => {
		const { general, billingShipping, transactionDetails, avsInfo, custom } = this.state;
		const customDisplayLabelsList = [];

		this.addCustomLabelsToList(general, customDisplayLabelsList);
		this.addCustomLabelsToList(billingShipping, customDisplayLabelsList);
		this.addCustomLabelsToList(transactionDetails, customDisplayLabelsList);
		this.addCustomLabelsToList(avsInfo, customDisplayLabelsList);
		this.addCustomLabelsToList(custom, customDisplayLabelsList);

		return flatten(filter(groupBy(customDisplayLabelsList, 'customLabel'), arr => arr.length > 1));
	};

	addCustomLabelsToList = (section, list) => {
		each(section, ({ customLabel, label }) => {
			if (customLabel) {
				list.push({ customLabel: trim(customLabel), label });
			}
		});
	};

	handleCvvRequiredChange = event => {
		const { name, checked, value, type } = event.target;
		const [sectionKey, fieldKey, propKey] = split(name, '.');

		const newState = {};
		newState[sectionKey] = cloneDeep(this.state[sectionKey]);
		const newValue = type === 'checkbox' ? checked : value;
		newState[sectionKey][fieldKey][propKey] = newValue;

		if (propKey === 'cvvNewTransactionsOnlyRequired') {
			newState[sectionKey][fieldKey].cvvAllTransactionsRequired = false;
		} else {
			newState[sectionKey][fieldKey].cvvNewTransactionsOnlyRequired = false;
		}

		if (newState[sectionKey][fieldKey].hide) {
			newState.wasCvvHidden = true;
			newState[sectionKey][fieldKey].hide = false;
		} else {
			newState.wasCvvHidden = false;
		}
		const disableCvvRequiredConfirmButton = this.checkIfShouldDisableConfirmButton(newState);
		if (newState.disableCvvRequiredConfirmButton !== disableCvvRequiredConfirmButton) {
			newState.disableCvvRequiredConfirmButton = disableCvvRequiredConfirmButton;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	handlePortalFlagsChange = ({ target: { name, checked } }) => {
		this.setState({
			portalFlags: {
				...this.state.portalFlags,
				[name]: checked,
			},
		});

		this.props.handleBlockChange(true);
	};

	handleConvenienceFeesChange = async ({ target: { name, value } }) => {
		const {
			convenienceFees: { originalCustomKey, convenienceCustomKey, achType, ccType },
		} = this.state;
		const wasChecked = parseInt(achType) || parseInt(ccType);
		const convenienceFees = {
			...this.state.convenienceFees,
			[name]: value,
		};

		if (name === 'achType' || name === 'ccType') {
			if (parseInt(value) && !wasChecked) {
				convenienceFees.convenienceCustomKey = convenienceCustomKey ? convenienceCustomKey : 'Custom09';
				convenienceFees.originalCustomKey = originalCustomKey ? originalCustomKey : 'Custom10';
			} else if (!parseInt(value) && !(parseInt(achType) && parseInt(ccType))) {
				convenienceFees.convenienceCustomKey = '';
				convenienceFees.originalCustomKey = '';
			}
		}

		this.props.handleBlockChange(true);

		await this.setStateAsync({ convenienceFees });
		const convenienceErrors = this.validateConvenience();
		this.setState({ convenienceErrors });
	};

	handleSalesTaxChange = ({ target: { name, type, checked, value } }) => {
		this.setState({ salesTax: { ...this.state.salesTax, [name]: type === 'checkbox' ? checked : value } }, () => {
			this.props.handleBlockChange(true);
		});
	};

	handleSalesTaxPercentageChange = ({ value: salesTaxPercentage }) => {
		this.setState({ salesTax: { ...this.state.salesTax, salesTaxPercentage } }, () => {
			this.props.handleBlockChange(true);
		});
	};
	validateTax = () => {
		const { salesTaxPercentage, enableSalesTax } = this.state.salesTax;
		const salesTaxErrors = [];
		if (
			(!salesTaxPercentage || parseFloat(salesTaxPercentage) <= 0) &&
			enableSalesTax &&
			this.state.focusedField !== 'salesTaxPercentage'
		) {
			salesTaxErrors.push('Sales Tax percent must be greater than 0');
		}
		this.setState({ salesTaxErrors });
	};

	handleClearErrors = (newState, checked, settingName, setting) => {
		if (!checked && settingName === 'convenienceFees') {
			setting.ccType = convenienceFeeTypes.none;
			setting.achType = convenienceFeeTypes.none;
			newState.convenienceErrors = [];
		} else if (!checked) {
			setting.allowExclude = false;
			setting.allowOverride = false;
			newState.salesTaxErrors = [];
		}
	};
	handleEnableCheckbox = ({ target: { checked, name } }) => {
		const { salesTax, convenienceFees } = this.state;
		const settingName = name === 'enableSalesTax' ? 'salesTax' : 'convenienceFees';
		let setting = name === 'enableSalesTax' ? salesTax : convenienceFees;
		const newState = {};
		this.handleClearErrors(newState, checked, settingName, setting);
		newState[settingName] = {
			...setting,
			[name]: checked,
		};

		this.setState(newState, () => {
			this.props.handleBlockChange(true);
		});
	};

	handleCheckboxChange = ({ target: { name, checked: value } }) =>
		this.handleConvenienceFeesChange({ target: { name, value } });

	handleNumberChange = async ({ value }, { target }) => {
		await this.handleConvenienceFeesChange({ target: { name: target.name, value } });
	};

	handleSelectChange = ({ value }, { name }) => this.handleConvenienceFeesChange({ target: { name, value } });

	addCustomField = () => {
		const custom = cloneDeep(this.state.custom);
		const field = find(custom, item => (parseInt(item.label.substr('Custom'.length)) > 3 ? item.hide : false));
		if (field) {
			field.hide = false;
			this.setState({ custom });
		}
		this.props.handleBlockChange(true);
	};

	removeCustomField = key => {
		const custom = cloneDeep(this.state.custom);
		if (custom[key] && custom[key].canDelete) {
			custom[key].hide = true;
			custom[key].required = false;
			custom[key].customLabel = '';
			this.setState({ custom }, this.validateFields);
		}
		this.props.handleBlockChange(true);
	};

	save = async setToDefaults => {
		let refreshData = false;
		let refNum;
		let error;
		this.props.showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);
		this.validateFields();
		this.validateTax();
		const convenienceErrors = this.validateConvenience();
		const salesTaxErrors = !isEmpty(this.state.salesTaxErrors);
		const convenienceHasErrors = !isEmpty(convenienceErrors);

		if (convenienceHasErrors || salesTaxErrors) {
			this.props.showLoader(false);
			this.setState({ convenienceErrors });
			return;
		}

		try {
			const [
				requiredFields,
				customDisplayLabels,
				transactionHiddenFields,
				flags,
				convenience,
				salesTax,
			] = await this.props.makePendingRequest(kvaasService.save(...mappedState), requestKeys.SAVE);
			const newState = this.mapResponseToState(
				requiredFields,
				customDisplayLabels,
				transactionHiddenFields,
				flags,
				convenience,
				salesTax
			);
			refNum = requiredFields.refNum;

			if (setToDefaults && this.hasPermissions) {
				newState.portalFlags = this.initialFlags;
				newState.convenienceFees = this.initialConvenience;
				newState.salesTax = this.initialSalesTax;
			}
			this.props.showLoader(false);
			this.handleNewState(newState);
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const [
					requiredFields,
					customDisplayLabels,
					transactionHiddenFields,
					flags,
					convenience,
					salesTax,
				] = await this.props.makePendingRequest(
					kvaasService.get(
						kvaasResources.transactionRequiredFields,
						kvaasResources.transactionDisplayLabels,
						kvaasResources.transactionHiddenFields,
						kvaasResources.portalFlags,
						kvaasResources.convenienceFees,
						kvaasResources.salesTax
					),
					requestKeys.KVAAS
				);
				const newState = this.mapResponseToState(
					requiredFields,
					customDisplayLabels,
					transactionHiddenFields,
					flags,
					convenience,
					salesTax
				);

				this.setState(newState, () => {
					this.shouldScrollTop(newState.errorMessages);
					this.validateFields();
				});
				this.props.showLoader(false);
				this.props.handleBlockChange(false);
			} catch (e) {
				const handledError = this.props.handleError(e, { delayMessage: true });
				error = handledError;
			}
			this.props.showLoader(false);
			this.shouldScrollTop(this.state.errorMessages, error);
		}
		if (!error) {
			this.notificationRef.current.addNotification({
				message: setToDefaults ? 'New Transaction settings reset to default' : 'New Transaction settings updated',
				ref: refNum,
				success: true,
			});
		}
	};

	shouldScrollTop = (errorMessages, error) => {
		if (!isEmpty(errorMessages)) {
			return this.scrollToTop();
		} else if (error) {
			error.show();
		}
	};

	shouldDisableSaveButton = () => {
		const { isLoading } = this.props;
		const { invalidFields, errorMessages, convenienceErrors, salesTaxErrors } = this.state;
		return (
			isLoading ||
			!isEmpty(errorMessages) ||
			!isEmpty(invalidFields) ||
			!isEmpty(convenienceErrors) ||
			!isEmpty(salesTaxErrors)
		);
	};

	toggleCollapseExpandSection = section => () => {
		this.setState({
			expandedSections: { ...this.state.expandedSections, [section]: !this.state.expandedSections[section] },
		});
	};

	getClassName = item => {
		if (some(this.state.invalidFields, field => field === item.label)) {
			return 'input input--sml is-invalid';
		}
		return 'input input--sml';
	};
	getValue = (key, item) => {
		if (key === this.state.parsedConvenienceKey) {
			return 'Electronic Transfer Fee';
		} else if (key === this.state.parsedOriginalKey) {
			return 'Original Amount';
		}
		return item.customLabel;
	};

	getChecked = item => {
		if (item.label === 'CVV') {
			return item.cvvAllTransactionsRequired || item.cvvNewTransactionsOnlyRequired;
		}
		return item.required;
	};

	getEventHandler = item => {
		if (item.label === 'CVV') {
			return this.handleOpenCvvModal;
		}
		return this.handleChange;
	};

	renderDisabledOrConvenience = (item, isConvenience) => {
		const className = `icon icon--sml icon--${item.hide && !item.required ? 'hide' : 'view'}`;
		if (item.disabled || isConvenience) {
			return (
				<div className="display--if datatooltip--auto" data-tooltip="Disabled">
					<i className="icon icon--sml icon--hide datatooltip--auto"></i>
				</div>
			);
		}
		return (
			<div
				className="display--if datatooltip--auto datatooltip--no-wrap"
				data-tooltip={item.hide ? 'Show field' : 'Hide field'}
			>
				<i className={className}></i>
			</div>
		);
	};

	renderRemoveField = (item, isConvenience, key, hideName) => {
		if (item.canDelete && !isConvenience) {
			return (
				<button
					onClick={() => this.removeCustomField(key)}
					data-tooltip="Remove"
					className="btn btn--link datatooltip--auto datatooltip--left"
				>
					<i className="icon icon--sml icon--delete"></i>
				</button>
			);
		}
		return (
			<div>
				<input
					disabled={item.disabled || isConvenience}
					type="checkbox"
					className="input--check input--check--icon"
					checked={item.hide}
					name={hideName}
					value={hideName}
					id={hideName}
					onChange={this.handleChange}
					onClick={this.handleChange}
				/>
				<label htmlFor={hideName}>{this.renderDisabledOrConvenience(item, isConvenience)}</label>
			</div>
		);
	};

	renderField = (isConvenience, item, labelName, key) => {
		const props = {};
		if (this.state.parsedConvenienceKey === key) {
			props['data-tooltip'] = "This field was previously referred to as 'Convenience Fee'";
		}
		return (
			<td {...props}>
				<input
					disabled={isConvenience}
					type="text"
					className={this.getClassName(item)}
					placeholder={item.label}
					name={labelName}
					value={this.getValue(key, item)}
					onChange={this.handleChange}
				/>
			</td>
		);
	};
	renderSection = (sectionName, sectionKey) => (
		<React.Fragment>
			<h6 className="card__heading spc--bottom--med">{sectionName}</h6>
			<div className="table__wrapper spc--bottom--lrg">
				<table className="table table--primary table--primary--big">
					<FieldManagementHeader sectionName={sectionName} />
					<colgroup>
						<col width={120} />
						<col />
						<col />
						<col />
					</colgroup>
					<tbody>
						{map(this.state[sectionKey], (item, key) => {
							const name = `${sectionKey}.${key}`;
							const requiredName = `${name}.required`;
							const hideName = `${name}.hide`;
							const labelName = `${name}.customLabel`;
							const isConvenience = key === this.state.parsedOriginalKey || key === this.state.parsedConvenienceKey;
							return sectionKey === 'custom' && item.canDelete && item.hide && !isConvenience ? null : (
								<tr key={key}>
									<td>
										<label htmlFor={labelName} className="type--p2 type--color--text">
											{item.label}
										</label>
									</td>
									{this.renderField(isConvenience, item, labelName, key)}
									<td className="type--center">
										<input
											type="checkbox"
											className="input--check"
											disabled={item.disabled}
											checked={this.getChecked(item)}
											name={requiredName}
											value={requiredName}
											id={requiredName}
											onChange={this.getEventHandler(item)}
											onClick={this.getEventHandler(item)}
										/>
										<label htmlFor={requiredName}>
											<span className="hide--from--med">Required</span>
										</label>
									</td>
									<td className="type--center">{this.renderRemoveField(item, isConvenience, key, hideName)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{this.renderAddCustomField(sectionKey)}
		</React.Fragment>
	);
	renderAddCustomField = sectionKey => {
		const hasCapacity = some(this.state.custom, field => {
			const fieldLabel = field.label === 'Custom19' ? -2 : -1;
			return Number(field.label.substr(fieldLabel) > 3 ? field.hide : false);
		});
		if (sectionKey === 'custom' && hasCapacity) {
			return (
				<button onClick={this.addCustomField} className="btn btn--link">
					Add another
				</button>
			);
		}
	};
	renderSetupSalesTax = () => {
		if (this.terminalOnly) return null;
		const {
			expandedSections: { sales },
			salesTax: { salesTaxPercentage, allowExclude, allowOverride, enableSalesTax },
			convenienceFees: { convenienceCustomKey, originalCustomKey },
			salesTaxErrors,
		} = this.state;

		return (
			<div className={`card spc--bottom--lrg ${sales ? 'is-expanded' : ''}`}>
				<button
					className="card__header card__header--expandable"
					onClick={this.toggleCollapseExpandSection('sales')}
					onKeyDown={this.toggleCollapseExpandSection('sales')}
				>
					<h5>Sales Tax</h5>
					<i className={`icon icon--sml icon--chevron--${sales ? 'top' : 'down'}--primary`}></i>
				</button>
				{sales && (
					<div className="card__body">
						{this.displayErrors(salesTaxErrors)}
						<div className="input--check--enable-form spc--bottom--lrg">
							<input
								type="checkbox"
								id="salesTax.enableSalesTax"
								name="enableSalesTax"
								checked={enableSalesTax}
								value={enableSalesTax}
								onChange={this.handleEnableCheckbox}
								className="input--check"
							/>
							<label htmlFor="salesTax.enableSalesTax">Enable Sales Tax</label>
						</div>
						<div className="form__group">
							<div className="form__group__header">
								<label htmlFor="salesTaxPercentage" className="form__group__label">
									Tax
								</label>
								<i data-tooltip="Max allowed Tax is 20%." className="icon icon--tny icon--regular--info"></i>
							</div>
							<NumberFormat
								value={salesTaxPercentage}
								suffix="%"
								decimalScale={4}
								className={this.getSalesTaxClassName()}
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								onValueChange={this.handleSalesTaxPercentageChange}
								name="salesTaxPercentage"
								isAllowed={this.checkIfAllowedSalesTaxPercentage}
								disabled={!enableSalesTax}
								onBlur={() => this.handleFieldBlur('tax')}
								onFocus={e => this.handleFieldFocus(e, 'tax')}
							/>
						</div>
						<div className="form__group">
							<div className="form__group__header">
								<label htmlFor="salesTax.originalCustomKey" className="form__group__label">
									Custom field to save the original amount
								</label>
								<i
									data-tooltip="This field is also used by Electronic Transfer Fee."
									className="icon icon--tny icon--regular--info datatooltip--top-left"
								></i>
							</div>
							<Select
								name="originalCustomKey"
								id="salesTax.originalCustomKey"
								value={this.getCustomField(originalCustomKey)}
								options={filter(this.customFieldOptions, ({ value }) => !value || value !== convenienceCustomKey)}
								onChange={this.handleSelectChange}
								menuPlacement="auto"
								isDisabled={!enableSalesTax}
							/>
						</div>
						<div className="row">
							<div className="col col-sml-12">
								<div className="spc--bottom--med">
									<div className="spc--bottom--tny">
										<input
											type="checkbox"
											id="salesTax.allowExclude"
											name="allowExclude"
											checked={allowExclude}
											value={allowExclude}
											onChange={this.handleSalesTaxChange}
											className="input input--check"
											disabled={!enableSalesTax}
										/>
										<label htmlFor="salesTax.allowExclude">Allow Users To Include/Exclude Sales Tax</label>
									</div>
								</div>
							</div>
						</div>
						<div className="spc--bottom--sml">
							<input
								type="checkbox"
								id="allowOverride"
								name="allowOverride"
								checked={allowOverride}
								value={allowOverride}
								onChange={this.handleSalesTaxChange}
								className="input input--check"
								disabled={!enableSalesTax}
							/>
							<label htmlFor="allowOverride" className="type--color--text--medium type--wgt--medium">
								Allow Users To Override Sales Tax
							</label>
						</div>
					</div>
				)}
			</div>
		);
	};
	renderReceiptSettings = () => {
		if (this.terminalOnly) return null;
		const {
			portalFlags: { sendCopy, sendReceipt, sendSmsReceipt, allowSmsReceipt },
		} = this.state;

		return (
			<React.Fragment>
				<div className="flex--primary spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">Receipts</p>
					<i
						data-tooltip="Changes made to this section will be applied to the Mobile App as well."
						className="icon icon--tny icon--regular--info spc--left--sml"
					></i>
				</div>
				<div className="spc--bottom--med">
					<input
						type="checkbox"
						id="sendCopy"
						name="sendCopy"
						checked={sendCopy}
						value={sendCopy}
						onChange={this.handlePortalFlagsChange}
						className="input input--check"
					/>
					<label htmlFor="sendCopy">Send Me an Email Receipt by Default</label>
				</div>
				<div className="spc--bottom--med">
					<input
						type="checkbox"
						id="sendReceipt"
						name="sendReceipt"
						checked={sendReceipt}
						value={sendReceipt}
						onChange={this.handlePortalFlagsChange}
						className="input--check"
					/>
					<label htmlFor="sendReceipt">Send the Customer an Email Receipt by Default</label>
				</div>
				{this.isAdmin && (
					<div className="flex--primary spc--bottom--med">
						<div>
							<input
								type="checkbox"
								id="allowSmsReceipt"
								name="allowSmsReceipt"
								checked={allowSmsReceipt}
								value={allowSmsReceipt}
								onChange={this.handlePortalFlagsChange}
								className="input--check"
							/>
							<label htmlFor="allowSmsReceipt">Enable SMS Receipts</label>
						</div>
						<i
							data-tooltip={
								'$0.02 SMS fee will apply for every text receipt sent.\nTo send SMS receipts, Billing Cell Phone or Billing Phone Number needs to be set as a required field.'
							}
							className="icon icon--tny icon--regular--info spc--left--tny"
						></i>
					</div>
				)}
				{allowSmsReceipt && (
					<div>
						<input
							type="checkbox"
							id="sendSmsReceipt"
							name="sendSmsReceipt"
							checked={sendSmsReceipt}
							value={sendSmsReceipt}
							onChange={this.handlePortalFlagsChange}
							className="input--check"
						/>
						<label htmlFor="sendSmsReceipt">Send SMS Receipt by Default</label>
					</div>
				)}
			</React.Fragment>
		);
	};
	renderOtherSettings = () => {
		if (this.terminalOnly) return null;
		const {
			portalFlags: { allowDuplicate, allowVerifyDisable, ignoreAvsOption, expandNewTransactionPopup },
		} = this.state;
		return (
			<React.Fragment>
				{this.has3DS && (
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="allowVerifyDisable"
							name="allowVerifyDisable"
							checked={allowVerifyDisable}
							value={allowVerifyDisable}
							onChange={this.handlePortalFlagsChange}
							className="input input--check"
						/>
						<label htmlFor="allowVerifyDisable" className="type--color--text--medium type--wgt--medium">
							Allow Disabling 3DS Verification on Transactions
						</label>
					</div>
				)}
				<div className="flex--primary spc--bottom--med">
					<div>
						<input
							type="checkbox"
							id="ignoreAvsOption"
							name="ignoreAvsOption"
							checked={ignoreAvsOption}
							value={ignoreAvsOption}
							onChange={this.handlePortalFlagsChange}
							className="input--check"
						/>
						<label htmlFor="ignoreAvsOption">Show Ignore AVS Option</label>
					</div>
					<i
						className="icon icon--tny icon--regular--info spc--left--tny"
						data-tooltip="Ignore AVS overrides the AVS fraud setting defined for your Sola account. Check this box to add the Ignore AVS option to the Add New Transaction screen."
					></i>
				</div>
				<div className="spc--bottom--med">
					<input
						type="checkbox"
						id="allowDuplicate"
						name="allowDuplicate"
						checked={allowDuplicate}
						value={allowDuplicate}
						onChange={this.handlePortalFlagsChange}
						className="input--check"
					/>
					<label htmlFor="allowDuplicate">Allow User to Process Duplicate Transactions</label>
				</div>
				<div className="spc--bottom--lrg">
					<input
						type="checkbox"
						id="expandNewTransactionPopup"
						name="expandNewTransactionPopup"
						checked={expandNewTransactionPopup}
						value={expandNewTransactionPopup}
						onChange={this.handlePortalFlagsChange}
						className="input--check"
					/>
					<label htmlFor="expandNewTransactionPopup">Expand New Transaction Popup by Default</label>
				</div>
			</React.Fragment>
		);
	};
	renderElectronicTransferFee = convenience => {
		if (this.terminalOnly) return null;
		return (
			<div className="card spc--bottom--lrg">
				<button
					className={`card__header card__header--expandable ${convenience ? 'is-expanded' : ''}`}
					onClick={this.toggleCollapseExpandSection('convenience')}
				>
					<div className="flex flex--primary">
						<h5>Electronic Transfer Fee</h5>
						<i
							className="icon icon--tny icon--regular--info spc--left--sml"
							data-tooltip="This field was previously referred to as 'Convenience Fee'"
						></i>
					</div>
					<i className={`icon icon--sml icon--chevron--${convenience ? 'top' : 'down'}--primary`}></i>
				</button>
				{convenience && this.renderConvenienceFields(convenience)}
			</div>
		);
	};
	render() {
		const {
			errorMessages,
			invalidFields,
			isCvvModalOpen,
			general,
			disableCvvRequiredConfirmButton,
			expandedSections: { fieldConfig, convenience },
		} = this.state;

		const { refTop, notificationRef, renderSection } = this;

		return (
			<React.Fragment>
				<Modal
					isOpen={isCvvModalOpen}
					onClose={this.closeCvvModalAndDefaultValues}
					shouldCloseOnOverlayClick={false}
					className="modal__content"
				>
					<div className="modal--customer">
						<div className="modal__header">
							<div className="modal__header__title">CVV required:</div>
						</div>
						<div className="modal__body">
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="general.cvv.cvvAllTransactionsRequired"
									name="general.cvv.cvvAllTransactionsRequired"
									checked={general.cvv.cvvAllTransactionsRequired}
									value={general.cvv.cvvAllTransactionsRequired}
									onChange={this.handleCvvRequiredChange}
									className="input input--check"
								/>
								<label
									htmlFor="general.cvv.cvvAllTransactionsRequired"
									className="type--color--text--medium type--wgt--medium"
								>
									All transactions - both new and subsequent transaction
								</label>
							</div>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="general.cvv.cvvNewTransactionsOnlyRequired"
									name="general.cvv.cvvNewTransactionsOnlyRequired"
									checked={general.cvv.cvvNewTransactionsOnlyRequired}
									value={general.cvv.cvvNewTransactionsOnlyRequired}
									onChange={this.handleCvvRequiredChange}
									className="input input--check"
								/>
								<label
									htmlFor="general.cvv.cvvNewTransactionsOnlyRequired"
									className="type--color--text--medium type--wgt--medium"
								>
									New Transactions only
								</label>
							</div>
						</div>
						<div className="modal__footer">
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--sml btn--primary"
								onClick={this.handleCloseCvvModal}
								disabled={disableCvvRequiredConfirmButton}
							>
								Confirm
							</button>
						</div>
					</div>
				</Modal>
				<div className="settings__header">
					<h3 className="settings__title">Portal Settings</h3>
					<h5>New Transactions Settings</h5>
				</div>

				<div ref={refTop}></div>
				{this.displayErrors(errorMessages)}

				{map(invalidFields, field => (
					<p key={field} className="type--validation spc--bottom--med">
						{field} must be unique.
					</p>
				))}

				<div className={`card spc--bottom--lrg ${fieldConfig ? 'is-expanded' : ''}`}>
					<button
						className="card__header card__header--expandable"
						onClick={this.toggleCollapseExpandSection('fieldConfig')}
					>
						<h5>Manage Columns</h5>
						<i className={`icon icon--sml icon--chevron--${fieldConfig ? 'top' : 'down'}--primary`}></i>
					</button>
					{fieldConfig && (
						<div className="card__body">
							{renderSection('General', 'general')}
							{renderSection('Billing Information', 'billingShipping')}
							{renderSection('AVS Information', 'avsInfo')}
							{renderSection('Transaction Details', 'transactionDetails')}
							{renderSection('Custom Fields', 'custom')}
						</div>
					)}
				</div>
				{this.hasPermissions && (
					<Fragment>
						{this.renderOtherSettings()}
						{this.renderElectronicTransferFee(convenience)}
						{this.renderSetupSalesTax()}
						{this.renderReceiptSettings()}
					</Fragment>
				)}
				<FooterComponent save={this.save} disabled={this.shouldDisableSaveButton()} isLoading={this.props.isLoading} />
				<Notification ref={notificationRef} />
			</React.Fragment>
		);
	}
	renderConvenienceFields = () => {
		const {
			convenienceFees: {
				ccType,
				achType,
				ccPercentageAmount,
				ccFlatAmount,
				achPercentageAmount,
				achFlatAmount,
				convenienceCustomKey,
				originalCustomKey,
				allowExclude,
				enableConvenienceFee,
				convenienceBeforeSales,
				applyToRecurringSchedule,
				enabledByDefault,
			},
			convenienceErrors,
		} = this.state;

		const {
			customFieldOptions,
			getCustomField,
			handleConvenienceFeesChange,
			handleNumberChange,
			handleSelectChange,
			handleCheckboxChange,
		} = this;

		const { none, percentage, flat } = convenienceFeeTypes;
		return (
			<div className="card__body">
				{enableConvenienceFee && this.displayErrors(convenienceErrors)}
				<div className="row">
					<div className="col col-sml-12 spc--bottom--med">
						<div className="input--check--enable-form">
							<input
								type="checkbox"
								id="salesTax.enableConvenienceFee"
								name="enableConvenienceFee"
								checked={enableConvenienceFee}
								value={enableConvenienceFee}
								onChange={this.handleEnableCheckbox}
								className="input--check"
							/>
							<label htmlFor="salesTax.enableConvenienceFee">Enable Electronic Transfer Fee</label>
						</div>
					</div>
				</div>
				<div className="spc--bottom--lrg">
					<div className={`checkbox-list__item ${ccType !== percentage || !enableConvenienceFee ? 'disabled' : ''}`}>
						<div className="checkbox">
							<input
								type="checkbox"
								id="ccPercentage"
								name="ccType"
								checked={ccType === percentage}
								value={ccType === percentage ? none : percentage}
								onChange={handleConvenienceFeesChange}
								className="input--check"
								disabled={!enableConvenienceFee}
							/>
							<label htmlFor="ccPercentage">CC Percent</label>
						</div>
						<div className="edit">
							<NumberFormat
								value={ccPercentageAmount}
								disabled={ccType !== percentage || !enableConvenienceFee}
								suffix="%"
								decimalScale={2}
								className={this.getInputClassName(ccPercentageAmount, ccType === percentage, 'ccPercentageAmount')}
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								onValueChange={handleNumberChange}
								name="ccPercentageAmount"
								isAllowed={this.checkIfAllowedValue}
								onFocus={this.handleFieldFocus}
								onBlur={this.handleFieldBlur}
							/>
						</div>
					</div>
					<div className={`checkbox-list__item ${achType !== percentage || !enableConvenienceFee ? 'disabled' : ''}`}>
						<div className="checkbox">
							<input
								type="checkbox"
								id="achPercentage"
								name="achType"
								checked={achType === percentage}
								value={achType === percentage ? none : percentage}
								onChange={handleConvenienceFeesChange}
								className="input--check"
								disabled={!enableConvenienceFee}
							/>
							<label htmlFor="achPercentage">ACH Percent</label>
						</div>
						<div className="edit">
							<NumberFormat
								value={achPercentageAmount}
								disabled={achType !== percentage || !enableConvenienceFee}
								suffix="%"
								decimalScale={2}
								className={this.getInputClassName(achPercentageAmount, achType === percentage, 'achPercentageAmount')}
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								onValueChange={handleNumberChange}
								name="achPercentageAmount"
								isAllowed={this.checkIfAllowedValue}
								onFocus={this.handleFieldFocus}
								onBlur={this.handleFieldBlur}
							/>
						</div>
					</div>
					<div className={`checkbox-list__item ${ccType !== flat || !enableConvenienceFee} ? 'disabled' : ''`}>
						<div className="checkbox">
							<input
								type="checkbox"
								id="ccFlat"
								name="ccType"
								checked={ccType === flat}
								value={ccType === flat ? none : flat}
								onChange={handleConvenienceFeesChange}
								className="input--check"
								disabled={!enableConvenienceFee}
							/>
							<label htmlFor="ccFlat">CC Set Amount</label>
						</div>
						<div className="edit">
							<NumberFormat
								value={ccFlatAmount}
								prefix={this.currencyCode}
								inputMode="numeric"
								disabled={ccType !== flat || !enableConvenienceFee}
								decimalScale={2}
								className={this.getInputClassName(ccFlatAmount, ccType === flat, 'ccFlatAmount')}
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								onValueChange={handleNumberChange}
								name="ccFlatAmount"
								onFocus={this.handleFieldFocus}
								onBlur={this.handleFieldBlur}
							/>
						</div>
					</div>
					<div className={`checkbox-list__item ${achType !== flat || !enableConvenienceFee} ? 'disabled' : ''`}>
						<div className="checkbox">
							<input
								type="checkbox"
								id="achFlat"
								name="achType"
								checked={achType === flat}
								value={achType === flat ? none : flat}
								onChange={handleConvenienceFeesChange}
								className="input--check"
								disabled={!enableConvenienceFee}
							/>
							<label htmlFor="achFlat">ACH Set Amount</label>
						</div>
						<div className="edit">
							<NumberFormat
								value={achFlatAmount}
								prefix={this.currencyCode}
								inputMode="numeric"
								disabled={achType !== flat || !enableConvenienceFee}
								decimalScale={2}
								className={this.getInputClassName(achFlatAmount, achType === flat, achFlatAmount)}
								thousandSeparator=","
								decimalSeparator="."
								allowNegative={false}
								onValueChange={handleNumberChange}
								name="achFlatAmount"
								onFocus={this.handleFieldFocus}
								onBlur={this.handleFieldBlur}
							/>
						</div>
					</div>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label htmlFor="convenienceCustomKey" className="form__group__label">
							Custom field to save the Electronic Transfer Fee amount
						</label>
					</div>
					<Select
						name="convenienceCustomKey"
						id="convenienceCustomKey"
						value={getCustomField(convenienceCustomKey)}
						options={filter(customFieldOptions, ({ value }) => !value || value !== originalCustomKey)}
						onChange={handleSelectChange}
						menuPlacement="auto"
						isDisabled={!enableConvenienceFee}
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label htmlFor="convFee.originalCustomKey" className="form__group__label">
							Custom field to save the original amount{' '}
						</label>
						<i
							data-tooltip="This field is also used by Sales Tax."
							className="icon icon--tny icon--regular--info datatooltip--top-left"
						></i>
					</div>
					<Select
						name="originalCustomKey"
						id="convFee.originalCustomKey"
						value={getCustomField(originalCustomKey)}
						options={filter(customFieldOptions, ({ value }) => !value || value !== convenienceCustomKey)}
						onChange={handleSelectChange}
						menuPlacement="auto"
						isDisabled={!enableConvenienceFee}
					/>
				</div>
				<div className="spc--bottom--sml--alt">
					<input
						type="checkbox"
						id="convFee.allowExclude"
						name="allowExclude"
						checked={allowExclude}
						value={allowExclude}
						onChange={handleCheckboxChange}
						className="input input--check"
						disabled={!enableConvenienceFee}
					/>
					<label htmlFor="convFee.allowExclude" className="type--color--text--medium type--wgt--medium">
						Allow Users to Include/Exclude Electronic Transfer Fee
					</label>
				</div>
				<div className="separator--left--primary spc--bottom--med">
					<div className="spc--left--med">
						<input
							type="checkbox"
							id="convFee.enabledByDefault"
							name="enabledByDefault"
							checked={enabledByDefault}
							value={enabledByDefault}
							onChange={handleCheckboxChange}
							className="input input--check"
							disabled={!allowExclude || !enableConvenienceFee}
						/>
						<label htmlFor="convFee.enabledByDefault" className="type--wgt--medium">
							Enabled by Default
						</label>
					</div>
				</div>
				<div className="spc--bottom--med">
					<input
						type="checkbox"
						id="convFee.convenienceBeforeSales"
						name="convenienceBeforeSales"
						checked={convenienceBeforeSales}
						value={convenienceBeforeSales}
						onChange={handleCheckboxChange}
						className="input input--check"
						disabled={!enableConvenienceFee}
					/>
					<label htmlFor="convFee.convenienceBeforeSales" className="type--color--text--medium type--wgt--medium">
						Apply Electronic Transfer Fee before Sales Tax
					</label>
				</div>
				<div>
					<input
						type="checkbox"
						id="convFee.applyToRecurringSchedule"
						name="applyToRecurringSchedule"
						checked={applyToRecurringSchedule}
						value={applyToRecurringSchedule}
						onChange={handleCheckboxChange}
						className="input input--check"
						disabled={!enableConvenienceFee}
					/>
					<label htmlFor="convFee.applyToRecurringSchedule" className="type--color--text--medium type--wgt--medium">
						Apply to Recurring Schedules
					</label>
				</div>
			</div>
		);
	};
}

AccountManagementTransactionSettings.propTypes = {
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(
	withLoader(withCancelable(withBlock(AccountManagementTransactionSettings))),
	handleInvalidRevision
);
