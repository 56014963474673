import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, map, find } from 'lodash';

class CardTypeFilterComponent extends Component {
	filterChanged = event => {
		const { value: name, checked } = event.target;

		const values = map(this.props.filter.values, (value, key) => {
			return { key, value };
		});

		const changedValue = find(values, value => value.key === name);
		changedValue.value = checked;

		this.props.onFilterChanged({
			id: 'cardType',
			values: values,
			emptyValue: false,
		});
	};

	render() {
		const { filter, goButtonHandler } = this.props;
		const { visa, mastercard, amex, discover, jcb, diners, other } = filter.values;
		const isAnyChecked = visa || mastercard || amex || discover || jcb || diners;

		return (
			<div className="flex--primary flex--column flex--gap--tny flex--top">
				<div className="flex--tertiary flex--gap--sml flex--nowrap fullwidth">
					<div>
						<input
							disabled={other}
							type="checkbox"
							name="ctype-visa"
							className="input--check"
							checked={visa}
							value="visa"
							id="ctype-visa"
							onChange={noop}
							onClick={this.filterChanged}
						/>
						<label htmlFor="ctype-visa">Visa</label>
					</div>
					<img alt="Visa" src="/static/media/credit-cards/visa.svg" className="w--20 flex--no-shrink" />
				</div>
				<div className="flex--tertiary flex--gap--sml flex--nowrap fullwidth">
					<div>
						<input
							disabled={other}
							type="checkbox"
							name="ctype-mastercard"
							className="input--check"
							checked={mastercard}
							value="mastercard"
							id="ctype-mastercard"
							onChange={noop}
							onClick={this.filterChanged}
						/>
						<label htmlFor="ctype-mastercard">Mastercard</label>
					</div>
					<img alt="Mastercard" src="/static/media/credit-cards/mastercard.svg" className="w--20 flex--no-shrink" />
				</div>
				<div className="flex--tertiary flex--gap--sml flex--nowrap fullwidth">
					<div>
						<input
							disabled={other}
							type="checkbox"
							name="ctype-amex"
							className="input--check"
							checked={amex}
							value="amex"
							id="ctype-amex"
							onChange={noop}
							onClick={this.filterChanged}
						/>
						<label htmlFor="ctype-amex">American Express</label>
					</div>
					<img alt="Amex" src="/static/media/credit-cards/amex.svg" className="w--20 flex--no-shrink" />
				</div>
				<div className="flex--tertiary flex--gap--sml flex--nowrap fullwidth">
					<div>
						<input
							disabled={other}
							type="checkbox"
							name="ctype-discover"
							className="input--check"
							checked={discover}
							value="discover"
							id="ctype-discover"
							onChange={noop}
							onClick={this.filterChanged}
						/>
						<label htmlFor="ctype-discover">Discover</label>
					</div>
					<img alt="Discover" src="/static/media/credit-cards/discover.svg" className="w--20 flex--no-shrink" />
				</div>
				<div className="flex--tertiary flex--gap--sml flex--nowrap fullwidth">
					<div>
						<input
							disabled={other}
							type="checkbox"
							name="ctype-jcb"
							className="input--check"
							checked={jcb}
							value="jcb"
							id="ctype-jcb"
							onChange={noop}
							onClick={this.filterChanged}
						/>
						<label htmlFor="ctype-jcb">JCB</label>
					</div>
					<img alt="JCB" src="/static/media/credit-cards/jcb.svg" className="w--20 flex--no-shrink" />
				</div>
				<div className="flex--tertiary flex--gap--sml flex--nowrap fullwidth">
					<div>
						<input
							disabled={other}
							type="checkbox"
							name="ctype-diners"
							className="input--check"
							checked={diners}
							value="diners"
							id="ctype-diners"
							onChange={noop}
							onClick={this.filterChanged}
						/>
						<label htmlFor="ctype-diners">Diners</label>
					</div>
					<img alt="Diners" src="/static/media/credit-cards/diners.svg" className="w--20 flex--no-shrink" />
				</div>
				<div className="flex--tertiary flex--gap--sml flex--nowrap fullwidth">
					<div>
						<input
							disabled={isAnyChecked}
							type="checkbox"
							name="ctype-other"
							className="input--check"
							checked={other}
							value="other"
							id="ctype-other"
							onChange={noop}
							onClick={this.filterChanged}
						/>
						<label htmlFor="ctype-other">Other</label>
					</div>
					<img alt="Unknown" src="/static/media/credit-cards/unknown.svg" className="w--20 flex--no-shrink" />
				</div>
				{goButtonHandler && (
					<button className="btn btn--med btn--primary spc--top--med" onClick={goButtonHandler}>
						Submit
					</button>
				)}
			</div>
		);
	}
}

CardTypeFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	goButtonHandler: PropTypes.func,
};

export default CardTypeFilterComponent;
