import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { includes, isEmpty, map } from 'lodash-es';

export default class UploadInvoice extends Component {
	constructor(props) {
		super(props);
	}
	renderDropzoneBody = () => {
		return (
			<div className="upload__text">
				<p className="type--p3 type--p3--medium spc--bottom--tny">Attach an invoice or drag and drop</p>
				<p className="type--p4 type--color--text--light">
					Allowed formats are PDF, DOC/DOCX, XLS/XLSX, CSV, JPG/JPEG, PNG, TIFF.
				</p>
			</div>
		);
	};

	displayAttachments = () => {
		if (isEmpty(this.props.files)) return;
		return (
			<div>
				{map(this.props.files, fileData => {
					return (
						<div className="upload__list__item spc--top--med" key={fileData.file}>
							{includes(fileData.type, 'image') && (
								<div className="upload__list__item__thumbnail">
									<img alt="Uploaded file thumbnail" src={fileData.file} />
								</div>
							)}

							<span className="upload__list__item__filename">{fileData.fileName}</span>
							<button
								className="btn upload__list__item__remove datatooltip--auto"
								data-tooltip="Remove"
								onClick={e => this.props.removeAttachment(e, fileData.fileName)}
							>
								<i className="icon icon--sml icon--close"></i>
							</button>
						</div>
					);
				})}
			</div>
		);
	};
	renderFileSizeError = () => {
		if (this.props.fileSizeError) {
			return (
				<p className="type--validation spc--bottom--med">File size limit is 15MB for emails sent through the portal</p>
			);
		}
		return null;
	};

	render = () => {
		return (
			<div className="card spc--bottom--lrg">
				<div className="card__header">
					<h5>Attach An Invoice</h5>
					<i
						className="icon icon--lrg icon--info datatooltip--top-left"
						data-tooltip="Max 5 Invoices can be uploaded."
					></i>
				</div>
				<div className="card__body">
					{this.renderFileSizeError()}
					<Dropzone onDrop={this.props.onDrop} multiple={true} maxFiles={5}>
						{({ getRootProps, getInputProps }) => (
							<section className="upload" {...getRootProps()}>
								<input {...getInputProps()} />
								<div className="upload__icon"></div>
								{this.renderDropzoneBody()}
							</section>
						)}
					</Dropzone>
					{this.displayAttachments()}
				</div>
			</div>
		);
	};
}

UploadInvoice.propTypes = {
	onDrop: PropTypes.func,
	files: PropTypes.any,
	uploadedFile: PropTypes.any,
	fileSizeError: PropTypes.bool.isRequired,
	removeAttachment: PropTypes.func,
};
