import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { invokeIfFunction } from 'common/utilities';

const FormGroup = ({
	id,
	name,
	formKey,
	htmlForKey,
	label,
	value,
	onChange,
	type = 'text',
	options = [],
	disabled = false,
	placeholder = '',
	requiredFields = {},
	getInputClassName,
	...additionalProps
}) => {
	const isRequired = requiredFields[formKey];

	const { className: externalClassName, ...restAdditionalProps } = additionalProps;

	const generateClassName = defaultClassName => {
		const baseClassName = invokeIfFunction(getInputClassName, defaultClassName, name) || defaultClassName;
		return [baseClassName, externalClassName].filter(Boolean).join(' ');
	};

	let inputElement;
	switch (type) {
		case 'select':
			inputElement = (
				<Select
					id={id}
					name={name}
					className={generateClassName('react-select-container')}
					classNamePrefix="react-select"
					value={value}
					options={options}
					onChange={onChange}
					isDisabled={disabled}
					{...restAdditionalProps}
				/>
			);
			break;

		case 'number':
			inputElement = (
				<NumberFormat
					id={id}
					value={value}
					onValueChange={onChange}
					className={generateClassName('input input--med')}
					placeholder={placeholder}
					disabled={disabled}
					name={name}
					{...restAdditionalProps}
				/>
			);
			break;

		case 'checkbox':
			inputElement = (
				<input
					type="checkbox"
					id={id}
					name={name}
					className={generateClassName('input--checkbox')}
					checked={value}
					onChange={onChange}
					disabled={disabled}
					{...restAdditionalProps}
				/>
			);
			break;

		case 'radio':
			inputElement = (
				<input
					type="radio"
					id={id}
					name={name}
					className={generateClassName('input--radio')}
					checked={value}
					onChange={onChange}
					disabled={disabled}
					{...restAdditionalProps}
				/>
			);
			break;

		default:
			inputElement = (
				<input
					type={type}
					id={id}
					name={name}
					className={generateClassName('input input--med')}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					disabled={disabled}
					{...restAdditionalProps}
				/>
			);
	}

	return (
		<div className="form__group">
			<div className="form__group__header">
				<label htmlFor={htmlForKey || id} className="form__group__label">
					{label}
				</label>
				{isRequired && (
					<span data-tooltip="Required" className="form__group__required">
						*
					</span>
				)}
			</div>
			{inputElement}
		</div>
	);
};

FormGroup.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	formKey: PropTypes.string.isRequired,
	htmlForKey: PropTypes.string,
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
	onChange: PropTypes.func.isRequired,
	type: PropTypes.string,
	options: PropTypes.array,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	requiredFields: PropTypes.object,
	getInputClassName: PropTypes.func,
};

export default FormGroup;
