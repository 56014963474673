import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { keys, map, reduce, get } from 'lodash';
import { Select } from '../select';
import { invokeIfFunction } from 'common/utilities';

class SelectFilterComponent extends Component {
	handleSelectChange = selectedOption => {
		const {
			filter: { key },
			isMulti,
			isDropdown,
			onFilterChanged,
		} = this.props;
		let newValues;

		if (isDropdown) {
			newValues = [{ key: selectedOption.value, value: selectedOption.label }];
		} else if (isMulti) {
			newValues = map(selectedOption, option => ({
				key: option.value,
				value: option.label,
			}));
		} else {
			newValues = [{ key: selectedOption.value, value: selectedOption.label }];
		}

		onFilterChanged({
			id: key,
			values: newValues,
			emptyValue: false,
		});
	};
	handleChange = e => {
		const {
			target: { value, checked },
		} = e;
		const { onFilterChanged, singleSelect } = this.props;
		const filter = { ...this.props.filter };
		const { values: oldValues, key } = filter;

		let newValues = { ...oldValues };

		if (singleSelect) {
			if (checked) {
				newValues = reduce(
					keys(newValues),
					(acc, filterKey) => {
						acc[filterKey] = filterKey === value;
						return acc;
					},
					{}
				);
			} else {
				newValues[value] = checked;
			}
		} else {
			newValues[value] = checked;
		}

		onFilterChanged({
			id: key,
			values: newValues,
			emptyValue: false,
		});
	};
	render() {
		const {
			isMulti,
			isDropdown,
			filter: { isRadio, key, values, checkbox, permissions: filterPermissions, options, selectOptions, disabled = {} },
			permissions,
			injectedFilters,
			goButtonHandler,
		} = this.props;

		return (
			<div className={!isDropdown ? 'flex--primary flex--gap--xlrg' : ''}>
				{isDropdown ? (
					<Select name={key} id={key} options={selectOptions} onChange={this.handleSelectChange} isMulti={isMulti} />
				) : (
					map(values, (checked, optionKey) => {
						const id = `${key}-${optionKey}`;
						return (
							<div key={id} className="spc--top--sml">
								<input
									disabled={
										(get(filterPermissions, optionKey) && !get(permissions, filterPermissions[optionKey])) ||
										invokeIfFunction(get(disabled, optionKey), injectedFilters)
									}
									type={isMulti || checkbox ? 'checkbox' : 'radio'}
									name={isRadio ? key : optionKey}
									className={`input--${isMulti || checkbox ? 'check' : 'radio'}`}
									checked={checked}
									value={optionKey}
									id={id}
									onChange={this.handleChange}
								/>
								<label htmlFor={id}>{options[optionKey]}</label>
							</div>
						);
					})
				)}
				{goButtonHandler && (
					<button className="btn btn--med btn--primary" onClick={goButtonHandler}>
						Submit
					</button>
				)}
			</div>
		);
	}
}
SelectFilterComponent.defaultProps = {
	isMulti: false,
	isDropdown: false,
	selectOptions: [],
	singleSelect: false,
};

SelectFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	isMulti: PropTypes.bool,
	isDropdown: PropTypes.bool,
	permissions: PropTypes.object,
	injectedFilters: PropTypes.object,
	goButtonHandler: PropTypes.func,
	selectOptions: PropTypes.array,
	singleSelect: PropTypes.bool,
};

export default SelectFilterComponent;
