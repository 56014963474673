import React from 'react';
import { map } from 'lodash';

import { NumericFilter, MultiselectCardknoxFilter, StringFilter } from '../../columns/filters';

import {
	DisplayDateComponent,
	CreditCardComponent,
	CurrencyComponent,
	StatusFraudComponent,
	AvsComponent,
} from '../../columns/formatters';
import { ColumnHeader } from 'common/components/columns/headers';
import { FraudRowActions } from 'common/components/transaction-actions';

const columns = [
	{
		order: 1,
		key: 'xRefNum',
		name: 'Reference Number',
		sortable: true,
		hideable: true,
		visible: true,
		locked: true,
		disabled: true,
		disableDrag: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 160,
		resizable: true,
		customSettingsKey: 'xRefNum',
	},
	{
		key: 'xEnteredDate',
		name: 'Date',
		sortable: true,
		hideable: true,
		filterable: true,
		visible: false,
		filterRenderer: StringFilter,
		formatter: DisplayDateComponent,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		initWidth: 220,
		resizable: true,
		customSettingsKey: 'xEnteredDate',
	},
	{
		key: 'currency',
		exportKey: 'xCurrency',
		name: 'Currency',
		sortable: true,
		hideable: false,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 75,
		resizable: true,
		customSettingsKey: 'currency',
	},
	{
		key: 'xAmount',
		name: 'Amount',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		dependentExportKey: 'currency',
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 150,
		resizable: true,
		alignHeaderRight: true,
		customSettingsKey: 'xAmount',
	},
	{
		key: 'xCardType',
		name: 'Card type',
		sortable: true,
		hideable: false,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 220,
		resizable: true,
		customSettingsKey: 'xCardType',
	},
	{
		key: 'xMaskedCardNumber',
		name: 'Account #',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		formatter: CreditCardComponent,
		filterRenderer: StringFilter,
		getRowMetaData: row => row,
		initWidth: 150,
		resizable: true,
		dependentExportKey: 'xCardType',
		customSettingsKey: 'xMaskedCardNumber',
	},
	{
		key: 'xName',
		name: 'C­ar­dholder N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 220,
		resizable: true,
		customSettingsKey: 'xName',
	},
	{
		key: 'xInvoice',
		name: 'Invoice',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
		customSettingsKey: 'xInvoice',
	},
	{
		key: 'xStatus',
		name: 'Status',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		formatter: StatusFraudComponent,
		filterRenderer: MultiselectCardknoxFilter,
		initWidth: 170,
		resizable: true,
		generateInlineFilterOptions: true,
		customSettingsKey: 'xStatus',
	},
	{
		key: 'xStatusReason',
		name: 'Status Details',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xStatusReason',
	},
	{
		key: 'xEntryMethod',
		name: 'Entry Method',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xEntryMethod',
	},
	{
		key: 'xDescription',
		name: 'Description',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xDescription',
	},
	{
		key: 'xCommand',
		name: 'Command',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xCommand',
	},
	{
		key: 'xResponseCVVCode',
		name: 'CVV Result',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xResponseCVVCode',
	},
	{
		key: 'xResponseAVSCode',
		name: 'AVS',
		formatter: AvsComponent,
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xResponseAVSCode',
	},
	{
		key: 'xOrderID',
		name: 'Order Id',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xOrderID',
	},
	{
		key: 'xPONum',
		name: 'PO Number',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xPONum',
	},
	{
		key: 'xSourceKey',
		name: 'Source Key',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xSourceKey',
	},
	{
		key: 'xUserName',
		name: 'User Name',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xUserName',
	},
	{
		key: 'xClientIP',
		name: 'Client IP',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xClientIP',
	},
	{
		key: 'xEmail',
		name: 'Email',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xEmail',
	},
	{
		key: 'xSoftwareName',
		name: 'Software Name',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xSoftwareName',
	},
	{
		key: 'xCardSource',
		name: 'Card Source',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xCardSource',
	},
	{
		key: 'xBillFirstName',
		name: 'Bill First Name',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillFirstName',
	},
	{
		key: 'xBillLastName',
		name: 'Bill Last Name',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillLastName',
	},
	{
		key: 'xBillCompany',
		name: 'Bill Company',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillCompany',
	},
	{
		key: 'xBillStreet',
		name: 'Bill Street',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillStreet',
	},
	{
		key: 'xBillCity',
		name: 'Bill City',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillCity',
	},
	{
		key: 'xBillZip',
		name: 'Bill Zip',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillZip',
	},
	{
		key: 'xBillState',
		name: 'Bill State',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillState',
	},
	{
		key: 'xBillCountry',
		name: 'Bill Country',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillCountry',
	},
	{
		key: 'xBillPhone',
		name: 'Bill Phone',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xBillPhone',
	},
	{
		key: 'xShipFirstName',
		name: 'Ship First Name',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipFirstName',
	},
	{
		key: 'xShipLastName',
		name: 'Ship Last Name',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipLastName',
	},
	{
		key: 'xShipCompany',
		name: 'Ship Company',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipCompany',
	},
	{
		key: 'xShipStreet',
		name: 'Ship Street',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipStreet',
	},
	{
		key: 'xShipCity',
		name: 'Ship City',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipCity',
	},
	{
		key: 'xShipZip',
		name: 'Ship Zip',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipZip',
	},
	{
		key: 'xShipState',
		name: 'Ship State',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipState',
	},
	{
		key: 'xShipCountry',
		name: 'Ship Country',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipCountry',
	},
	{
		key: 'xShipPhone',
		name: 'Ship Phone',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xShipPhone',
	},
	{
		key: 'xCustom01',
		name: 'Custom01',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xCustom01',
	},
	{
		key: 'xCustom02',
		name: 'Custom02',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xCustom02',
	},
	{
		key: 'xCustom03',
		name: 'Custom03',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 260,
		resizable: true,
		customSettingsKey: 'xCustom03',
	},
	{
		key: 'canResubmit',
		hideable: false,
		visible: true,
		formatter: FraudRowActions,
		filterRenderer: MultiselectCardknoxFilter,
		getRowMetaData: row => row,
		initWidth: 170,
		resizable: false,
	},
];

export const FraudColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
