import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, filter as filterMethod, isEqual, find } from 'lodash';

import { mapAvsItem } from 'common/utilities';

class ExpandFilterContainerComponent extends Component {
	constructor(props) {
		super(props);
		this.onFilterChanged = this.onFilterChanged.bind(this);

		this.state = {
			value: null,
			hasValue: false,
			isEditable: false,
		};
	}

	get cardNames() {
		return {
			visa: 'Visa',
			mastercard: 'Mastercard',
			amex: 'American Express',
			discover: 'Discover',
			jcb: 'JCB',
			diners: 'Diners',
			other: 'Other',
		};
	}
	shouldComponentUpdate = (nextProps, nextState) => {
		return (
			!isEqual(this.props.filter.values, nextProps.filter.values) ||
			this.state.value !== nextState.value ||
			this.state.isEditable !== nextState.isEditable ||
			this.state.hasValue !== nextState.hasValue ||
			this.props.filter.hasSelection !== nextProps.filter.hasSelection
		);
	};

	componentDidUpdate = prevProps => {
		if (prevProps.filter !== this.props.filter) {
			let hasValue = false;
			let value = null;
			if (this.props.filter.hasSelection) {
				hasValue = true;
				value = this.parseDisplayValue({
					id: this.props.filter.key,
					values: map(this.props.filter.values, (value, key) => ({
						key,
						value,
					})),
				});
			} else {
				hasValue = false;
				value = null;
			}
			this.setState({
				hasValue,
				value,
			});
		}
	};

	onFilterChanged = filter => {
		this.props.onFilterChanged(filter);

		let hasValue = false;
		let value = null;
		if (this.props.filter.hasSelection) {
			hasValue = true;
			value = this.parseDisplayValue(filter);
		} else {
			hasValue = false;
			value = null;
		}

		this.setState({
			hasValue: hasValue,
			value: value,
		});
	};

	parseDisplayValue = filter => {
		if (filter.id === 'cvv') {
			const foundItem = find(filter.values, { value: true });
			return foundItem && foundItem.key === 'match' ? 'Match' : 'No match';
		} else if (filter.id === 'avs') {
			return mapAvsItem(filter.values[0].key).text;
		} else if (filter.id === 'cardType') {
			return map(filterMethod(filter.values, item => item.value), item => this.cardNames[item.key]).join(', ');
		} else if (filter.id === 'custom') {
			return map(filterMethod(filter.values, item => item.value), item => item.value).join(', ');
		} else {
			return filter.values[0].value;
		}
	};

	onFilterClear = key => {
		const { filter } = this.props;
		this.setState(
			{
				hasValue: false,
				value: key === 'cvv' ? 'No match' : filter.defaultValues[key],
			},
			() => this.props.onFilterClear(key)
		);
	};

	showEditable = e => {
		const isEditable = e.target.checked;
		this.setState({ isEditable });
	};

	render() {
		const { label, component, filter, filterKey } = this.props;
		const { value, hasValue } = this.state;

		const isEditable = this.state.isEditable;

		let labelClasses = ['aside'];
		if (hasValue) {
			labelClasses.push('is-filled');
		}
		if (isEditable) {
			labelClasses.push('is-active');
		}

		return (
			<div className={`reports__filter__item${filter.key === 'cardType' ? ' card-type' : ''}`}>
				<div className={labelClasses.join(' ')}>
					<input type="checkbox" id={label} className="input--check" onChange={this.showEditable} />
					<label htmlFor={label}>{label}</label>
				</div>
				<div className="main">
					{isEditable ? (
						React.cloneElement(component, { filter: filter, onFilterChanged: this.onFilterChanged })
					) : hasValue ? (
						<div className="reports__filter__item__value">
							{value}
							{!isEditable ? (
								<button className="btn btn--link" onClick={() => this.onFilterClear(filterKey)}>
									<i className="icon icon--med icon--clear-value"></i>
								</button>
							) : null}
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

ExpandFilterContainerComponent.propTypes = {
	label: PropTypes.string.isRequired,
	filterKey: PropTypes.string.isRequired,
	component: PropTypes.object,
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	onFilterClear: PropTypes.func.isRequired,
};

export default ExpandFilterContainerComponent;
