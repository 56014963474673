import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, map, identity, each, get, noop, isEmpty } from 'lodash';

import { paymentSiteService } from './../../../../Common/services';
import { PaymentSiteColumns as Columns } from './../../../../Common/components/payment-site/column-filter/paymentSiteColumns';
import { GridComponent } from './../../../../Common/components/grid';
import { withError } from './../../../../Common/components/error';
import { withCancelable } from './../../../../Common/components/cancelable';
import { ZebraRenderer } from './../../../../Common/components/row';
import { exportService } from './../../../../Common/components/export/exportService';
import { ExportComponent } from 'common/components/export';
import EnrollWithPaymentSite from './EnrollWithPaymentSite';

const requestKeys = {
	fetch: 'FETCH',
	KVAAS: 'kvaas',
};

class PaymentSiteList extends Component {
	constructor(props) {
		super(props);
		this.state = cloneDeep(this.initialState);

		this.gridRef = createRef();
		this.headerRef = createRef();

		this.components = {
			rowRenderer: ZebraRenderer,
		};

		this.classes = {
			wrapper: 'settings--main',
			header: '',
			title: 'flex--tertiary',
			headerMenu: 'settings__header__action',
			headerMenuAction: 'settings__header__action',
			headerGroup: '',
			content: '',
		};
	}

	get initialState() {
		return {
			data: null,
			fetchingData: true,
			expanded: false,
			columns: cloneDeep(Columns),
		};
	}

	getSnapshotBeforeUpdate(prevProps) {
		return prevProps.location.key !== this.props.location.key;
	}
	async componentDidMount() {
		this.headerRef.current && this.headerRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (snapshot) {
			this.setState(this.initialState, get(this.gridRef, 'current.reset', noop));
		}
	}

	fetchData = async () => {
		this.setState({
			fetchingData: true,
			data: null,
		});

		try {
			const data = await this.props.makePendingRequest(paymentSiteService.find(), requestKeys.fetch);
			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
			}
			this.mapData(data);
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data: data,
					fetchingData: false,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				item.gridRowNumber = i;
				item.index = i + 1;
				i++;
			});
		}
	};

	mapRow = identity;

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'parsedCreated':
				key = 'created';
				break;
			default:
				break;
		}
		return key;
	};

	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	goToEdit = (rowNumber, row) => {
		if (rowNumber > -1) {
			const {
				history,
				match: { path },
			} = this.props;
			history.push(`${path}/${row.id}`);
		}
	};

	renderHeaderDisclaimer = () => {
		const { data, columns } = this.state;
		return (
			<div className="settings__header">
				<h3 className="settings__title">Gateway Settings</h3>
				<div className="flex--tertiary flex--gap--sml">
					<h5 ref={this.headerRef}>PaymentSITE Management</h5>
					<ExportComponent
						data={get(data, 'xReportData', [])}
						columns={columns}
						fetchExportData={{ current: exportService.mapPaymentSiteData }}
						type="paymentSite"
						showLoaderMethod={noop}
					/>
				</div>
			</div>
		);
	};

	render = () => {
		const { data, fetchingData, columns } = this.state;

		return !fetchingData && isEmpty(get(data, 'xReportData')) ? (
			<div className="settings--main settings--main--alt">
				<EnrollWithPaymentSite disclaimer="Sign up with PaymentSITE to Access These Settings" displayHeader={true} />
			</div>
		) : (
			<Fragment>
				{this.renderHeaderDisclaimer()}
				<GridComponent
					fetchingData={fetchingData}
					filteredRows={(data && data.xReportData) || []}
					resolveColumnName={this.resolveColumnName}
					onChange={this.handleChange}
					columns={columns}
					data={data}
					classes={this.classes}
					hasPaging={false}
					title={false}
					type="paymentSite"
					enableFilters={false}
					enableExport={false}
					fetchData={this.fetchData}
					showPanel={false}
					showGridHeader={false}
					ref={this.gridRef}
					useInlineFilters={false}
					components={this.components}
					fetchExportData={{ current: exportService.mapPaymentSiteData }}
					headerMenuThreshold={Infinity}
					onRowClick={this.goToEdit}
					customWidth={'auto'}
				/>
			</Fragment>
		);
	};
}

PaymentSiteList.propTypes = {
	handleError: PropTypes.func,
	errorMessageVisible: PropTypes.bool,
	makePendingRequest: PropTypes.func,
	location: PropTypes.object,
	match: PropTypes.object,
	history: PropTypes.object,
};

export default withError(withCancelable(PaymentSiteList));
