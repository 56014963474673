import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import moment from 'moment';

import TransactionHistoryItem from './TransactionHistoryItem';

class CustomerTransactionsHistory extends Component {
	get filters() {
		const { customer } = this.props;
		const custom01 = customer.customerId;
		const startDate = moment()
			.startOf('day')
			.subtract(6, 'months')
			.format(ApplicationSettings.parseDateTimeFormat);
		const endDate = moment()
			.endOf('day')
			.format(ApplicationSettings.parseDateTimeFormat);
		const key = 'custom';

		return {
			custom01,
			startDate,
			endDate,
			key,
		};
	}

	render() {
		const { transactions, errorMessages, loadmore, loadMoreFlag, dataRange } = this.props;
		const { custom01, startDate, endDate, key } = this.filters;
		const range = moment(dataRange.end).diff(dataRange.start, 'days');
		return (
			<Fragment>
				<div>
					{map(errorMessages, (errorMessage, index) => (
						<p className="type--validation spc--bottom--med" key={index}>
							{errorMessage}
						</p>
					))}
				</div>
				{(!transactions || transactions.length < 1) && (
					<div className="type--p3 type--color--text--light spc--bottom--lrg w--300 align--h--center type--center">
						{range > 179
							? 'No transactions linked to the customer in the past 6 months.'
							: `Customer doesn't have any transactions in the last ${range} days.`}
					</div>
				)}
				{transactions && (
					<React.Fragment>
						{map(transactions, (item, index) => {
							return <TransactionHistoryItem key={index} item={item} />;
						})}
						<div className="info-panel__section">
							<div className="flex--tertiary flex--gap--med">
								{loadmore && loadMoreFlag && (
									<button
										className="btn btn--link datatooltip--w--100"
										data-tooltip="Load next 30 days transaction history."
										onClick={loadmore}
									>
										Load more
									</button>
								)}
								<a
									href={`/transactions?custom01=${custom01}&endDate=${endDate}&key=${key}&startDate=${startDate}`}
									target="_blank"
									className="btn btn--link"
								>
									View all customer transactions
								</a>
							</div>
						</div>
					</React.Fragment>
				)}
			</Fragment>
		);
	}
}

CustomerTransactionsHistory.propTypes = {
	customer: PropTypes.object.isRequired,
	transactions: PropTypes.array,
	customerId: PropTypes.string,
	errorMessages: PropTypes.arrayOf(PropTypes.string),
	loadmore: PropTypes.func,
	loadMoreFlag: PropTypes.bool,
	dataRange: PropTypes.shape({
		start: PropTypes.instanceOf(moment),
		end: PropTypes.instanceOf(moment),
	}),
};
export default CustomerTransactionsHistory;
