// for now kept here, will move to common folder later

export const fileTypes = {
	jpeg: 'image/jpeg',
	pdf: 'application/pdf',
	gif: 'image/gif',
	png: 'image/png',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	doc: 'application/msword',
	txt: 'text/plain',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	xls: 'application/vnd.ms-excel',
	log: '.log',
	bmp: 'image/bmp',
	odt: 'application/vnd.oasis.opendocument.text',
	csv: 'text/csv',
	tiff: 'image/tiff',
};

export const fileTypesValues = {
	[fileTypes.pdf]: ['.pdf'],
	[fileTypes.gif]: ['.gif'],
	[fileTypes.png]: ['.png'],
	[fileTypes.doc]: ['.doc'],
	[fileTypes.xls]: ['.xls'],
	[fileTypes.log]: ['.log'],
	[fileTypes.odt]: ['.odt'],
	[fileTypes.xlsx]: ['.xlsx'],
	[fileTypes.docx]: ['.docx'],
	[fileTypes.bmp]: ['.bmp', '.dip'],
	[fileTypes.txt]: ['.txt', '.text'],
	[fileTypes.jpeg]: ['.jpg', '.jpeg', '.jfif', '.pjpeg'],
	[fileTypes.csv]: ['.csv'],
	[fileTypes.tiff]: ['.tiff', '.tif'],
};
// Regular expression for validating filenames
export const filenameRegex = /^[a-zA-Z0-9]{1,200}\.[a-zA-Z0-9]{1,10}$/;

export const sanitizeFilename = filename => {
	// Remove control characters and unwanted special characters
	return filename.replace(/[^a-zA-Z0-9.]/g, '');
};

export const isValidFilename = filename => {
	return filenameRegex.test(sanitizeFilename(filename));
};
