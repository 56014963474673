import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toNumber, split, toLower, get, toUpper } from 'lodash';

import NumberFormat from 'react-number-format';

import { CurrencyMap } from './../../../utilities';
import { Modal } from 'common/components/modal';
import TooltipFormatterComponent from './TooltipFormatter';

class CurrencyComponent extends TooltipFormatterComponent {
	static instances = [];

	constructor(props) {
		super(props, CurrencyComponent.instances);

		this.tooltipClass = 'datatooltip--fake';

		this.state = {
			isOriginalRefPopupOpen: false,
		};
	}

	get tooltip() {
		return this.isSplitCaptured ? 'This is the amount that remains after split captures.' : null;
	}

	get currency() {
		return this.props.dependentValues && this.props.dependentValues.currency;
	}

	get isVoided() {
		return this.props.dependentValues && this.props.dependentValues.xVoid == 1;
	}

	get isSplitCaptured() {
		return parseInt(get(this.props, 'dependentValues.xClearedCount', 0)) > 0;
	}

	get isAuthOnly() {
		return (
			this.props.dependentValues &&
			this.props.dependentValues.xCommand &&
			this.props.dependentValues.xCommand.toLowerCase() === 'cc:authonly'
		);
	}

	get isSplitPay() {
		return this.props.dependentValues.xCommand && toLower(this.props.dependentValues.xCommand) === 'split pay';
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(toUpper(this.currency));
	}

	handleClosePopup = () => {
		this.setState({ isOriginalRefPopupOpen: false });
	};

	handleOpenPopup = () => {
		this.setState({ isOriginalRefPopupOpen: true });
	};

	renderOriginalRefPopup = () => {
		const {
			row: { xCustom01 },
		} = this.props;
		const { isOriginalRefPopupOpen } = this.state;

		return (
			<Modal
				isOpen={isOriginalRefPopupOpen}
				onClose={this.handleClosePopup}
				shouldCloseOnOverlayClick={false}
				className="modal__content"
			>
				<div className="modal__header">
					<div className="modal__header__title">Original transaction's Ref #</div>
				</div>
				<div className="modal__body">
					<p className="type--wgt--bold">Ref #: {xCustom01}</p>
				</div>
				<div className="modal__footer"></div>
			</Modal>
		);
	};
	getClassName = (isBreakdown, dependentValues, isBolded) => {
		return `flex--primary fullwidth type--wgt--${isBolded ? 'semibold' : 'medium'} ${
			isBreakdown || !(dependentValues.alignLeftOnPrint || dependentValues.alignLeftOnGrid) ? 'flex--right' : ''
		}`;
	};

	renderSplitCaptureAuth = (isNumber, displayBadge) => {
		if (isNumber && !this.isVoided && this.isAuthOnly && displayBadge) {
			return (
				<Fragment>
					{this.isSplitCaptured ? (
						<div>
							<i
								ref={this.elementRef}
								onMouseEnter={this.displayPopup}
								onMouseLeave={this.closePopup}
								className="icon icon--tny icon--regular--info spc--right--tny"
							></i>
							<div className="badge badge--dot badge--warning spc--right--tny">Split Captured</div>
						</div>
					) : (
						<div className="badge badge--dot badge--warning spc--right--tny">Auth</div>
					)}
				</Fragment>
			);
		}
	};

	renderValue = (isNumber, value, issueGift, dependentValues, row) => {
		if (!isNumber) {
			return <span>{value}</span>;
		} else if (isNumber && !this.isVoided) {
			return (
				<Fragment>
					<NumberFormat
						value={value}
						displayType="text"
						thousandSeparator={true}
						prefix={`${issueGift ? '- ' : ''}${this.currencyCode}`}
						decimalScale={2}
						fixedDecimalScale={true}
					/>
					{dependentValues && dependentValues.seperateCountColumn && row && row.currency ? (
						<span className="spc--left--tny">{row.currency}</span>
					) : null}
				</Fragment>
			);
		}
	};

	renderSplitPay = (isNumber, displayBadge) => {
		if (isNumber && this.isSplitPay && displayBadge) {
			return (
				<Fragment>
					{this.renderOriginalRefPopup()}
					<span
						className="type--status type--status--sml type--status--declined spc--left--tny align--v--middle"
						data-tooltip="Click to retrieve original transaction's Ref #"
						onClick={this.handleOpenPopup}
					>
						<span className="type--uppercase">Split Pay</span>
					</span>
				</Fragment>
			);
		}
	};

	renderVoided = (isNumber, displayBadge, value) => {
		if (isNumber && this.isVoided) {
			return (
				<Fragment>
					{displayBadge && <div className="badge badge--error spc--right--tny">Voided</div>}
					<NumberFormat
						value={value}
						displayType="text"
						thousandSeparator={true}
						className={displayBadge ? 'type--linethrough type--wgt--light type--color--primary' : ''}
						prefix={this.currencyCode}
						decimalScale={2}
						fixedDecimalScale={true}
					/>
				</Fragment>
			);
		}
	};

	render() {
		let { value, dependentValues, row, isBolded } = this.props;
		const isNumber = !isNaN(toNumber(value));
		const isBreakdown = dependentValues && dependentValues.seperateCountColumn;
		const displayBadge = dependentValues && dependentValues.displayBadge;
		const [payment, transaction] = split(toLower(row.xCommand), ':');
		const issueGift = payment === 'gift' && transaction === 'issue';
		const redeemGift = payment === 'gift' && transaction === 'redeem';
		if (redeemGift) {
			value = Math.abs(value);
		}
		return (
			<div className={this.getClassName(isBreakdown, dependentValues, isBolded)}>
				{this.renderSplitCaptureAuth(isNumber, displayBadge)}
				{this.renderValue(isNumber, value, issueGift, dependentValues, row)}
				{this.renderSplitPay(isNumber, displayBadge)}
				{this.renderVoided(isNumber, displayBadge, value)}
			</div>
		);
	}
}
CurrencyComponent.defaultProps = {
	isBolded: false,
};
CurrencyComponent.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	dependentValues: PropTypes.object,
	row: PropTypes.object,
	isBolded: PropTypes.bool,
};

export default CurrencyComponent;
