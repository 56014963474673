import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import {
	includes,
	filter,
	sumBy,
	toLower,
	map,
	some,
	each,
	has,
	keys,
	split,
	isEmpty,
	round,
	upperFirst,
} from 'lodash';

import { CurrencyMap, CardTypeImagePath } from '../../Common/utilities';

const RADIAN = Math.PI / 180;
const chartColors = {
	visa: {
		sale: '#2D50D8',
		credit: '#2D50D8',
	},
	mastercard: {
		sale: '#69C5D2',
		credit: '#69C5D2',
	},
	discover: {
		sale: '#FE702B',
		credit: '#FE702B',
	},
	amex: {
		sale: '#B6592E',
		credit: '#B6592E',
	},
	ebt: {
		sale: '#A0BDDC',
		credit: '#A0BDDC',
	},
	ebtw: {
		sale: '#E0D9DA',
		credit: '#E0D9DA',
	},
	grant: {
		sale: '#192B50',
		credit: '#192B50',
	},
	gift: {
		sale: '#DFEDF9',
		credit: '#DFEDF9',
	},
	other: {
		sale: '#8F8F8F',
		credit: '#8F8F8F',
	},
};

const cardTypes = {
	visa: 'Visa',
	mastercard: 'Master Card',
	discover: 'Discover',
	amex: 'Amex',
	ebt: 'EBT',
	ebtw: 'EBTW',
	grant: 'Grant',
	gift: 'Gift',
	other: 'Other',
};

class DashboardPieChart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
		};
	}

	getCardType = cardType => {
		includes(['pledger', 'donorsfund', 'ojc'], toLower(cardType));
		const isGrant = includes(['pledger', 'donorsfund', 'ojc'], toLower(cardType));
		const cType = isGrant ? 'Grant' : cardType;
		return cType;
	};

	getCardData = (transactions, cardType) => {
		const { type } = this.props;
		const cType = this.getCardType(cardType);
		const filteredTransactions = filter(transactions, ({ card }) => card === cardType);
		return {
			name: cardTypes[cType],
			icon: cardType,
			isNa: false,
			color: chartColors[toLower(cType)][type],
			amount: round(sumBy(filteredTransactions, t => round(t.amount, 2)), 2),
			count: filteredTransactions.length,
		};
	};

	getData = () => {
		const { data, type, shouldDisplayChart } = this.props;
		if (!data || isEmpty(data.xReportData) || !shouldDisplayChart) {
			return this.getEmptyData();
		}

		const transactions = [];
		const validCommands =
			type === 'sale'
				? ['sale', 'capture', 'postauth', 'authonly', 'redeem', 'recommendation']
				: ['credit', 'refund', 'issue'];
		each(data.xReportData, ({ xCommand, xAmount, xCardType }) => {
			const [, command] = split(toLower(xCommand), ':');
			if (includes(validCommands, command)) {
				const cardType = toLower(this.getCardType(xCardType));
				transactions.push({
					amount: Math.abs(xAmount),
					card: has(cardTypes, cardType) ? cardType : 'other',
				});
			}
		});

		const mappedData = map(keys(cardTypes), cardType => this.getCardData(transactions, cardType));
		if (!some(mappedData, ({ amount }) => amount > 0)) {
			return this.getEmptyData();
		}
		return mappedData;
	};

	getEmptyData = () => {
		return [
			{
				name: 'N/A',
				icon: 'N/A',
				isNa: true,
				color: '#E6E6E9',
				amount: 1,
				count: 'N/A',
			},
		];
	};

	getDashboardCardName = name => {
		if (toLower(name) === 'ebtw') {
			name = 'eWIC';
		}
		return name;
	};

	renderCustomTooltip = data => {
		let payload = {};
		if (this.props.shouldDisplayChart && data && data.payload && data.payload[0] && data.payload[0].payload) {
			payload = data.payload[0].payload;
		} else {
			payload = {
				color: '#9AA2B0',
				stroke: '#9AA2B0',
				fill: '#9AA2B0',
				icon: 'N/A',
				name: 'N/A',
				amount: 0,
				count: 'N/A',
			};
		}

		if (payload.isNa) {
			payload.amount = 0;
		}

		return (
			<div className="recharts-tooltip-pie-chart">
				{this.props.shouldDisplayChart ? (
					<React.Fragment>
						<span className="name">{payload.name}</span>
						<div className="flex--tertiary">
							<img alt={payload.icon} src={CardTypeImagePath.getPath(payload.icon)} className="cards" />
							<div className="value">
								<span>{upperFirst(this.props.type)}</span>
								<NumberFormat
									className="amount"
									prefix={this.state.currency}
									value={payload.amount}
									displayType="text"
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</div>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className="type--center">
							<img src="/static/media/warning.jpg" className="spc--bottom--nano" />
							<div className="type--color--error type--wgt--medium type--lineheight--22">
								There are no values
								<br />
								to show in this view
							</div>
							<hr className="separator separator--grey1" />
						</div>
					</React.Fragment>
				)}
			</div>
		);
	};

	renderCustomChartLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		if (this.props.shouldDisplayChart) {
			if (percent > 0.04) {
				return (
					<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
						{' '}
						{`${(percent * 100).toFixed(0)}%`}
					</text>
				);
			}
		} else {
			return (
				<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					0%
				</text>
			);
		}
	};

	renderLegend = data => {
		const total = round(sumBy(data, dataItem => round(dataItem.amount, 2)), 2) || 0;
		return map(data, ({ amount, isNa, name, color }) => {
			const legendName = this.getDashboardCardName(name);
			if (isNa) {
				return (
					<div key={legendName}>
						<span style={{ color: color, fontSize: '36px', verticalAlign: 'text-bottom' }}>&#9679;</span> {legendName}{' '}
						(100%)
					</div>
				);
			}
			const percent = (amount / total) * 100;
			if (percent > 0) {
				const percentString = percent < 1 ? '<1' : percent.toFixed(0);

				return (
					<div key={legendName} className="rechart__legends__item">
						<div className="indicator" style={{ backgroundColor: color }}></div>
						<span className="name">{legendName}</span>
						<span className="percentage">{percentString}%</span>
					</div>
				);
			}
		});
	};

	render() {
		const chartData = this.getData();
		const chartTitle = this.props.type === 'sale' ? 'Sales Summary by Card Type' : 'Credit Summary by Card Type';

		return (
			<React.Fragment>
				<h5 className="spc--bottom--lrg">{chartTitle}</h5>
				<div className="dashboard__summary__chart__wrapper">
					<div className={`dashboard__summary__chart${this.props.total === 0 ? ' is-empty' : ''}`}>
						<ResponsiveContainer height="100%" aspect={1} debounce={1}>
							<PieChart width={110} height={110}>
								<Tooltip content={this.renderCustomTooltip} position={{ x: 0, y: -112 }} />
								<Pie
									data={chartData}
									paddingAngle={0}
									innerRadius="60%"
									outerRadius="103%"
									cornerRadius={4}
									fill="#82ca9d"
									dataKey="amount"
									legendType="circle"
									label={this.renderCustomChartLabel}
									labelLine={false}
								>
									{chartData.map((entry, index) => {
										return <Cell key={`cell-${index}`} stroke={entry.color} fill={entry.color} />;
									})}
								</Pie>
								<text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" className="pie-chart-text amount">
									${round(this.props.total, 2)}
								</text>
								<text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" className="pie-chart-text count">
									{this.props.count} count
								</text>
							</PieChart>
						</ResponsiveContainer>
					</div>
					<div className="rechart__legends">
						<span className="rechart__legends__title">Legends</span>
						{this.renderLegend(chartData)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

DashboardPieChart.propTypes = {
	data: PropTypes.any,
	type: PropTypes.oneOf(['sale', 'credit']).isRequired,
	shouldDisplayChart: PropTypes.bool,
	total: PropTypes.number,
	count: PropTypes.number,
};

export default DashboardPieChart;
